import { Box, FormLabel, Input } from "@chakra-ui/react";
import React from "react";

export default function CustomInput({ label, containerStyle, ...rest }) {
  return (
    <Box style={containerStyle}>
      <FormLabel fontSize="sm">{label}</FormLabel>
      <Input borderRadius="5px" borderColor="grey" size="sm" {...rest} />
    </Box>
  );
}
