import React from "react";
import {
  Box,
  Center,
  Divider,
  Heading,
  Icon,
  createIcon,
  Flex,
  Image,
  Wrap,
  Spacer,
  WrapItem,
} from "@chakra-ui/react";
import { CloseIcon, PhoneIcon } from "@chakra-ui/icons";
import ssl from "../../assets/img/footerimg/ssl.png";
import age from "../../assets/img/footerimg/18plus.png";
import gt from "../../assets/img/footerimg/gt.png";
import gameCare from "../../assets/img/footerimg/gamecare.png";
import fif from "../../assets/img/footerimg/fif.png";
import { Link } from "@chakra-ui/react";
import "./footer.css";
const Footer = () => {
  return (
    <>
      <Box mt={"7px"}>
        <Divider variant={"dashed"} />
        <div className="support snipcss-QC8Wb">
          <div>
            <div className="w-100 text-center">
              <b>24X7 Support</b>
            </div>
            <div className="text-center w-100"></div>
          </div>
          <div className="footer-social">
            <a href="" target="_blank">
              <img src={require("../../assets/img/whatsapp.png")} />
            </a>
            <a href="" target="_blank">
              <img src={require("../../assets/img/call.png")} />
            </a>
          </div>
        </div>

        {/*  <Center
          style={{
            position: "relative",
          }}
          className="twenty247_text"
          mt={"5px"}
          color="rgb(170,175,181)"
        >
          <Heading size={"md"}>24</Heading>
          <CloseIcon boxSize={"1em"} />
          <Heading size={"md"}>7</Heading> &nbsp;{" "}
          <Heading size={"md"}>Support</Heading>
          <Image
            style={{
              position: "absolute",
              right: "30px",
            }}
            h="20px"
            w="20px"
            src={require("../../assets/img/whatsapp.png")}
          />
          <PhoneIcon
            style={{
              position: "absolute",
              right: "10px",
            }}
          />
        </Center> */}
        <Center color="rgb(170,175,181)" mt={"10px"}>
          <Heading
            size={"sm"}
            m={"12px"}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            borderBottom={"1px solid rgb(170,175,181)"}
          >
            <Link>About Us</Link>
          </Heading>
          <Icon viewBox="0 0 200 200" color="rgb(170,175,181)">
            <path
              fill="currentColor"
              d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
            />
          </Icon>
          <Heading
            size={"sm"}
            m={"12px"}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            borderBottom={"1px solid rgb(170,175,181)"}
          >
            <Link> Terms and Conditions</Link>
          </Heading>
          <Icon viewBox="0 0 200 200" color="rgb(170,175,181)">
            <path
              fill="currentColor"
              d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
            />
          </Icon>
          <Heading
            size={"sm"}
            m={"12px"}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            borderBottom={"1px solid rgb(170,175,181)"}
          >
            <Link> Responsible Gaming</Link>
          </Heading>
        </Center>
        <Box ml={"28px"} mr={"28px"}>
          <Flex>
            <Wrap p={"20px"}>
              <Box>
                <Image src={ssl} style={{ height: "49.5px", width: "100px" }} />
              </Box>

              <Box p={"4px"} color={"rgb(170,175,181)"}>
                <Heading size={"8px"} mb={"-3px"}>
                  100% SAFE
                </Heading>
                <p>Protected connection and encrypted data</p>
              </Box>
            </Wrap>
            <Spacer />
            <Wrap mt={"35px"}>
              <WrapItem>
                <Image src={age} style={{ height: "30px", width: "30px" }} />
              </WrapItem>
              <WrapItem>
                <Image
                  src={gameCare}
                  style={{ height: "30px", width: "30px" }}
                />
              </WrapItem>
              <WrapItem>
                <Image src={gt} style={{ height: "30px", width: "30px" }} />
              </WrapItem>
              <WrapItem>
                <Image
                  src={fif}
                  style={{ height: "45px", width: "45px", marginTop: "-6px" }}
                />
              </WrapItem>
            </Wrap>
          </Flex>
          <Divider variant={"dashed"} />
          <Center color={"rgb(170,175,181)"} mt={"10px"}>
            © Copyright 2023. All Rights Reserved.
          </Center>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
