import { Box, Button, Input, Select, Td, Text } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import CustomTable from "../../components/Table";
import { getActivityLogAPI } from "../../service/AuthService";
import { getAccountStatementAPI } from "../../service/UserService";
import {
  bgColor,
  buttonColors,
  getCurrentDateTimeLocal,
  searchSubstringInArrayObjects,
} from "../../utils/constants";
import moment from "moment";

export default function ActivityLog() {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalPages, setTotalPages] = React.useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([]);

  const [totalElements, setTotalElements] = React.useState(0);
  const [type, setType] = React.useState("LOGIN");
  const [startDate, setStartDate] = React.useState(
    getCurrentDateTimeLocal(true)
  );
  const [endDate, setEndDate] = React.useState(getCurrentDateTimeLocal());
  const getActivityLog = async () => {
    let endDate_ = new Date(endDate);
    endDate_.setDate(endDate_.getDate() + 1);
    let payload = {
      pageSize: rowsPerPage,
      from: new Date(startDate),
      to: endDate_,
      activityLogType: type,
    };
    const {
      response: {
        content,
        size,
        totalElements,
        totalPages,
        number,
        numberOfElements,
      },
    } = await getActivityLogAPI(payload);
    setData(content);
    setTotalPages(totalPages);
    setLoading(false);
    setRowsPerPage(size);
    setTotalElements(totalElements);
  };
  useEffect(() => {
    getActivityLog();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    getActivityLog();
  };
  const search = async (e) => {
    if (e.target.value == "") return getActivityLog();
    setData(
      searchSubstringInArrayObjects(
        data,
        ["userName", "ipAddress"],
        e.target.value
      )
    );
  };
  return (
    <Box bg={bgColor} p="1.5" mt="5" textColor="#AAA391">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
      >
        <Text textColor="#AAA391" fontSize="2xl">
          Activity Log
        </Text>
        {/* <CustomInput
          placeholder="Search"
          bg="transparent"
          border="1px solid #0C9A71"
          borderRadius="0"
          color="gray"
          _placeholder={{ color: "gray" }}
          _focus={{ outline: "none" }}
          onChange={search}
        /> */}
      </Box>
      <Box
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box display="flex" justifyContent="center" alignItems="center" my="2">
          <CustomInput
            placeholder="Select Start Date"
            type="datetime-local"
            bg="transparent"
            _placeholder={{ color: "gray" }}
            _focus={{ outline: "none" }}
            label="From"
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
            containerStyle={{
              margin: 5,
            }}
            textColor="#AAA391"
            size="md"
            borderRadius="5"
          />
          <CustomInput
            placeholder="Select Start Date"
            type="datetime-local"
            bg="transparent"
            borderRadius="5"
            _placeholder={{ color: "gray" }}
            _focus={{ outline: "none" }}
            label="To"
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
            textColor="#AAA391"
            containerStyle={{
              margin: 5,
            }}
            size="md"
          />
          <CustomSelect
            borderRadius="5"
            label="Type"
            textColor="#AAA391"
            containerStyle={{
              margin: 5,
            }}
            size="md"
            onChange={(e) => setType(e.target.value)}
            borderColor="#AAA391"
            options={[
              {
                label: "Login",
                value: "LOGIN",
              },
              {
                label: "Change Password",
                value: "CHANGE_PASSWORD",
              },
            ]}
          ></CustomSelect>
          <Button
            mt="7"
            mx="5"
            size="sm"
            bg={buttonColors}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <CustomTable
        columns={[
          { name: "Username", id: "userName" },
          {
            name: "Date",
            id: "createdAt",
            element: (row) => {
              return (
                <Td>
                  <Text textColor="#AAA391" fontSize="sm">
                    {moment(row?.createdAt + "Z").format("DD-MM-YYYY HH:mm:ss")}
                  </Text>
                </Td>
              );
            },
          },

          { name: "Ip Address", id: "ipAddress" },
        ]}
        rows={data}
        showPagination={false}
        totalPages_={totalPages}
        rowsPerPage_={rowsPerPage}
        currentPage_={pageNumber}
      />
    </Box>
  );
}
