import React, { useEffect } from "react";
import { Box, Divider, Text } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import Live from "../../components/live";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import OddsBox from "../game/Ui/OddsBox";
export default function TabsContent({ id, bg, icon }) {
  const data = useSelector((state) => state.gameData[id + "Games"]);
  const userData = useSelector((state) => state.account.userData);
  const dispatch = useDispatch();
  const cricketData = useSelector((state) =>
    state?.gameData?.cricketGames
      ?.filter((item) => item?.inPlay)
      .map((item) => {
        return {
          ...item,
          sport: "cricket",
        };
      })
  );
  const tennisData = useSelector((state) =>
    state?.gameData?.tennisGames
      ?.filter((item) => item?.inPlay)
      ?.map((item) => {
        return {
          ...item,
          sport: "tennis",
        };
      })
  );
  const soccerData = useSelector((state) =>
    state?.gameData?.soccerGames
      ?.filter((item) => item?.inPlay)
      ?.map((item) => {
        return {
          ...item,
          sport: "soccer",
        };
      })
  );
  const naviagte = useNavigate();
  const calculateTime = (time) => {
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    let matchDate = new Date(time);
    let payload = {
      string: "Today",
      hours: matchDate.getHours(),
      minutes: matchDate.getMinutes(),
    };
    if (matchDate.getDate() == today.getDate()) {
      payload = {
        string: "Today",
        hours: matchDate.getHours(),
        minutes: matchDate.getMinutes(),
      };
    }
    if (matchDate.getDate() == yesterday.getDate()) {
      payload = {
        string: "Yesterday",
        hours: matchDate.getHours(),
        minutes: matchDate.getMinutes(),
      };
    }
    if (matchDate.getDate() == tomorrow.getDate()) {
      payload = {
        string: "Tomorrow",
        hours: matchDate.getHours(),
        minutes: matchDate.getMinutes(),
      };
    }
    return payload;
  };
  useEffect(() => {}, [data]);
  const navigateToGame = (gameEvent) => {
    if (!userData?.token) {
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: true,
      });
      return;
    }

    if (id == "all") {
      naviagte(
        `/game/${gameEvent?.sport}/${gameEvent[gameEvent?.sport + "Id"]}`,
        {
          state: { gameEvent },
        }
      );
    } else {
      naviagte(`/game/${id}/${gameEvent[id + "Id"]}`, {
        state: { gameEvent },
      });
    }
  };
  return (
    <TableContainer>
      <Table
        variant="simple"
        style={{ color: "#aaafb5" }}
        colorScheme="whiteAlpha"
        size="sm"
      >
        <Thead bg={`${bg}`}>
          <Tr>
            <Th
              padding="10px"
              w="55%"
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Box style={{ marginRight: "7px", fontSize: 18 }}>
                <i className={`icon-sm ${icon}`}></i>
              </Box>
              <Text textColor="#262626">{id}</Text>
            </Th>
            <Th w="15%" textAlign="center">
              <Text textColor="#262626">1</Text>
            </Th>
            <Th w="15%">
              <Text textColor="#262626" textAlign="center">
                X
              </Text>
            </Th>
            <Th w="15%">
              <Text textColor="#262626" textAlign="center">
                2
              </Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {id == "all"
            ? [...cricketData, ...tennisData, ...soccerData].map(
                (gameEvent) => (
                  <Tr>
                    <Td
                      cursor="pointer"
                      onClick={() => navigateToGame(gameEvent)}
                      display="flex"
                      alignItems="center"
                    >
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        p="1"
                        mr="5px"
                        textColor="grey.300"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        className="time"
                      >
                        {gameEvent.inPlay ? (
                          <Box>
                            <Live />
                          </Box>
                        ) : (
                          `    ${moment(
                            gameEvent.eventTime
                              ? gameEvent.eventTime
                              : gameEvent.eventDate
                          ).format("  HH:mm")}`
                        )}
                      </Text>
                      <Text>
                        {gameEvent.eventName} <br />
                        <span
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {gameEvent.seriesName
                            ? `(${gameEvent.seriesName})`
                            : ""}
                        </span>
                      </Text>
                    </Td>
                    <Td>
                      <Box display="flex" flexDirection="row">
                        <OddsBox
                          style={{
                            height: "24px",
                          }}
                          match={gameEvent}
                          oddsKey="back1"
                          back={true}
                          home={true}
                        />

                        <OddsBox
                          style={{
                            height: "24px",
                          }}
                          match={gameEvent}
                          oddsKey="lay1"
                          back={false}
                          home={true}
                        />
                      </Box>
                    </Td>
                    <Td>
                      <Box display="flex" flexDirection="row">
                        <OddsBox
                          style={{
                            height: "24px",
                          }}
                          match={gameEvent}
                          oddsKey="back11"
                          back={true}
                          home={true}
                        />

                        <OddsBox
                          style={{
                            height: "24px",
                          }}
                          match={gameEvent}
                          oddsKey="lay11"
                          back={false}
                          home={true}
                        />
                      </Box>
                    </Td>
                    <Td>
                      <Box display="flex" flexDirection="row">
                        <OddsBox
                          style={{
                            height: "24px",
                          }}
                          match={gameEvent}
                          oddsKey="back12"
                          back={true}
                          home={true}
                        />

                        <OddsBox
                          style={{
                            height: "24px",
                          }}
                          match={gameEvent}
                          oddsKey="lay12"
                          back={false}
                          home={true}
                        />
                      </Box>
                    </Td>
                  </Tr>
                )
              )
            : Array.isArray(data)
            ? data.map((gameEvent) => (
                <Tr>
                  <Td
                    cursor="pointer"
                    onClick={() => navigateToGame(gameEvent)}
                    display="flex"
                    alignItems="center"
                  >
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      p="1"
                      mr="5px"
                      textColor="grey.300"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      className="time"
                    >
                      {gameEvent.inPlay ? (
                        <Box>
                          <Live />
                        </Box>
                      ) : (
                        `${moment(
                          gameEvent.eventTime
                            ? gameEvent.eventTime
                            : gameEvent.eventDate
                        ).calendar(moment().startOf("day"))}`
                      )}
                    </Text>
                    <Text>
                      {gameEvent.eventName} <br />
                      <span
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {gameEvent.seriesName
                          ? `(${gameEvent.seriesName})`
                          : ""}
                      </span>
                    </Text>
                  </Td>
                  <Td>
                    <Box display="flex" flexDirection="row">
                      <OddsBox
                        style={{
                          height: "24px",
                        }}
                        match={gameEvent}
                        oddsKey="back1"
                        back={true}
                        home={true}
                      />

                      <OddsBox
                        style={{
                          height: "24px",
                        }}
                        match={gameEvent}
                        oddsKey="lay1"
                        back={false}
                        home={true}
                      />
                    </Box>
                  </Td>
                  <Td>
                    <Box display="flex" flexDirection="row">
                      <OddsBox
                        style={{
                          height: "24px",
                        }}
                        match={gameEvent}
                        oddsKey="back11"
                        back={true}
                        home={true}
                      />

                      <OddsBox
                        style={{
                          height: "24px",
                        }}
                        match={gameEvent}
                        oddsKey="lay11"
                        back={false}
                        home={true}
                      />
                    </Box>
                  </Td>
                  <Td>
                    <Box display="flex" flexDirection="row">
                      <OddsBox
                        style={{
                          height: "24px",
                        }}
                        match={gameEvent}
                        oddsKey="back12"
                        back={true}
                        home={true}
                      />

                      <OddsBox
                        style={{
                          height: "24px",
                        }}
                        match={gameEvent}
                        oddsKey="lay12"
                        back={false}
                        home={true}
                      />
                    </Box>
                  </Td>
                </Tr>
              ))
            : id == "football"
            ? data["soccerAllMatchesResponseDTOS"].map((gameEvent) => (
                <Tr>
                  <Td display="flex" alignItems="center">
                    <Text
                      fontSize="sm"
                      p="1"
                      mr="5px"
                      textColor="##AAAFB5"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRight="1px solid #aaafb5"
                      className="noto"
                    >
                      {gameEvent.inPlay ? (
                        <Box>
                          <Live />
                        </Box>
                      ) : (
                        <Text className="noto" textColor="##AAAFB5">
                          {calculateTime(gameEvent.eventDate).string}
                          <span
                            style={{
                              marginLeft: "40px",
                              marginRight: "40px",
                            }}
                          >
                            {calculateTime(gameEvent.eventDate).hours +
                              ":" +
                              calculateTime(gameEvent.eventDate).minutes}
                          </span>
                        </Text>
                      )}
                    </Text>
                    <Text className="noto">{gameEvent.eventName}</Text>
                  </Td>
                  <Td>
                    <Box display="flex" flexDirection="row">
                      <OddsBox
                        style={{
                          height: "24px",
                        }}
                        match={gameEvent}
                        oddsKey="back1"
                        back={true}
                        home={true}
                      />

                      <OddsBox
                        style={{
                          height: "24px",
                        }}
                        match={gameEvent}
                        oddsKey="lay1"
                        back={false}
                        home={true}
                      />
                    </Box>
                  </Td>
                  <Td>
                    <Box display="flex" flexDirection="row">
                      <OddsBox
                        style={{
                          height: "24px",
                        }}
                        match={gameEvent}
                        oddsKey="back11"
                        back={true}
                        home={true}
                      />

                      <OddsBox
                        style={{
                          height: "24px",
                        }}
                        match={gameEvent}
                        oddsKey="lay11"
                        back={false}
                        home={true}
                      />
                    </Box>
                  </Td>
                  <Td>
                    <Box display="flex" flexDirection="row">
                      <OddsBox
                        style={{
                          height: "24px",
                        }}
                        match={gameEvent}
                        oddsKey="back12"
                        back={true}
                        home={true}
                      />

                      <OddsBox
                        style={{
                          height: "24px",
                        }}
                        match={gameEvent}
                        oddsKey="lay12"
                        back={false}
                        home={true}
                      />
                    </Box>
                  </Td>
                </Tr>
              ))
            : data["tennisAllMatchesResponseDTOS"].map((gameEvent) => (
                <Tr>
                  <Td display="flex" alignItems="center">
                    <Text
                      fontSize="sm"
                      p="1"
                      mr="5px"
                      textColor="##AAAFB5"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRight="1px solid #aaafb5"
                      className="noto"
                    >
                      {gameEvent.inPlay ? (
                        <Box>
                          <Live />
                        </Box>
                      ) : (
                        <Text className="noto" textColor="##AAAFB5">
                          {calculateTime(gameEvent.eventDate).string}
                          <span
                            style={{
                              marginLeft: "40px",
                              marginRight: "40px",
                            }}
                          >
                            {calculateTime(gameEvent.eventDate).hours +
                              ":" +
                              calculateTime(gameEvent.eventDate).minutes}
                          </span>
                        </Text>
                      )}
                    </Text>
                    <Text className="noto">
                      {gameEvent.eventName} <br />
                    </Text>
                  </Td>
                  <Td>
                    <Box display="flex" flexDirection="row">
                      <Box
                        bg="rgb(114,187,239)"
                        px="3"
                        py="1.5"
                        mr="1"
                        rounded="sm"
                        w="50px"
                      >
                        <Text textColor="black" fontWeight="bold">
                          {gameEvent.back1}
                        </Text>
                      </Box>
                      <Box
                        px="3"
                        w="50px"
                        py="1.5"
                        rounded="sm"
                        bg="rgb(249,148,186)"
                      >
                        <Text textColor="black" fontWeight="bold">
                          {gameEvent.lay1}
                        </Text>
                      </Box>
                    </Box>
                  </Td>
                  <Td>
                    <Box display="flex" flexDirection="row">
                      <Box
                        bg="rgb(114,187,239)"
                        px="3"
                        py="1.5"
                        mr="1"
                        rounded="sm"
                        w="50px"
                      >
                        <Text textColor="black" fontWeight="bold">
                          {gameEvent.back11}
                        </Text>
                      </Box>
                      <Box
                        px="3"
                        w="50px"
                        py="1.5"
                        rounded="sm"
                        bg="rgb(249,148,186)"
                      >
                        <Text textColor="black" fontWeight="bold">
                          {gameEvent.lay11}
                        </Text>
                      </Box>
                    </Box>
                  </Td>
                  <Td>
                    <Box display="flex" flexDirection="row">
                      <Box
                        bg="rgb(114,187,239)"
                        px="3"
                        py="1.5"
                        mr="1"
                        rounded="sm"
                        w="50px"
                      >
                        <Text textColor="black" fontWeight="bold">
                          {gameEvent.back12}
                        </Text>
                      </Box>
                      <Box
                        px="3"
                        w="50px"
                        py="1.5"
                        rounded="sm"
                        bg="rgb(249,148,186)"
                      >
                        <Text textColor="black" fontWeight="bold">
                          {gameEvent.lay12}
                        </Text>
                      </Box>
                    </Box>
                  </Td>
                </Tr>
              ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
