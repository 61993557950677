import React from "react";
import { Box, Card, CardBody, CardHeader, Heading } from "@chakra-ui/react";
import Carousel from "../../components/Carousel";
import Image1 from "./../../assets/img/rSideBar/cmeter1.jpg";
import Image2 from "./../../assets/img/rSideBar/cricketv3.jpg";
import Image3 from "./../../assets/img/rSideBar/poker.jpg";
import Image4 from "./../../assets/img/rSideBar/superover.jpg";
import Image5 from "./../../assets/img/rSideBar/teen.jpg";
import Image6 from "./../../assets/img/rSideBar/teen20.jpg";

export default function VirtualCosino() {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6];
  return (
    <>
      <Card className="card">
        <CardHeader>
          <h5 className="card_title">Virtual Casino</h5>
        </CardHeader>
        <CardBody>
          <div className="card_content">
            <Carousel time={2000}>
              {images.map((e) => {
                return (
                  <>
                    <img
                      src={e}
                      style={{
                        height: "150px",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      alt="slide"
                    />
                  </>
                );
              })}
            </Carousel>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
