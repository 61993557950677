import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Toast,
  useToast,
} from "@chakra-ui/react";
import {
  Button,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Checkbox, CheckboxGroup } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { loginAPI } from "../../service/AuthService";
import { JSEncrypt } from "jsencrypt";
import { PUBLIC_KEY } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserDataAPI } from "../../service/UserService";
import { generateHash } from "../../service";
function Model({ children }) {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const content = children;
  const isLoginPopUpOpen = useSelector(
    (state) => state.account.ui.loginPopOpen
  );
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();
  const Toast = useToast();
  const login = async () => {
    let encryptedPassword = new JSEncrypt();
    encryptedPassword.setPublicKey(PUBLIC_KEY);
    encryptedPassword = encryptedPassword.encrypt(password);
    let fingerprint = await generateHash();
    localStorage.setItem("fingerprint", fingerprint);
    var { data, code, message, response, error } = await loginAPI(
      userName,
      encryptedPassword
    );
    if (code === 200) {
      dispatch({
        type: "accountData/setUserData",
        payload: response,
      });
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: false,
      });
      dispatch({
        type: "accountData/setChangePopSate",
        payload: response.resetRequired,
      });
    } else if (code == 403) {
      Toast({
        title: "Error",
        description: error.description,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (code == 401) {
      Toast({
        title: "Error",
        description: response,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      Toast({
        title: "Error",
        description: "Account is disabled or Something went wrong",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <Box
        onClick={() =>
          dispatch({
            type: "accountData/setLoginPopSate",
            payload: true,
          })
        }
      >
        {content}
      </Box>
      <Modal
        isCentered
        onClose={() =>
          dispatch({
            type: "accountData/setLoginPopSate",
            payload: false,
          })
        }
        isOpen={isLoginPopUpOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={"white"} borderRadius={"5px"}>
            Login
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody m={5} pb={6} borderRadius={"5px"}>
            <FormControl>
              <FormLabel>Username</FormLabel>
              <Input
                onChange={(e) => setUserName(e.target.value)}
                isRequired
                variant="filled"
                placeholder="Enter Username"
                focusBorderColor="rgb(51,51,51)"
                style={{ backgroundColor: "rgb(51,51,51)" }}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Password</FormLabel>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                isRequired
                placeholder="Enter Password"
                variant="filled"
                type={"password"}
                focusBorderColor="rgb(51,51,51)"
                style={{ backgroundColor: "rgb(51,51,51)" }}
              />
            </FormControl>

            <Checkbox defaultChecked mt={"30px"} alignItems="top">
              <Text fontSize="12px">
                {" "}
                I am at least{" "}
                <a
                  href="#"
                  style={{ textDecoration: "underline", color: "#FF7777" }}
                  onMouseEnter={(e) => {
                    e.target.style.color = "rgb(150,0,0)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "rgb(250,0,0)";
                  }}
                >
                  18 years
                </a>{" "}
                of age and I have read, accept and agree to the{" "}
                <a
                  style={{
                    textDecoration: "underline",
                    color: "#FDCF13",
                  }}
                >
                  Terms and Conditions
                </a>
                ,&nbsp;
                <a
                  href="#"
                  style={{
                    textDecoration: "underline",
                    color: "#FDCF13",
                  }}
                >
                  {" "}
                  Responsible Gaming{" "}
                </a>
                ,&nbsp;
                <a
                  href="#"
                  style={{
                    textDecoration: "underline",
                    color: "#FDCF13",
                  }}
                >
                  GamCare
                </a>
                ,&nbsp;
                <a
                  href="#"
                  style={{
                    textDecoration: "underline",
                    color: "#FDCF13",
                  }}
                >
                  Gambling Therapy
                </a>
              </Text>
            </Checkbox>
            <Button onClick={login} mt="40px" w={"100%"} className="primary">
              Login
            </Button>
            <Text fontSize="9px" mt="29px">
              This site is protected by reCAPTCHA and the Google{" "}
              <a href="#" style={{ color: "#FDCF13" }}>
                Privacy Policy
              </a>{" "}
              and{" "}
              <a href="#" style={{ color: "#FDCF13" }}>
                Terms of Service
              </a>{" "}
              apply.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
export default Model;
