import { Stomp } from "@stomp/stompjs";
import { JSEncrypt } from "jsencrypt";
import moment from "moment/moment";
import {
  casinoPL,
  cricketBookMakerPL,
  cricketFancyPL,
} from "../pages/game/profitLoss";
import store from "../redux/store";
export const ASSETS_URL = "https://assets.bet999Pro.com/static/";
export const PROD_IP = process.env.REACT_APP_API_URL;
export const DEV_IP = process.env.REACT_APP_API_URL;
var BASE_URL_;
var CRICKET_SERVICE_URL_;
var SOCCER_AND_TENNIS_SERVICE_URL_;
var CASINO_SERVICE_URL_;
/* export const BASE_URL = `http://${process.env.REACT_APP_API_URL}:8000/api`;
export const CRICKET_SERVICE_URL = `http://${process.env.REACT_APP_API_URL}:8001/api`;
export const SOCCER_AND_TENNIS_SERVICE_URL = `http://${process.env.REACT_APP_API_URL}:8002/api`;
export const CASINO_SERVICE_URL = `http://${process.env.REACT_APP_API_URL}:8003/api`; */
if (PROD_IP.startsWith("api")) {
  BASE_URL_ = `https://${process.env.REACT_APP_API_URL}/aam/v1`;
  CRICKET_SERVICE_URL_ = `https://${process.env.REACT_APP_API_URL}/cm/v1`;
  SOCCER_AND_TENNIS_SERVICE_URL_ = `https://${process.env.REACT_APP_API_URL}/tas/v1`;
  CASINO_SERVICE_URL_ = `https://${process.env.REACT_APP_API_URL}/casino/v1`;
} else {
  BASE_URL_ = `http://${process.env.REACT_APP_API_URL}:8000/api`;
  CRICKET_SERVICE_URL_ = `http://${process.env.REACT_APP_API_URL}:8001/api`;
  SOCCER_AND_TENNIS_SERVICE_URL_ = `http://${process.env.REACT_APP_API_URL}:8002/api`;
  CASINO_SERVICE_URL_ = `http://${process.env.REACT_APP_API_URL}:8003/api`;
}
export const BASE_URL = BASE_URL_;
export const CRICKET_SERVICE_URL = CRICKET_SERVICE_URL_;
export const SOCCER_AND_TENNIS_SERVICE_URL = SOCCER_AND_TENNIS_SERVICE_URL_;
export const CASINO_SERVICE_URL = CASINO_SERVICE_URL_;
export const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQB0ZacT13Ok+3pX0lmuJ9dJ
F6atV7Vwv5gmgpvXiBVYKKM4sfGb2qg395uVcampRgLrM63KxElPkWaa90j10ol6
fnHneE3sGuNIrvUmSvtqgeSD8nS4GXdkDecD+HSB7OCOcr+oxsGZzXJdzN/5QyUd
DGpRTlm8T+yB6wa9sJPPgZJ/C650JF57WF+G3qSngqJZEtGrKLlZYL24AqVEg85d
gLsrawWpZUEGeYqhYsdSeoGqkAR6E8Ybw+YWEYCFyMLZi2Fe5MVwMARWQ5kzpKTx
0ur7DPsghrAem08DIAekAN97F1DS48Vs88T29C2716ylE27ICyiMLDw9DLXekhAD
AgMBAAE=
-----END PUBLIC KEY-----`;
export const buttonColors = "yellow.400";
export const bgColor = "#383838";
export const secondaryColor = "#3C444B";
export const applyFancyFor = [
  "Over By Over",
  "Normal",
  "Ball By Ball",
  "meter",
  "fancy1",
];
export const sortByStringNumberInEnd = (a, b) => {
  const aNumber = parseInt(a);
  const bNumber = parseInt(b);
  if (aNumber && bNumber) {
    return aNumber - bNumber;
  } else if (aNumber) {
    return -1;
  } else if (bNumber) {
    return 1;
  } else {
    return a.localeCompare(b);
  }
};
var ENCRYPT = new JSEncrypt();
ENCRYPT.setPublicKey(PUBLIC_KEY);
export const Encrypt = (data) => ENCRYPT.encrypt(data);
export const getToken = () => {
  return store.getState().account.userData.token;
};
export const getCurrentDateTimeLocal = (
  prev = false,
  date_ = false,
  time = false
) => {
  let date = new Date(); // create a new Date object with the current date and time
  let year = date.getFullYear(); // get the four-digit year (e.g. 2023)
  let month = ("0" + (date.getMonth() + 1)).slice(-2); // get the month as a zero-padded two-digit string (e.g. "03")
  let day = ("0" + date.getDate()).slice(-2); // get the day of the month as a zero-padded two-digit string (e.g. "04")
  let hour = ("0" + date.getHours()).slice(-2); // get the hour as a zero-padded two-digit string (e.g. "09")
  let minute = ("0" + date.getMinutes()).slice(-2); // get the minute as a zero-padded two-digit string (e.g. "05")
  if (prev) {
    if (date_) {
      day = ("0" + (date.getDate() - date_)).slice(-2);
    } else {
      day = ("0" + (date.getDate() - 1)).slice(-2);
    }
  }
  let formattedDate = `${year}-${month}-${day} ${hour}:${minute}`; // concatenate the year, month, and day with hyphens as the separator
  return formattedDate;
};

export const convertEpochToDate = (epoch) => {
  return moment(epoch).format("DD/MM/YYYY hh:mm A");
};
export function searchSubstringInArrayObjects(array, keys, searchString) {
  return array.filter((obj) => {
    return keys.some((key) => {
      if (!obj[key]) return false;
      return String(obj[key])
        .toLowerCase()
        .includes(searchString.toLowerCase());
    });
  });
}
export const getProfit = (selectedAmount, game, selectedEventForBet) => {
  let profit;
  let loss;
  let selectedAmount_ = selectedEventForBet.amount;
  if (game == "cricket" && selectedEventForBet.isBookmaker) {
    profit = cricketBookMakerPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = cricketBookMakerPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  } else if (game == "casino" || game == "tennis" || game == "soccer") {
    profit = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  } else if (game == "cricket" && selectedEventForBet.isFancy) {
    profit = cricketFancyPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = cricketFancyPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  } else {
    profit = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  }
  return {
    profit: parseFloat(profit).toFixed(2),
    loss: parseFloat(loss).toFixed(2),
  };
};

export const createSocket = (id, dispatch, game = "dragonTiger20") => {
  const stompURL =
    id == "cricket"
      ? CRICKET_SERVICE_URL.replace("http", "ws").replace("/api", "/websocket")
      : id == "casino"
      ? CASINO_SERVICE_URL.replace("http", "ws").replace("/api", "/websocket")
      : SOCCER_AND_TENNIS_SERVICE_URL.replace("http", "ws").replace(
          "/api",
          "/websocket"
        );

  let stompClient = Stomp.client(stompURL);
  stompClient.debug = () => {
    return null;
  };
  stompClient.reconnect_delay = 5000;
  stompClient.connect({}, (frame) => {
    if (id != "casino") {
      stompClient.subscribe("/live/liveMatches", function (message) {
        try {
          let data = JSON.parse(message.body);
          if (id == "cricket")
            dispatch({
              type: "gameData/setCricketGames",
              payload: data,
            });
          if (id == "soccer" || id == "tennis") {
            dispatch({
              type: "gameData/setSoccerGames",
              payload: data.soccerAllMatchesResponseDTOS,
            });
            dispatch({
              type: "gameData/setTennisGames",
              payload: data.tennisAllMatchesResponseDTOS,
            });
          }
        } catch (e) {
          console.log(e);
        }
      });
    }
    let url = "";
    if (id == "cricket") url = "/live/liveOdds";
    if (id == "soccer") url = "/live/soccer/liveOdds";
    if (id == "tennis") url = "/live/tennis/liveOdds";
    if (id == "casino") url = `/live/${game}`;
    stompClient.subscribe(url, function (message) {
      try {
        if (id == "cricket")
          dispatch({
            type: "gameData/setCricketOdds",
            payload: JSON.parse(message.body),
          });
        if (id == "soccer")
          dispatch({
            type: "gameData/setSoccerOdds",
            payload: JSON.parse(message.body),
          });
        if (id == "tennis")
          dispatch({
            type: "gameData/setTennisOdds",
            payload: JSON.parse(message.body),
          });
        if (id == "casino") {
          switch (game) {
            case "dragonTiger20":
              dispatch({
                type: "gameData/setDragonTiger20Odds",
                payload: JSON.parse(message.body),
              });
              break;
            case "casinoMeter":
              dispatch({
                type: "gameData/setCasinoMeterOdds",
                payload: JSON.parse(message.body),
              });
              break;
            case "teenPatti20":
              dispatch({
                type: "gameData/setTeenPatti20Odds",
                payload: JSON.parse(message.body),
              });
              break;
            case "dragonTiger202":
              dispatch({
                type: "gameData/setDragonTiger202Odds",
                payload: JSON.parse(message.body),
              });
              break;
            case "lucky7":
              dispatch({
                type: "gameData/setLucky7Odds",
                payload: JSON.parse(message.body),
              });
              break;
            case "lucky7eu":
              dispatch({
                type: "gameData/setLucky7euOdds",
                payload: JSON.parse(message.body),
              });
              break;
            case "casinoQueen":
              dispatch({
                type: "gameData/setCasinoQueenOdds",
                payload: JSON.parse(message.body),
              });
              break;
            case "aaa":
              dispatch({
                type: "gameData/setAaaOdds",
                payload: JSON.parse(message.body),
              });
              break;
            case "poker20":
              dispatch({
                type: "gameData/setPoker20Odds",
                payload: JSON.parse(message.body),
              });
              break;
            case "dragonTigerOneDay":
              dispatch({
                type: "gameData/setDragonTigerOneDayOdds",
                payload: JSON.parse(message.body),
              });
              break;
            case "teen":
              dispatch({
                type: "gameData/setTeenOdds",
                payload: JSON.parse(message.body),
              });
              break;
            case "andarBahar20":
              dispatch({
                type: "gameData/setAndarBahar20Odds",
                payload: JSON.parse(message.body),
              });
              break;
            case "abj":
              dispatch({
                type: "gameData/setAbjOdds",
                payload: JSON.parse(message.body),
              });
              break;
            case "card32":
              dispatch({
                type: "gameData/setCard32Odds",
                payload: JSON.parse(message.body),
              });
              break;
            case "dragonTigerLion":
              dispatch({
                type: "gameData/setDragonTigerLionOdds",
                payload: JSON.parse(message.body),
              });
              break;
            case "teen8":
              dispatch({
                type: "gameData/setTeen8Odds",
                payload: JSON.parse(message.body),
              });
              break;
            case "teen9":
              dispatch({
                type: "gameData/setTeen9Odds",
                payload: JSON.parse(message.body),
              });
              break;

            case "poker9":
              dispatch({
                type: "gameData/setPoker9Odds",
                payload: JSON.parse(message.body),
              });
              break;

            case "card32eu":
              dispatch({
                type: "gameData/setCard32euOdds",
                payload: JSON.parse(message.body),
              });
              break;
            case "baccarat2":
              dispatch({
                type: "gameData/setBaccarat20Odds",
                payload: JSON.parse(message.body),
              });
              break;
            case "race20":
              dispatch({
                type: "gameData/setRace20Odds",
                payload: JSON.parse(message.body),
              });
              break;
            case "worli2":
              dispatch({
                type: "gameData/setWorli2Odds",
                payload: JSON.parse(message.body),
              });
              break;
            case "bollywoodTable":
              dispatch({
                type: "gameData/setBollywoodTableOdds",
                payload: JSON.parse(message.body),
              });
              break;
            case "casinoWar":
              dispatch({
                type: "gameData/setCasinoWarOdds",
                payload: JSON.parse(message.body),
              });
              break;
            case "abj":
              dispatch({
                type: "gameData/setAbjOdds",
                payload: JSON.parse(message.body),
              });
              break;
            case "baccarat":
              dispatch({
                type: "gameData/setBaccaratOdds",
                payload: JSON.parse(message.body),
              });
              break;
            case "cmatch20":
              dispatch({
                type: "gameData/setCmatch20Odds",
                payload: JSON.parse(message.body),
              });
              break;
            case "worli":
              dispatch({
                type: "gameData/setWorliOdds",
                payload: JSON.parse(message.body),
              });
              break;
            case "superOver":
              dispatch({
                type: "gameData/setSuperOverOdds",
                payload: JSON.parse(message.body),
              });
              break;
            case "cricketV3":
              dispatch({
                type: "gameData/setCricketv3Odds",
                payload: JSON.parse(message.body),
              });
              break;
          }
        }
      } catch (e) {}
    });
  });
  return stompClient;
};
