import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../components/CustomInput";
import {
  getButtonValuesAPI,
  getLiveStreamingUrlAPI,
} from "../../service/AuthService";
import Iframe from "react-iframe";
import {
  getCasinoLastTenResultsAPI,
  getMyBetsCasinoAPI,
  placeCasinoBetAPI,
} from "../../service/casino";
import {
  deleteCricketBetAPI,
  getCricketEventDetailsAPI,
  getMyBetsCricketAPI,
  placeCricketBetAPI,
  placeFancyCricketBetAPI,
} from "../../service/cricket";
import {
  getMyBetsSoccerAPI,
  getMyBetsTennisAPI,
  getSoccerEventDetailsAPI,
  getTennisEventDetailsAPI,
  placeSoccerBetAPI,
  placeTennisBetAPI,
} from "../../service/soccer&tennis";
import {
  applyFancyFor,
  ASSETS_URL,
  bgColor,
  buttonColors,
  secondaryColor,
} from "../../utils/constants";
import {
  casinoPL,
  cricketBookMakerPL,
  cricketFancyPL,
  cricketMatchOddsPL,
} from "./profitLoss";
import axios from "axios";
import CasinoResultModal from "../../components/CasinoResultModal/CasinoResultModal";
import IframeComponent from "../../components/Iframe";
import moment from "moment";
export default function PlaceBet({
  matchName,
  matchOdds,
  teamName,
  game,
  eventId,
  settings,
  w,
  inningsRule,
  isImage,
  gameId,
  seperationRequired,
}) {
  const selectedEventForBet_ = useSelector(
    (state) => state.gameData.selectedEventForBet
  );
  const imageMap = {
    S: "spade.png",
    H: "heart.png",
    D: "diamond.png",
    C: "club.png",
  };
  const selectedEventForBet = useMemo(
    () => selectedEventForBet_,
    [selectedEventForBet_]
  );
  const [channelId, setChannelId] = useState(null);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const isMobileView = window.innerWidth < 768;
  const toast = useToast();
  const dispatch = useDispatch();
  const [userBets, setUserBets] = useState([]);
  const userData = useSelector((state) => state.account.userData);
  const [lastTenResult, setLastTenResult] = useState([]);
  const [amount, setAmount] = useState({ casino: [], sport: [] });
  const [gameEvent, setGameEvent] = useState(null);
  const [isChannelIdFetched, setIsChannelIdFetched] = useState(false);
  const setSelectedAmount = (value) => {
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: {
        amount: value,
        profit: getProfit(value)?.profit,
        loss: getProfit(value)?.loss,
      },
    });
  };
  var gameSettings = useSelector((state) => state.gameData[game + "Settings"]);
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }
  const getGameEventDetails = useCallback(async () => {
    if (game == "soccer") {
      const { response } = await getSoccerEventDetailsAPI(eventId);
      return response;
    } else if (game == "tennis") {
      const { response } = await getTennisEventDetailsAPI(eventId);
      return response;
    } else if (game == "cricket") {
      const { response } = await getCricketEventDetailsAPI(eventId);
      return response;
    }
  }, [eventId, game]);
  const getLiveStreamingUrl = async () => {
    let gameId = await getGameEventDetails();
    const { response } = await getLiveStreamingUrlAPI(gameId?.eventId);
    setChannelId(response);
    setIsChannelIdFetched(true);
  };
  useEffect(() => {
    getLiveStreamingUrl();
  }, [eventId]);
  const [isLoading, setIsLoading] = useState(false);
  const placeBet = async () => {
    let delay;
    setIsLoading(true);

    if (
      selectedEventForBet.marketName == "MATCH_ODDS" ||
      selectedEventForBet.marketName == "BOOKMAKER_ODDS_2" ||
      selectedEventForBet.marketName == "BOOKMAKER_ODDS_1"
    ) {
      delay = gameSettings?.delayOdds;
    } else {
      delay = gameSettings?.delaySession;
    }

    let selectedAmount_ = selectedEventForBet?.amount;
    if (selectedEventForBet.selectedOdd <= 0) {
      toast({
        description: "Please select a valid odd",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setIsLoading(false);
      return;
    }
    if (game == "cricket" || game == "tennis" || game == "soccer") {
      if (
        (selectedEventForBet.marketName == "MATCH_ODDS" &&
          gameSettings?.minStack > selectedAmount_) ||
        (selectedEventForBet.marketName == "BOOKMAKER_ODDS_2" &&
          gameSettings?.minStack > selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS" &&
          gameSettings?.sessionMinStack > selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS_2" &&
          gameSettings?.sessionMinStack > selectedAmount_)
      ) {
        toast({
          description: `Minimum bet amount is ${
            selectedEventForBet.marketName == "FANCY_ODDS_2"
              ? gameSettings?.sessionMinStack
              : selectedEventForBet.marketName == "FANCY_ODDS"
              ? gameSettings?.sessionMinStack
              : selectedEventForBet.marketName == "BOOKMAKER_ODDS_2"
              ? gameSettings?.bookmakerMinStack
              : gameSettings?.minStack
          }`,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setIsLoading(false);
        return;
      }
      if (
        (selectedEventForBet.marketName == "MATCH_ODDS" &&
          gameSettings?.maxStack < selectedAmount_) ||
        (selectedEventForBet.marketName == "BOOKMAKER_ODDS_2" &&
          gameSettings?.bookmakerMaxStack < selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS" &&
          gameSettings?.sessionMaxStack < selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS_2" &&
          gameSettings?.sessionMaxStack < selectedAmount_)
      ) {
        toast({
          description: `Maximum bet amount is ${
            selectedEventForBet.marketName == "FANCY_ODDS_2"
              ? gameSettings?.sessionMaxStack
              : selectedEventForBet.marketName == "FANCY_ODDS"
              ? gameSettings?.sessionMaxStack
              : selectedEventForBet.marketName == "BOOKMAKER_ODDS_2"
              ? gameSettings?.bookmakerMaxStack
              : gameSettings?.maxStack
          }`,

          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setIsLoading(false);
        return;
      }
    }

    let profit;
    let loss;
    if (game == "cricket" && selectedEventForBet.isBookmaker) {
      profit = cricketBookMakerPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = cricketBookMakerPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    } else if (game == "casino" || game == "tennis" || game == "soccer") {
      profit = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    } else {
      profit = cricketMatchOddsPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = cricketMatchOddsPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    }
    let payload = {
      amount: selectedAmount_,
      profit: parseFloat(profit).toFixed(2),
      loss: parseFloat(loss).toFixed(2),
      marketId: selectedEventForBet.marketId,
      selectionId: selectedEventForBet.selectedId,
      type: selectedEventForBet.type,
      eventId: parseInt(selectedEventForBet.eventId),
      runnerName: selectedEventForBet.runnerName,
      gameType:
        selectedEventForBet.marketName != "MATCH_ODDS" ||
        selectedEventForBet.marketName != "BOOKMAKER_ODDS_1" ||
        selectedEventForBet.marketName != "BOOKMAKER_ODDS_2"
          ? selectedEventForBet.gameType
          : selectedEventForBet.marketName,
      marketName: selectedEventForBet.marketName,
    };

    if (game == "casino") {
      payload.casinoGames = eventId;
    }
    if (selectedEventForBet.type == "back") {
      payload["back"] = parseFloat(selectedEventForBet.selectedOdd);
    } else {
      payload["lay"] = parseFloat(selectedEventForBet.selectedOdd);
    }

    setTimeout(async () => {
      if (game == "cricket" && !selectedEventForBet.isFancy) {
        const { response, code, data } = await placeCricketBetAPI(
          payload,
          userData.token
        );
        if (code == 200) {
          toast({
            title: "Bet Placed Successfully",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        } else if (code == "ERR_BAD_RESPONSE") {
          toast({
            title: "Something Went Wrong ",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        } else {
          toast({
            title: response || "Something went wrong",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      } else if (game == "cricket" && selectedEventForBet.isFancy) {
        let fancyPayload = {
          amount: selectedAmount_,
          eventId: parseInt(selectedEventForBet.eventId),
          marketId: selectedEventForBet.marketId,
          marketName:
            selectedEventForBet.marketName != "MATCH_ODDS" ||
            selectedEventForBet.marketName != "BOOKMAKER_ODDS_1" ||
            selectedEventForBet.marketName != "BOOKMAKER_ODDS_2"
              ? selectedEventForBet.gameType
              : selectedEventForBet.marketName,
          gameType: selectedEventForBet.gameType,
        };

        if (selectedEventForBet.type === "lay") {
          fancyPayload["layRate"] = selectedEventForBet.selectedRate;
          fancyPayload.oddsLay = selectedEventForBet.selectedOdd;
          if (selectedEventForBet.gameType == "fancy1") {
            fancyPayload["layRate"] = selectedEventForBet.selectedOdd;
            fancyPayload.oddsLay = selectedEventForBet.selectedOdd;
          }
        } else {
          fancyPayload["backRate"] = selectedEventForBet.selectedRate;
          fancyPayload.oddsBack = selectedEventForBet.selectedOdd;
          if (selectedEventForBet.gameType == "fancy1") {
            fancyPayload["backRate"] = selectedEventForBet.selectedOdd;
            fancyPayload.oddsBack = selectedEventForBet.selectedOdd;
          }
        }

        if (
          applyFancyFor.find(
            (item) =>
              item?.toLowerCase() ==
              selectedEventForBet?.gameType?.toLowerCase()
          )
        ) {
          if (selectedEventForBet?.type == "lay") {
            if (selectedEventForBet?.gameType == "fancy1") {
              fancyPayload.profit = cricketMatchOddsPL(
                selectedEventForBet.selectedOdd,
                selectedAmount_,
                selectedEventForBet.type
              ).loss;
              fancyPayload.loss = cricketMatchOddsPL(
                selectedEventForBet.selectedOdd,
                selectedAmount_,
                selectedEventForBet.type
              ).profit;
            } else {
              fancyPayload.profit = cricketFancyPL(
                selectedEventForBet.selectedRate,
                selectedAmount_,
                selectedEventForBet.type
              ).loss;
              fancyPayload.loss = cricketFancyPL(
                selectedEventForBet.selectedRate,
                selectedAmount_,
                selectedEventForBet.type
              ).profit;
            }
          } else {
            if (selectedEventForBet?.gameType == "fancy1") {
              fancyPayload.profit = cricketMatchOddsPL(
                selectedEventForBet.selectedOdd,
                selectedAmount_,
                selectedEventForBet.type
              ).profit;
              fancyPayload.loss = cricketMatchOddsPL(
                selectedEventForBet.selectedOdd,
                selectedAmount_,
                selectedEventForBet.type
              ).loss;
            } else {
              fancyPayload.profit = cricketFancyPL(
                selectedEventForBet.selectedRate,
                selectedAmount_,
                selectedEventForBet.type
              ).profit;
              fancyPayload.loss = cricketFancyPL(
                selectedEventForBet.selectedRate,
                selectedAmount_,
                selectedEventForBet.type
              ).loss;
            }
          }
        } else {
          fancyPayload.profit = cricketMatchOddsPL(
            selectedEventForBet.selectedOdd,
            selectedAmount_,
            selectedEventForBet.type
          ).profit;
          fancyPayload.loss = cricketMatchOddsPL(
            selectedEventForBet.selectedOdd,
            selectedAmount_,
            selectedEventForBet.type
          ).loss;
          fancyPayload.selectionId = selectedEventForBet.selectedId;
          fancyPayload.runnerName = selectedEventForBet.runnerName;
          if (selectedEventForBet.type === "lay") {
            fancyPayload["layRate"] = selectedEventForBet.selectedOdd;
          } else {
            fancyPayload["backRate"] = selectedEventForBet.selectedOdd;
          }
        }

        const { response, code, data } = await placeFancyCricketBetAPI(
          fancyPayload,
          userData.token
        );
        if (code == 200) {
          toast({
            title: "Bet Placed Successfully",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          dispatch({
            type: "gameData/setSelectedEventForBet",
            payload: null,
          });
        } else if (code == "ERR_BAD_RESPONSE") {
          toast({
            title: "Something Went Wrong ",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        } else {
          toast({
            title: response || "Something went wrong",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      } else if (game == "soccer") {
        const { response, code } = await placeSoccerBetAPI(
          payload,
          userData.token
        );
        if (code == 200) {
          toast({
            title: "Bet Placed Successfully",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          dispatch({
            type: "gameData/setSelectedEventForBet",
            payload: null,
          });
          dispatch({
            type: "gameData/setSelectedEventForBet",
            payload: null,
          });
        } else if (code == "ERR_BAD_RESPONSE") {
          toast({
            title: "Something Went Wrong ",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        } else {
          toast({
            title: response || "Something went wrong",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      } else if (game == "tennis") {
        const { response, code } = await placeTennisBetAPI(
          payload,
          userData.token
        );
        if (code == 200) {
          toast({
            title: "Bet Placed Successfully",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          dispatch({
            type: "gameData/setSelectedEventForBet",
            payload: null,
          });
        } else if (code == "ERR_BAD_RESPONSE") {
          toast({
            title: "Something Went Wrong ",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        } else {
          toast({
            title: response || "Something went wrong",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      } else if (game == "casino") {
        const { response, code } = await placeCasinoBetAPI(
          payload,
          userData.token
        );
        if (code == 200) {
          toast({
            title: "Bet Placed Successfully",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          dispatch({
            type: "gameData/setSelectedEventForBet",
            payload: null,
          });
        } else if (code == "ERR_BAD_RESPONSE") {
          toast({
            title: "Something Went Wrong ",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        } else {
          toast({
            title: response || "Something went wrong",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      }
      setIsLoading(false);
      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: null,
      });
    }, delay * 1000);
  };
  const myBets = useCallback(async () => {
    if (game == "cricket") {
      const { response, code } = await getMyBetsCricketAPI(
        eventId,
        userData.token
      );
      setUserBets(code == 200 ? response : []);
    } else if (game == "soccer") {
      const { response, code } = await getMyBetsSoccerAPI(
        eventId,
        userData.token
      );
      setUserBets(code == 200 ? response : []);
    } else if (game == "tennis") {
      const { response, code } = await getMyBetsTennisAPI(
        eventId,
        userData.token
      );
      setUserBets(code == 200 ? response : []);
    } else if (game == "casino") {
      const { response, code } = await getMyBetsCasinoAPI(
        eventId,
        userData.token
      );
      setUserBets(code == 200 ? response : []);
    } else {
      setUserBets([]);
    }
  }, [eventId, game, userData.token]);
  const getCasinoLastResults = useCallback(async () => {
    const { data } = await getCasinoLastTenResultsAPI(eventId, userData.token);
    setLastTenResult(data);
  }, [eventId, userData.token]);
  const getButtonValues = async () => {
    const { response, code } = await getButtonValuesAPI();
    if (code == 200) {
      const convertedData = {
        casino: [],
        sport: [],
      };
      for (const key in response) {
        if (key.startsWith("buttonValueCasino")) {
          const index = key.replace("buttonValueCasino", "");
          const label = response["buttonNameCasino" + index];
          const value = response[key];
          convertedData.casino.push({ label, value });
        } else if (key.startsWith("buttonValue")) {
          const index = key.replace("buttonValue", "");
          const label = response["buttonName" + index];
          const value = response[key];
          convertedData.sport.push({ label, value });
        }
      }
      setAmount(convertedData);
    }
  };
  useEffect(() => {
    if (userData.token) {
      getButtonValues();
      let interval = setInterval(() => {
        myBets();
        if (game == "casino") {
          getCasinoLastResults();
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [game, getCasinoLastResults, myBets, userData.token]);
  const deleteCricketBet = async (id) => {
    const { response, code } = await deleteCricketBetAPI(id);
    if (code == 200) {
      toast({
        title: "Bet Deleted Successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      myBets();
    }
  };
  useEffect(() => {}, [selectedEventForBet]);
  useEffect(() => {
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: null,
    });
  }, [eventId]);
  const getProfit = (value) => {
    let profit;
    let loss;
    let selectedAmount_ = value;
    if (game == "cricket" && selectedEventForBet.isBookmaker) {
      profit = cricketBookMakerPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = cricketBookMakerPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    } else if (game == "casino" || game == "tennis" || game == "soccer") {
      profit = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    } else if (game == "cricket" && selectedEventForBet.isFancy) {
      if (
        applyFancyFor.find(
          (item) =>
            item?.toLowerCase() == selectedEventForBet?.gameType?.toLowerCase()
        )
      ) {
        if (selectedEventForBet?.type == "lay") {
          if (selectedEventForBet?.gameType == "fancy1") {
            profit = cricketMatchOddsPL(
              selectedEventForBet.selectedOdd,
              selectedAmount_,
              selectedEventForBet.type
            ).loss;
            loss = cricketMatchOddsPL(
              selectedEventForBet.selectedOdd,
              selectedAmount_,
              selectedEventForBet.type
            ).profit;
          } else {
            profit = cricketFancyPL(
              selectedEventForBet.selectedRate,
              selectedAmount_,
              selectedEventForBet.type
            ).loss;
            loss = cricketFancyPL(
              selectedEventForBet.selectedRate,
              selectedAmount_,
              selectedEventForBet.type
            ).profit;
          }
        } else {
          if (selectedEventForBet?.gameType == "fancy1") {
            profit = cricketMatchOddsPL(
              selectedEventForBet.selectedOdd,
              selectedAmount_,
              selectedEventForBet.type
            ).profit;
            loss = cricketMatchOddsPL(
              selectedEventForBet.selectedOdd,
              selectedAmount_,
              selectedEventForBet.type
            ).loss;
          } else {
            profit = cricketFancyPL(
              selectedEventForBet.selectedRate,
              selectedAmount_,
              selectedEventForBet.type
            ).loss;
            loss = cricketFancyPL(
              selectedEventForBet.selectedRate,
              selectedAmount_,
              selectedEventForBet.type
            ).profit;
          }
        }
      } else {
        profit = cricketMatchOddsPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).profit;
        loss = cricketMatchOddsPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).loss;
      }
    } else {
      profit = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    }
    return {
      profit: parseFloat(profit).toFixed(2),
      loss: parseFloat(loss).toFixed(2),
    };
  };

  const handleOddUpdate = (e, value) => {
    let odd = value
      ? parseFloat(
          parseFloat(selectedEventForBet.selectedOdd) + parseFloat(value)
        ).toFixed(2)
      : e.target.value;
    if (!String(odd).match(/^[0-9]*\.?[0-9]*$/)) odd = false;
    let profit;
    let loss;
    let selectedAmount = selectedEventForBet.selectedAmount
      ? selectedEventForBet.selectedAmount
      : 1000;
    if (odd > 0) {
      if (selectedEventForBet.isFancy && game == "cricket") {
        profit = cricketFancyPL(
          odd,
          selectedAmount,
          selectedEventForBet.type
        ).profit;
        loss = cricketFancyPL(
          odd,
          selectedAmount,
          selectedEventForBet.type
        ).loss;
      }
      if (selectedEventForBet.isBookmaker && game == "cricket") {
        profit = cricketBookMakerPL(
          odd,
          selectedAmount,
          selectedEventForBet.type
        ).profit;
        loss = cricketBookMakerPL(
          odd,
          selectedAmount,
          selectedEventForBet.type
        ).loss;
      }
      if (game == "casino" || game == "tennis" || game == "soccer") {
        profit = casinoPL(odd, selectedAmount, selectedEventForBet.type).profit;
        loss = casinoPL(odd, selectedAmount, selectedEventForBet.type).loss;
      }
    }

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: {
        selectedOdd: odd ? odd : 0,
        profit: odd ? parseFloat(profit).toFixed(2) : null,
        loss: odd ? parseFloat(loss).toFixed(2) : null,
      },
    });
  };
  const resetBet = () => {
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: null,
    });
  };
  const getRunnerName = (runnerName, data) => {
    let runnerName_;
    if (String(data.selectionId).startsWith("1")) {
      runnerName_ = runnerName + " (A)";
    } else {
      runnerName_ = runnerName + " (B)";
    }

    return runnerName_;
  };

  return (
    <Box
      p="1"
      borderTopRadius="5px"
      width={{ base: "100%", lg: w ? w : "30%" }}
      bg="#383838"
      h="full"
      overflowY="auto"
      height="88vh"
      className="bb-bsh"
    >
      <Modal
        size={"5xl"}
        isOpen={isResultModalOpen}
        onClose={setIsResultModalOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <CasinoResultModal
            marketId={isResultModalOpen}
            onClose={setIsResultModalOpen}
            isCustom={true}
          />
        </ModalContent>
      </Modal>
      {game == "casino" && (
        <Accordion
          w="100%"
          allowToggle
          borderRadius={10}
          bg="#383838"
          fontSize="sm"
          defaultIndex={[0]}
          p="0"
        >
          <AccordionItem p="0" w="100%" border="none">
            <AccordionButton
              w="100%"
              color="gray"
              fontWeight="bold"
              fontSize="md"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Text>Last Results</Text>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel bg="#262626" p="0" w="100%">
              <Grid
                templateColumns="repeat(10, 1fr)"
                gap={1}
                w="100%"
                p="2"
                color="gray"
              >
                {lastTenResult?.casinoMarketHistoryResponseDTOS?.map(
                  (result) => (
                    <GridItem
                      borderRadius={2}
                      fontSize="sm"
                      textAlign="center"
                      bg={isImage ? "" : "#434343"}
                      colSpan={1}
                      onClick={() => setIsResultModalOpen(result?.id)}
                    >
                      {isImage ? (
                        <Image
                          height="40px"
                          width="40px"
                          src={ASSETS_URL + "ball" + result.mainWinner + ".png"}
                        />
                      ) : (
                        <Text
                          fontWeight="bold"
                          color={
                            result.mainWinner == "1" ||
                            result.mainWinner == "A" ||
                            result.mainWinner == "B"
                              ? "tomato"
                              : result.mainWinner == "T"
                              ? "white"
                              : "yellow"
                          }
                        >
                          {eventId == "RACE20" ? (
                            <Image
                              src={ASSETS_URL + imageMap[result.mainWinner]}
                            />
                          ) : (
                            result.mainWinner
                          )}
                        </Text>
                      )}
                    </GridItem>
                  )
                )}
              </Grid>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
      {(game == "cricket" || game == "tennis" || game == "soccer") && (
        <Accordion
          w="100%"
          allowToggle
          borderRadius={10}
          bg="#383838"
          fontSize="sm"
          p="0"
          defaultChecked={false}
        >
          <AccordionItem p="0" w="100%" border="none" mt="1">
            <AccordionButton
              w="100%"
              color="gray"
              fontWeight="bold"
              fontSize="md"
              my="2"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              bg="#262626"
              borderTopEndRadius={10}
              borderTopStartRadius={10}
            >
              <Box as="span">
                <Text>Live TV</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel p="0" w="100%">
              <div>
                {channelId && (
                  <iframe
                    style={{
                      width: "100%",
                      height: "30vh",
                    }}
                    src={`https://e765432.xyz/static/f7088fa6ad4983b579bcec09709b8afbce3b7c2b/getdata.php?chid=${channelId}`}
                  />
                )}
              </div>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}

      {isMobileView && (
        <Modal
          isOpen={
            selectedEventForBet &&
            isMobileView &&
            selectedEventForBet?.selectedOdd &&
            selectedEventForBet?.selectedOdd > 0
          }
          onClose={() => {
            dispatch({
              type: "gameData/setSelectedEventForBet",
              payload: null,
            });
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Text>Bet Slip</Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody bg="#383838">
              <Text>{teamName}</Text>
              <Text>{selectedEventForBet?.marketName}</Text>
              <WrapItem
                alignItems="center"
                w="100%"
                justifyContent="space-between"
                fontSize="sm"
                my="2"
              >
                <Text color="yellow.400">
                  {selectedEventForBet?.runnerName}
                </Text>
                <NumberInput
                  minW="80px"
                  w="90px"
                  fontSize="xs"
                  border={
                    selectedEventForBet?.marketName != "MATCH_ODDS"
                      ? "1px solid #494949"
                      : "none"
                  }
                  type="number"
                  value={selectedEventForBet?.selectedOdd}
                  color={
                    selectedEventForBet?.marketName != "MATCH_ODDS"
                      ? buttonColors
                      : "white"
                  }
                >
                  <NumberInputField
                    onChange={handleOddUpdate}
                    value={selectedEventForBet?.selectedOdd}
                  />
                  {(selectedEventForBet?.marketName == "MATCH_ODDS" ||
                    selectedEventForBet?.marketName == "BOOKMAKER_ODDS_1") && (
                    <NumberInputStepper>
                      <NumberIncrementStepper
                        color="white"
                        onClick={(e) => {
                          if (selectedEventForBet?.marketName == "MATCH_ODDS") {
                            handleOddUpdate(e, 0.01);
                          } else {
                            handleOddUpdate(e, 1);
                          }
                        }}
                      />
                      <NumberDecrementStepper
                        color="white"
                        onClick={(e) => {
                          if (selectedEventForBet?.marketName == "MATCH_ODDS") {
                            handleOddUpdate(e, -0.01);
                          } else {
                            handleOddUpdate(e, -1);
                          }
                        }}
                      />
                    </NumberInputStepper>
                  )}
                </NumberInput>
              </WrapItem>
              <Flex alignItems="center" justifyContent="space-between">
                <CustomInput
                  onChange={(e) => {
                    if (e.target.value > 1000000) return;
                    setSelectedAmount(e.target.value);
                  }}
                  type="number"
                  my="2"
                  containerStyle={{ width: "30%", marginRight: "20px" }}
                  value={selectedEventForBet?.amount}
                  borderRadius={0}
                  placeholder="Amount"
                  border="none"
                  backgroundColor="#15191C"
                />

                {parseInt(selectedEventForBet?.profit) ? (
                  <Flex direction="column" w="40%">
                    <Text fontSize="sm" color="green.400">
                      profit :{" "}
                      {selectedEventForBet.profit &&
                        parseInt(selectedEventForBet?.profit)?.toFixed(2)}
                    </Text>
                    <Text fontSize="sm" color="red.400">
                      loss :{" "}
                      {selectedEventForBet.loss &&
                        parseInt(selectedEventForBet?.loss).toFixed(2)}
                    </Text>
                  </Flex>
                ) : null}
              </Flex>

              <Grid templateColumns="repeat(4, 1fr)" gap={2}>
                {amount[game == "casino" ? "casino" : "sport"].map((amt) => (
                  <GridItem
                    onClick={() => {
                      setSelectedAmount(amt.value);
                    }}
                    key={amt.label}
                    textAlign="center"
                    p={2}
                    borderRadius="md"
                    bg={"#CA6D00"}
                    cursor="pointer"
                  >
                    {amt.label}
                  </GridItem>
                ))}
              </Grid>

              <Button
                color="black"
                onClick={placeBet}
                w="100%"
                my="4"
                bg={buttonColors}
                isDisabled={selectedEventForBet?.amount == 0 || isLoading}
              >
                {isLoading ? (
                  <Image
                    h="30px"
                    w="30px"
                    src={require("../../assets/img/spinner.gif")}
                  />
                ) : (
                  "Place Bet"
                )}
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {selectedEventForBet && (
        <Accordion
          w="100%"
          allowToggle
          borderRadius={10}
          bg="#383838"
          fontSize="sm"
          defaultIndex={[0]}
          display={{
            base: "none",
            lg: "block",
          }}
        >
          <AccordionItem w="100%" border="none" mt="1">
            <AccordionButton
              w="100%"
              color="gray"
              fontWeight="bold"
              fontSize="md"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Text>Bet Slip</Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel w="100%">
              <Text>{teamName}</Text>
              <Text>{selectedEventForBet.marketName}</Text>
              <WrapItem
                alignItems="center"
                w="100%"
                justifyContent="space-between"
                fontSize="sm"
                my="2"
              >
                <Text mr="auto" color="yellow.400">
                  {selectedEventForBet?.runnerName}
                </Text>
                <NumberInput
                  minW="80px"
                  w="90px"
                  fontSize="xs"
                  border={
                    selectedEventForBet?.marketName != "MATCH_ODDS"
                      ? "1px solid #494949"
                      : "none"
                  }
                  type="number"
                  value={selectedEventForBet?.selectedOdd}
                  color={
                    selectedEventForBet?.marketName != "MATCH_ODDS"
                      ? buttonColors
                      : "white"
                  }
                >
                  <NumberInputField
                    onChange={handleOddUpdate}
                    value={selectedEventForBet?.selectedOdd}
                  />
                  {(selectedEventForBet?.marketName == "MATCH_ODDS" ||
                    selectedEventForBet?.marketName == "BOOKMAKER_ODDS_1") && (
                    <NumberInputStepper>
                      <NumberIncrementStepper
                        color="white"
                        onClick={(e) => {
                          if (selectedEventForBet?.marketName == "MATCH_ODDS") {
                            handleOddUpdate(e, 0.01);
                          } else {
                            handleOddUpdate(e, 1);
                          }
                        }}
                      />
                      <NumberDecrementStepper
                        color="white"
                        onClick={(e) => {
                          if (selectedEventForBet?.marketName == "MATCH_ODDS") {
                            handleOddUpdate(e, -0.01);
                          } else {
                            handleOddUpdate(e, -1);
                          }
                        }}
                      />
                    </NumberInputStepper>
                  )}
                </NumberInput>
                <NumberInput />
                {/* <Input
                   w="70px"
                   fontSize="xs"
                   p="2"
                   onChange={handleOddUpdate}
                   value={selectedEventForBet?.selectedOdd}
                 /> */}
              </WrapItem>
              <Flex alignItems="center" justifyContent="space-between">
                <CustomInput
                  onChange={(e) => {
                    if (e.target.value > 1000000) return;
                    setSelectedAmount(e.target.value);
                  }}
                  type="number"
                  my="2"
                  containerStyle={{ width: "30%", marginRight: "20px" }}
                  value={selectedEventForBet?.amount}
                  borderRadius={0}
                  placeholder="Amount"
                  border="none"
                  backgroundColor="#15191C"
                />

                {parseInt(selectedEventForBet?.profit) ? (
                  <Flex direction="column" w="40%">
                    <Text fontSize="sm" color="green.400">
                      profit :{" "}
                      {selectedEventForBet.profit &&
                        parseInt(selectedEventForBet?.profit)?.toFixed(2)}
                    </Text>
                    <Text fontSize="sm" color="red.400">
                      loss :{" "}
                      {selectedEventForBet.loss &&
                        parseInt(selectedEventForBet?.loss).toFixed(2)}
                    </Text>
                  </Flex>
                ) : null}
              </Flex>

              <Grid templateColumns="repeat(4, 1fr)" gap={2}>
                {amount[game == "casino" ? "casino" : "sport"].map((amt) => (
                  <GridItem
                    onClick={() => {
                      setSelectedAmount(amt.value);
                    }}
                    key={amt.label}
                    textAlign="center"
                    p={2}
                    borderRadius="md"
                    bg={"#CA6D00"}
                    cursor="pointer"
                  >
                    {amt.label}
                  </GridItem>
                ))}
              </Grid>

              <Button
                color="black"
                onClick={placeBet}
                w="100%"
                my="4"
                bg={buttonColors}
                isDisabled={selectedEventForBet?.amount == 0 || isLoading}
              >
                {isLoading ? (
                  <Image
                    h="30px"
                    w="30px"
                    src={require("../../assets/img/spinner.gif")}
                  />
                ) : (
                  "Place Bet"
                )}
              </Button>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}

      {userBets && userBets.length > 0 && (
        <Accordion
          w="100%"
          allowToggle
          borderRadius={10}
          bg="#383838"
          fontSize="sm"
          defaultIndex={[0]}
          p="0"
        >
          <AccordionItem p="0" w="100%" border="none" mt="1">
            <AccordionButton
              w="100%"
              color="gray"
              fontSize="sm"
              my="2"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              bg="#383838"
              borderTopEndRadius={10}
              borderTopStartRadius={10}
            >
              <Text fontSize="sm">MY BETS</Text>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel overflowX="auto" p="0" w="100%">
              <Table overflowX="auto" variant="unstyled" size="sm">
                <Thead fontSize="sm">
                  <Tr fontSize="xs">
                    <Th>
                      <Text color="whiteAlpha.500">Matched Bet</Text>
                    </Th>
                    <Th isNumeric>
                      <Text color="whiteAlpha.500">Odds</Text>
                    </Th>
                    <Th isNumeric>
                      <Text color="whiteAlpha.500">Stake</Text>
                    </Th>
                    <Th>
                      <Text color="whiteAlpha.500">Placed Time</Text>
                    </Th>
                    {game != "casino" &&
                      game !== "tennis" &&
                      game !== "soccer" && (
                        <Th>
                          <Text color="whiteAlpha.500">Market Name</Text>
                        </Th>
                      )}
                  </Tr>
                </Thead>
                <Tbody>
                  {game == "casino" &&
                    userBets?.map((item) => (
                      <Tr
                        borderLeft={`5px ${
                          item.back ? "#72BBEF" : "#F994BA"
                        } solid`}
                        fontWeight="bold"
                        bg="#262626"
                      >
                        <Td>
                          <Text>
                            {seperationRequired
                              ? getRunnerName(item.runnerName)
                              : item.runnerName}
                          </Text>
                        </Td>
                        <Td isNumeric>
                          <Text>{item.lay ? item.lay : item.back}</Text>
                        </Td>
                        <Td isNumeric>
                          <Text>{item.amount}</Text>
                        </Td>

                        {game == "casino" && (
                          <Td>
                            <Text fontSize="10px !important">
                              {item.marketName == "MATCH_ODDS"
                                ? "MATCH ODDS"
                                : item.marketName == "BOOKMAKER_ODDS_1" ||
                                  item.marketName == "BOOKMAKER_ODDS_2"
                                ? "BOOKMAKER ODDS"
                                : item.marketName}
                            </Text>
                          </Td>
                        )}
                      </Tr>
                    ))}
                  {userBets
                    ?.filter((item) => item.betStatus == "MATCHED")
                    ?.map((item, index) => (
                      <Tr
                        borderBottom="1px solid white"
                        color="black !important"
                        backgroundColor={item.back ? "#72BBEF" : "#F994BA"}
                        borderLeft={`5px ${
                          item.back ? "#72BBEF" : "#F994BA"
                        } solid`}
                        fontWeight="bold"
                      >
                        <Td borderRight="1px solid white">
                          <Text color="black !important">
                            {seperationRequired
                              ? getRunnerName(item.runnerName, item)
                              : item.runnerName}
                          </Text>
                        </Td>
                        <Td isNumeric borderRight="1px solid white">
                          <Text color="black !important">
                            {item.lay ? item.lay : item.back}
                          </Text>
                        </Td>
                        <Td isNumeric borderRight="1px solid white">
                          <Text color="black !important">{item.amount}</Text>
                        </Td>
                        <Td borderRight="1px solid white">
                          <Text color="black !important">
                            {moment(item.createdAt + "Z").format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </Text>
                        </Td>
                        {game != "casino" && (
                          <Td>
                            <Text
                              color="black !important"
                              fontSize="10px !important"
                            >
                              {item.marketName == "MATCH_ODDS"
                                ? "MATCH ODDS"
                                : item.marketName == "BOOKMAKER_ODDS_1" ||
                                  item.marketName == "BOOKMAKER_ODDS_2"
                                ? "BOOKMAKER ODDS"
                                : item.marketName}
                            </Text>
                          </Td>
                        )}
                      </Tr>
                    ))}
                </Tbody>
              </Table>
              {userBets.filter((item) => item.betStatus == "UNMATCHED").length >
                0 && (
                <Table variant="unstyled" size="sm">
                  <Thead fontSize="sm">
                    <Tr fontSize="xs">
                      <Th>
                        <Text color="whiteAlpha.500">Un-Matched Bet</Text>
                      </Th>
                      <Th>
                        <Text color="whiteAlpha.500">Odds</Text>
                      </Th>
                      <Th>
                        <Text color="whiteAlpha.500">Stake</Text>
                      </Th>
                      {game != "casino" &&
                        game !== "tennis" &&
                        game !== "soccer" && (
                          <Th>
                            <Text color="whiteAlpha.500">Market Name</Text>
                          </Th>
                        )}
                      {game == "cricket" && <Th>Action</Th>}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {userBets
                      .filter((item) => item.betStatus == "UNMATCHED")
                      .map((item) => (
                        <Tr
                          color="black !important"
                          backgroundColor={item.back ? "#72BBEF" : "#F994BA"}
                          borderLeft={`5px ${
                            item.back ? "#72BBEF" : "#F994BA"
                          } solid`}
                          fontWeight="bold"
                        >
                          <Td borderRight="1px solid white">
                            <Text color="black !important">
                              {seperationRequired
                                ? getRunnerName(item.runnerName, item)
                                : item.runnerName}
                            </Text>
                          </Td>
                          <Td borderRight="1px solid white">
                            <Text color="black !important">
                              {item.lay ? item.lay : item.back}
                            </Text>
                          </Td>
                          <Td borderRight="1px solid white">
                            <Text color="black !important">{item.amount}</Text>
                          </Td>
                          {game != "casino" && (
                            <Td borderRight="1px solid white">
                              <Text
                                color="black !important"
                                fontSize="10px !important"
                              >
                                {item.marketName == "MATCH_ODDS"
                                  ? "MATCH ODDS"
                                  : item.marketName == "BOOKMAKER_ODDS_1" ||
                                    item.marketName == "BOOKMAKER_ODDS_2"
                                  ? "BOOKMAKER ODDS"
                                  : item.marketName}
                              </Text>
                            </Td>
                          )}
                          {game == "cricket" && (
                            <Td>
                              <Button
                                size="xs"
                                colorScheme="blue"
                                onClick={() => deleteCricketBet(item.id)}
                              >
                                Delete
                              </Button>
                            </Td>
                          )}
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
      {inningsRule && inningsRule}
    </Box>
  );
}