import { AXIOS_INSTANCE } from "..";
import {
  CASINO_SERVICE_URL,
  getToken,
  SOCCER_AND_TENNIS_SERVICE_URL,
} from "../../utils/constants";

export const placeCasinoBetAPI = async (payload, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CASINO_SERVICE_URL}/casino/bet/place`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getMyBetsCasinoAPI = async (casinoGames, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}/casino/my-bet`,
      {
        params: {
          casinoGames,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getCasinoLastTenResultsAPI = async (casinoGames, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}/casino/last-10-results`,
      {
        params: {
          casinoGames,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getCasinoReportHistory = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CASINO_SERVICE_URL}/casino/casino-report-history`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getIndividualResultAPI = async (marketId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}/casino/individaul-result?id=${marketId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getCasinoPlExposureAPI = async (marketId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}/casino/pl-exposure?marketId=${marketId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
