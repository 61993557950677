import React from "react";
import { Box } from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from "../../assets/img/DasRsideBar/img1.png";
import Image2 from "../../assets/img/DasRsideBar/img2.png";
import Image3 from "../../assets/img/DasRsideBar/img3.png";
import Image4 from "../../assets/img/DasRsideBar/img4.png";
import Image5 from "../../assets/img/DasRsideBar/img5.png";
import Image6 from "../../assets/img/DasRsideBar/img6.png";
import Image7 from "../../assets/img/DasRsideBar/img7.png";
import Image8 from "../../assets/img/DasRsideBar/img8.png";

export default function DasRside() {
  const imags = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
  ];
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: false,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    draggable: true,
    beforeChange: function (currentSlide, nextSlide) {},
    afterChange: function (currentSlide) {},
  };
  return (
    <Box bg="rgb(0,0,0)" w="22%" color="white" p={"5px"}>
      <Slider {...settings}>
        {imags.map((e) => {
          return (
            <>
              <img
                src={e}
                style={{
                  height: "150px",
                  width: "280px",
                  borderRadius: "5px",
                }}
              />
            </>
          );
        })}
      </Slider>
    </Box>
  );
}
