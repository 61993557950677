import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Td,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import CustomInput from "../../components/CustomInput";
import CustomModal from "../../components/CustomModal";
import CustomSelect from "../../components/CustomSelect";
import CustomTable from "../../components/Table";
import {
  getCasinoReportHistory,
  getIndividualResultAPI,
} from "../../service/casino";
import { getAccountStatementAPI } from "../../service/UserService";
import {
  bgColor,
  buttonColors,
  getCurrentDateTimeLocal,
  searchSubstringInArrayObjects,
} from "../../utils/constants";

export default function CasinoResult() {
  const [from, setFrom] = React.useState(getCurrentDateTimeLocal());
  const [sport, setSport] = React.useState("DRAGON_TIGER_20");
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [selectedMarket, setSelectedMarket] = React.useState({});
  const [marketData, setMarketData] = React.useState({});
  const getCasinoResult = async () => {
    if (!sport || !from) return alert("Please select casino and date");
    const {
      response: { content },
    } = await getCasinoReportHistory({
      casinoGames: sport,
      date: new Date(from),
      page,
      size,
    });
    setData(content);
  };
  const getIndividualResult = async (marketId) => {
    if (marketId) {
      const { response } = await getIndividualResultAPI(marketId);
      setMarketData(response);
    }
  };
  const search = async (e) => {
    if (e.target.value == "") return getCasinoResult();

    setData(
      searchSubstringInArrayObjects(
        data,
        ["round_id", "winner"],
        e.target.value
      )
    );
  };
  useEffect(() => {
    getIndividualResult(selectedMarket.marketId);
  }, [selectedMarket]);

  console.log(data, "data");
  return (
    <Box bg={bgColor} p="1.5" mt="5" textColor="#AAA391">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
      >
        <Text textColor="#AAA391" fontSize="2xl">
          Casino Results
        </Text>
        <CustomInput
          placeholder="Search"
          bg="transparent"
          border="1px solid #0C9A71"
          borderRadius="0"
          color="gray"
          _placeholder={{ color: "gray" }}
          _focus={{ outline: "none" }}
          onChange={search}
        />
      </Box>
      <Box
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box display="flex" justifyContent="center" alignItems="center" my="2">
          <CustomInput
            placeholder="Date"
            type="date"
            bg="transparent"
            _placeholder={{ color: "gray" }}
            _focus={{ outline: "none" }}
            label="Date"
            onChange={(e) => {
              setFrom(e.target.value);
            }}
            value={from}
            containerStyle={{
              margin: 5,
            }}
            textColor="#AAA391"
            size="md"
            borderRadius="5"
          />

          <CustomSelect
            borderRadius="5"
            label="Type"
            textColor="#AAA391"
            containerStyle={{
              margin: 5,
            }}
            size="md"
            onChange={(e) => {
              setSport(e.target.value);
            }}
            value={sport}
            borderColor="#AAA391"
            options={[
              { label: "1 Day Teenpatti", value: "TEEN" },
              { label: "Test Teenpatti", value: "TEEN_9" },
              { label: "20-20 Teenpatti", value: "TEEN_20" },
              { label: "Open Teenpatti", value: "TEEN_8" },
              { label: "1 Day Poker", value: "POKER_1_DAY" },
              { label: "20-20 Poker", value: "POKER_20" },
              { label: "6 Player Poker", value: "POKER_9" },
              { label: "Andar Bahar Casino", value: "AB_20" },
              { label: "Worli Matka", value: "WORLI1" },
              { label: "Instant Worli", value: "WORLI2" },
              { label: "3 Cards Judgement", value: "THREE_CARD" },
              { label: "32 Cards - A", value: "CARD_32" },
              { label: "20-20 Dragon Tiger", value: "DRAGON_TIGER_20" },
              { label: "1 Day Dragon Tiger", value: "DRAGON_TIGER_6" },
              { label: "Amar Akbar Anthoni", value: "AAA" },
              { label: "Bollywood Table", value: "BOLLYWOOD_TABLE" },
              { label: "Card 32 - B", value: "CARD32EU" },
              { label: "War", value: "CASINO_WAR" },
              { label: "DTL 20", value: "DRAGON_TIGER_LION_20" },
              { label: "Casino Meter", value: "CASINO_METER" },
              { label: "20-20 Casino Match", value: "CRICKET_MATCH_20" },
              { label: "Teenpatti 2.0", value: "TEEN_6" },
              { label: "Lucky 7 - A", value: "LUCKY7" },
              { label: "Lucky 7 - B", value: "LUCKY7EU" },
              { label: "Baccarat", value: "BACCARAT" },
              { label: "Baccarat 2", value: "BACCARAT2" },
              { label: "20-20 Dragon Tiger - 2", value: "DRAGON_TIGER_20_2" },
              { label: "Andar Bahar 2", value: "ABJ" },
              { label: "5Five Cricket", value: "CRICKET_V3" },
              { label: "Queen", value: "CASINO_QUEEN" },
              { label: "Race 20-20", value: "RACE20" },
              { label: "Super Over", value: "SUPEROVER" },
            ]}
          ></CustomSelect>
          <Button
            mt="7"
            mx="5"
            size="sm"
            bg={buttonColors}
            onClick={() => getCasinoResult()}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <CustomTable
        columns={[
          {
            name: "Round Id",
            id: "round_id",
            element: (row) => (
              <Td>
                <Text
                  onClick={() => {
                    setSelectedMarket({
                      marketId: row?.marketId,
                    });

                    setModalOpen(true);
                  }}
                  color="blue.400"
                >
                  {row?.marketId}
                </Text>
              </Td>
            ),
          },
          {
            name: "Winner",
            id: "winner",
          },
        ]}
        rows={data}
        currentPage_={page}
        sizePerPage_={size}
        totalSize_={data.length}
        rowsPerPage_={20}
      />
      <Modal
        size={"lg"}
        onClose={() => setModalOpen(false)}
        isOpen={modalOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent marginTop={10} bg={"rgb(51,51,51)"}>
          <ModalHeader bg={"#3C444B"} p="1" borderRadius={"5px"}>
            <Text fontSize="sm" color={"#FDCF13"}>
              {sport} Result
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="2" bg={"#2E3439"} borderRadius={"5px"}>
            <Box p="2" display="flex" w="100%" justifyContent="flex-start">
              <Text fontSize="sm">Round Id {marketData?.mid}</Text>
            </Box>
            <Box p="2" display="flex" w="100%" justifyContent="center">
              <Flex>
                {marketData &&
                  marketData?.cards
                    ?.split(",")
                    .map((card, index) => (
                      <Image
                        m="1"
                        h="50px"
                        src={require(`../../assets/img/casino/${card}.png`)}
                      />
                    ))}
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/*  <Flex
          display="flex"
          flexDirection="column"
          w="100%"
          justifyContent="center"
          alignItems="center"
          fontSize="sm"
        >
          <Flex>
            <Text color="green.500">Result:</Text>
            <Text>{marketData?.desc?.split("*")[0]}</Text>
          </Flex>
          <Flex>
            <Text color="green.500">Dragon:</Text>
            <Text>{marketData?.desc?.split("*")[1]}</Text>
          </Flex>
          <Flex>
            <Text color="green.500">Tiger:</Text>
            <Text>{marketData?.desc?.split("*")[2]}</Text>
          </Flex>
        </Flex>
      </CustomModal> */}
    </Box>
  );
}
