import {
  Box,
  Button,
  Flex,
  Input,
  Radio,
  Select,
  Text,
} from "@chakra-ui/react";
import React from "react";
import CustomTable from "../../components/Table";

export default function UnSetteledBet() {
  return (
    <Box borderRadius="4" border="1px solid lightgray">
      <Box
        bg="#4F4F4F"
        p="2"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        fontWeight="bold"
        color="white"
      >
        <Text fontSize="xl">Un-Setteled Bet</Text>
      </Box>
      <Box mt="5" display="flex" p="2" w="40%">
        <Flex mx="1">
          <Radio mx="1" value="all" />
          <Text>Matched</Text>
        </Flex>
        <Flex mx="1">
          <Radio mx="1" value="all" />
          <Text>Un-Matched</Text>
        </Flex>
        <Flex mx="1">
          <Radio mx="1" value="all" />
          <Text>Deleted</Text>
        </Flex>
      </Box>
      <Box p="2">
        <CustomTable
          columns={[
            {
              name: "No",
              id: "no",
            },
            {
              name: "Event Name",
              id: "eventName",
            },
            {
              name: "Nation",
              id: "nation",
            },
            {
              name: "Event Type",
              id: "eventType",
            },
            {
              name: "Market Name",
              id: "marketName",
            },
            {
              name: "Side",
              id: "side",
            },
            {
              name: "Rate",
              id: "rate",
            },
            {
              name: "Amount",
              id: "amount",
            },
            {
              name: "Place Date",
              id: "placeDate",
            },
            {
              name: "matchDate",
              id: "matchDate",
            },
          ]}
          rows={[]}
        />
      </Box>
    </Box>
  );
}
