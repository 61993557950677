import React from "react";
import {
  Wrap,
  WrapItem,
  Center,
  Flex,
  Box,
  Spacer,
  Text,
} from "@chakra-ui/react";
import ImageSlider from "../../components/imageSlider";
import ExportTabs from "../home/exportTabs";
import { BiFootball } from "react-icons/bi";
import { AiTwotoneStop } from "react-icons/ai";
import { MdSportsCricket } from "react-icons/md";
import Footer from "../../includes/footer";
import DasRside from "../../includes/dasRightSideBar";


const Dashboard = () => {
  const style = {
    backgroundImage:
      "url(https://sitethemedata.com/v94/static/front/img/wave2.svg)",
    width: "20%",
    height: "50px",
    margin: "auto",
    padding: "10px",
    borderRadius: "10px",
  };
  const style2 = {
    width: "50%",
    backgroundColor: "#F18521",
    borderRadius: "7px",
    height: "40px",
    backgroundImage:
      "url(https://sitethemedata.com/v94/static/front/img/wave2.svg)",
    margin: "auto",
    padding: "10px",
    marginBottom: "5px",
  };
  return (
    <>
      <Flex>
        <Box w={"77%"}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            w={"100%"}
            justifyContent="center"
          >
            <Box style={{ ...style, backgroundColor: "#03B37F" }}>
              <Center>
                {" "}
                <BiFootball />
                &nbsp;<Text fontSize={"xs"}>Getafe v Malloco</Text>
              </Center>
            </Box>
            <Box style={{ ...style, backgroundColor: "#03B37F" }}>
              <Center>
                {" "}
                <BiFootball />
                &nbsp;<Text fontSize={"xs"}>Celta Vigo v Sevilla</Text>
              </Center>
            </Box>
            <Box style={{ ...style, backgroundColor: "#03B37F" }}>
              <Center>
                {" "}
                <BiFootball />
                &nbsp;<Text fontSize={"xs"}>west Ham v Brentford</Text>
              </Center>
            </Box>
            <Box style={{ ...style, backgroundColor: "#20327B" }}>
              <Center>
                {" "}
                <MdSportsCricket />
                &nbsp;
                <Text fontSize={"xs"}>Melbourne Renegades v Sydney...</Text>
              </Center>
            </Box>
            <Box style={{ ...style, backgroundColor: "#F18521" }}>
              <Center>
                {" "}
                <AiTwotoneStop />
                &nbsp;<Text fontSize={"xs"}>Json Kubler v Danial Evans</Text>
              </Center>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" gap=".8rem" mt={"5px"}>
            <Box style={{ ...style2 }}>
              <Center>
                {" "}
                <AiTwotoneStop />
                &nbsp;
                <Text fontSize={"xs"}>Caroline Garcia VS Nadia Podoroska</Text>
              </Center>
            </Box>
            <Box style={{ ...style2 }}>
              <Center>
                <AiTwotoneStop />
                &nbsp;
                <Text fontSize={"xs"}>Adrina Mannarina VS Federico Coria</Text>
              </Center>
            </Box>
          </Box>
          <ImageSlider width={970} />
          <ExportTabs />
          <Footer />
        </Box>
        <Spacer />
        <DasRside />
      </Flex>
    </>
  );
};

export default Dashboard;
