import React from "react";
import {
  IconButton,
  Flex,
  HStack,
  useColorModeValue,
  Text,
  Button,
  Box,
  Image,
} from "@chakra-ui/react";
import { FiMenu, FiBell } from "react-icons/fi";
import Model from "../../components/model";
import { getAnnouncementAPI } from "../../service/AuthService";
const MobileNav = ({ onOpen, ...rest }) => {
  const [announcement, setAnnouncement] = React.useState({});
  const getAnnouncement = async () => {
    const { response } = await getAnnouncementAPI();
    setAnnouncement(response);
  };
  React.useEffect(() => {
    getAnnouncement();
  }, []);
  return (
    <>
      <Box className="mobile-menu-top" display={{ base: "flex", md: "none" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <IconButton
            display={{ base: "flex", md: "none" }}
            onClick={onOpen}
            variant="outline"
            aria-label="open menu"
            icon={<FiMenu />}
            className="menu-btn"
          />
          <a href="/">
            <Box className="logo_box">
              <img src={require("../../assets/img/logo.png")} alt="logo" />
            </Box>
          </a>
        </Box>
        <Model>
          <Button className="primary">Login</Button>
        </Model>
      </Box>
      <Flex
        pr={3}
        alignItems="center"
        pos="fixed"
        right={0}
        left={0}
        top={34}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        {...rest}
        className="nav_menu_bar announcement-flex"
        zIndex={1000}
      >
        {/* <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />

        <Box className="logo_box">
          <img src={require("../../assets/img/logo.png")} alt="logo" />
        </Box> */}
        <Flex h="50px" display={{ base: "none", md: "flex" }}>
          <a href="/">
            <Image
              w="150px"
              src={require("../../assets/img/logo.png")}
              alt="logo"
            />
          </a>
          {/*  <Image
            w="50px"
            src={require("../../assets/img/side.gif")}
            alt="logo"
          /> */}
        </Flex>
        <HStack
          display={{ base: "none", md: "flex" }}
          spacing={{ base: "0", md: "6" }}
          width="100%"
        >
          <Box className="announcement_box">
            <marquee>{announcement?.announcement}</marquee>
            <i className="icon speaker" />
          </Box>

          <Flex alignItems={"center"} display={{ base: "none", md: "flex" }}>
            <Model>
              <Button className="primary">Login</Button>
            </Model>
          </Flex>
        </HStack>
      </Flex>
    </>
  );
};

export default MobileNav;
