import { Box, Button, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaAndroid } from "react-icons/fa";
import { getUserDataAPI } from "../../service/UserService";
import { useDispatch, useSelector } from "react-redux";
import { buttonColors } from "../../utils/constants";

const Header = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.account.userData);
  const location = useLocation();
  return (
    <Box pos="fixed" className="top_header" zIndex={100}>
      <Box w="100%" display="flex" alignItems="center" justifyContent="center">
        <ul>
          <li>
            <Link
              onClick={() => {
                if (!userData?.token) {
                  dispatch({
                    type: "accountData/setLoginPopSate",
                    payload: true,
                  });
                  return;
                }
              }}
              to="/"
              color="#FDCF13"
            >
              <Text color={location.pathname == "/" ? "#FDCF13" : ""}>
                Exchange
              </Text>
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                if (!userData?.token) {
                  dispatch({
                    type: "accountData/setLoginPopSate",
                    payload: true,
                  });
                  return;
                }
              }}
              to={!userData.token ? "/" : "/live-casino"}
            >
              <Text
                color={
                  location.pathname.startsWith("/live-casino") ? "#FDCF13" : ""
                }
              >
                Live Casino
              </Text>
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                if (!userData?.token) {
                  dispatch({
                    type: "accountData/setLoginPopSate",
                    payload: true,
                  });
                  return;
                }
              }}
              to="/slot"
            >
              Slot
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                if (!userData?.token) {
                  dispatch({
                    type: "accountData/setLoginPopSate",
                    payload: true,
                  });
                  return;
                }
              }}
              to="/fantasy"
            >
              Fantasy Games
            </Link>
          </li>
        </ul>
      </Box>
    </Box>
  );
};

export default Header;
