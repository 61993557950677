import { Box, Button, Input, Select, Text } from "@chakra-ui/react";
import React from "react";
import CustomTable from "../../components/Table";

export default function ProfitLossReport() {
  return (
    <Box>
      <Box
        bg="#4F4F4F"
        p="2"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        fontWeight="bold"
        color="white"
      >
        <Text fontSize="xl">Profit Loss Report</Text>
      </Box>
      <Box mt="5" display="flex" p="2" w="50%">
        <Input mx="1" type="datetime-local" />
        <Input mx="1" type="datetime-local" />
        <button
          style={{
            backgroundColor: "#0079FB",
            color: "white",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "5px",
            marginLeft: "10px",
          }}
        >
          Submit
        </button>
      </Box>
      <CustomTable
        columns={[
          {
            name: "Event Type",
            id: "eventType",
          },
          {
            name: "Event Name",
            id: "eventName",
          },
          {
            name: "Amount",
            id: "amount",
          },
        ]}
        rows={[]}
      />
    </Box>
  );
}
