import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import { Wrap } from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";
import img1 from "../../assets/img/fantacyGames/1.jpg";
import img2 from "../../assets/img/fantacyGames/2.jpg";
import img3 from "../../assets/img/fantacyGames/3.jpg";
import img4 from "../../assets/img/fantacyGames/4.png";
import img5 from "../../assets/img/fantacyGames/5.jpg";
import img6 from "../../assets/img/fantacyGames/6.jpg";
import img7 from "../../assets/img/fantacyGames/7.jpg";
import img8 from "../../assets/img/fantacyGames/8.jpg";
import img9 from "../../assets/img/fantacyGames/9.jpg";
import img10 from "../../assets/img/fantacyGames/10.jpg";
import img11 from "../../assets/img/fantacyGames/11.jpg";
import img12 from "../../assets/img/fantacyGames/12.jpg";
import img13 from "../../assets/img/fantacyGames/13.jpg";
import img14 from "../../assets/img/fantacyGames/14.jpg";
import img15 from "../../assets/img/liveCosino/1.jpg";
import img16 from "../../assets/img/liveCosino/2.jpg";
import img17 from "../../assets/img/liveCosino/3.jpg";
import img18 from "../../assets/img/liveCosino/4.jpg";
import img19 from "../../assets/img/liveCosino/5.jpg";
import img20 from "../../assets/img/liveCosino/6.jpg";
import img21 from "../../assets/img/liveCosino/7.jpg";
import img22 from "../../assets/img/liveCosino/8.jpg";
import img23 from "../../assets/img/liveCosino/9.jpg";
export default function GamesGallery() {
  const add = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
  ];
  const add2 = [img15, img16, img17, img18, img19, img20, img21, img22, img23];
  return (
    <Flex direction={"column"} width={"100%"}>
      <Box
        style={{
          backgroundColor: "#383838",
          padding: "8px",
          marginTop: 15,
          width: "100%",
          marginBottom: 15,
        }}
      >
        <Heading size={"sm"} color="#fff">
          Fantasy Games
        </Heading>
      </Box>
      <Wrap mt="5px">
        {add.map((e) => {
          return (
            <>
              <Image
                src={e}
                h={"100px"}
                w={"172px"}
                className="slide_image"
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              />
            </>
          );
        })}
      </Wrap>
      <Box
        style={{
          backgroundColor: "#383838",
          padding: "8px",
          marginTop: 15,
          width: "100%",
          marginBottom: 15,
        }}
      >
        <Heading size={"sm"} color="rgb(170,175,181)">
          Live Casino
        </Heading>
      </Box>
      <Wrap mt="5px" mb="15px">
        {add2.map((e) => {
          return (
            <>
              <Image
                src={e}
                h={"100px"}
                w={"172px"}
                className="slide_image"
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              />
            </>
          );
        })}
      </Wrap>
    </Flex>
  );
}
