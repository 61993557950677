import { Box, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Live from "../../components/live";
import {
  getAllMatchesOddsSoccerAPI,
  getAllMatchesOddsTennisAPI,
} from "../../service/soccer&tennis";
import { getAllMatchesOddsCricketAPI } from "../../service/cricket";

const MobileOddsTable = () => {
  const [selectedGame, setSelectedGame] = React.useState("all");
  const userData = useSelector((state) => state.account.userData);
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const cricketData = useSelector((state) =>
    state?.gameData?.cricketGames
      //   ?.filter((item) => item?.inPlay)
      .map((item) => {
        return {
          ...item,
          sport: "cricket",
        };
      })
  );
  const tennisData = useSelector((state) =>
    state?.gameData?.tennisGames
      //   ?.filter((item) => item?.inPlay)
      ?.map((item) => {
        return {
          ...item,
          sport: "tennis",
        };
      })
  );
  const soccerData = useSelector((state) =>
    state?.gameData?.soccerGames
      //   ?.filter((item) => item?.inPlay)
      ?.map((item) => {
        return {
          ...item,
          sport: "soccer",
        };
      })
  );
  const navigateToGame = (gameEvent) => {
    if (!userData?.token) {
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: true,
      });
      return;
    }

    if (selectedGame == "all") {
      naviagte(
        `/game/${gameEvent?.sport}/${gameEvent[gameEvent?.sport + "Id"]}`,
        {
          state: { gameEvent },
        }
      );
    } else {
      naviagte(`/game/${selectedGame}/${gameEvent[selectedGame + "Id"]}`, {
        state: { gameEvent },
      });
    }
  };
  const getSoccerMatches = async () => {
    const { response, code } = await getAllMatchesOddsSoccerAPI();
    if (code == 200) {
      dispatch({
        type: "gameData/setSoccerGames",
        payload: response,
      });
    }
  };
  const getTennisMatches = async () => {
    const { response, code } = await getAllMatchesOddsTennisAPI();
    if (code == 200) {
      dispatch({
        type: "gameData/setTennisGames",
        payload: response,
      });
    }
  };
  const getCricketMatches = async () => {
    const { response, code } = await getAllMatchesOddsCricketAPI();
    if (code == 200) {
      dispatch({
        type: "gameData/setCricketGames",
        payload: response,
      });
    }
  };
  let gamesData = [];

  if (selectedGame === "cricket") {
    gamesData = cricketData;
  } else if (selectedGame === "soccer") {
    gamesData = soccerData;
  } else if (selectedGame === "tennis") {
    gamesData = tennisData;
  } else if (selectedGame === "all") {
    gamesData = [
      ...cricketData?.filter((item) => item?.inPlay),
      ...soccerData?.filter((item) => item?.inPlay),
      ...tennisData?.filter((item) => item?.inPlay),
    ];
  }
  useEffect(() => {
    let interval;
    if (selectedGame == "cricket") {
      getCricketMatches();
      interval = setInterval(() => {
        getCricketMatches();
      }, 5000);
    } else if (selectedGame == "soccer") {
      getSoccerMatches();
      interval = setInterval(() => {
        getSoccerMatches();
      }, 5000);
    } else if (selectedGame == "tennis") {
      getTennisMatches();
      interval = setInterval(() => {
        getTennisMatches();
      }, 5000);
    } else {
      getSoccerMatches();
      getCricketMatches();
      getTennisMatches();
      interval = setInterval(() => {
        getSoccerMatches();
        getCricketMatches();
        getTennisMatches();
      }, 5000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [selectedGame]);
  return (
    <>
      <div class="bet-table">
        <div class="bet-table-header sport4">
          <Flex className="mobile-home-menu-flex">
            <Box
              onClick={() => setSelectedGame("all")}
              p="2"
              mx="1"
              className={`game-title ${selectedGame === "all" ? "active" : ""}`}
            >
              <i class="d-icon icon-4"></i>
              <span>In-Play</span>
            </Box>
            <Box
              onClick={() => setSelectedGame("cricket")}
              p="2"
              mx="1"
              className={`game-title ${
                selectedGame === "cricket" ? "active" : ""
              }`}
            >
              <i class="d-icon icon-4"></i>
              <span>Cricket</span>
            </Box>
            <Box
              onClick={() => setSelectedGame("tennis")}
              p="2"
              mx="1"
              className={`game-title ${
                selectedGame === "tennis" ? "active" : ""
              }`}
            >
              <i class="d-icon icon-4"></i>
              <span>Tennis</span>
            </Box>
            <Box
              onClick={() => setSelectedGame("soccer")}
              p="2"
              mx="1"
              className={`game-title ${
                selectedGame === "soccer" ? "active" : ""
              }`}
            >
              <i class="d-icon icon-4"></i>
              <span>Football</span>
            </Box>

            <Box
              // onClick={() => naviagte("/live-casino")}
              p="2"
              mx="1"
              className="game-title"
            >
              <i class="d-icon icon-4"></i>
              <span>Casino</span>
            </Box>
          </Flex>
          <div class="point-title d-none-mobile">1</div>
          <div class="point-title d-none-mobile">X</div>
          <div class="point-title d-none-mobile">2</div>
        </div>
        <div class="bet-table-body">
          {gamesData.map((gameEvent) => (
            <div class="bet-table-box">
              <div class="bet-table-row-header-mobile d-none-desktop">
                <div class="game-title">
                  <div class="game-date">
                    <p class="day text-left">
                      {gameEvent.inPlay ? (
                        <Box>
                          <Live />
                        </Box>
                      ) : (
                        <Text fontSize="xs">
                          {moment(
                            gameEvent.eventTime
                              ? gameEvent.eventTime
                              : gameEvent.eventDate
                          ).calendar(moment().startOf("day"))}
                        </Text>
                      )}
                    </p>
                    {/*  <p class="mb-0 day text-left">
                      {moment(
                        gameEvent.eventTime
                          ? gameEvent.eventTime
                          : gameEvent.eventDate
                      ).format("  HH:mm")}
                    </p> */}
                  </div>
                  <div class="game-name d-inline-block">
                    <a onClick={() => navigateToGame(gameEvent)} class="">
                      <p class="team-name text-left"> {gameEvent.eventName} </p>
                      <p class="team-name text-left team-event">
                        {gameEvent.seriesName
                          ? `(${gameEvent.seriesName})`
                          : ""}
                      </p>
                    </a>
                    {/* <div class="game-icons">
                    <div class="game-icon">
                      <span class="f-bm-icon">F</span>
                    </div>
                    <div class="game-icon">
                      <span class="f-bm-icon">F1</span>
                    </div>
                    <div class="game-icon">
                      <span class="f-bm-icon">BM</span>
                    </div>
                    <div class="game-icon"></div>
                  </div> */}
                  </div>
                </div>
              </div>
              <div class="bet-table-row">
                <div class="game-title d-none-mobile">
                  <div class="game-date">
                    <p class="day text-left">
                      {moment(
                        gameEvent.eventTime
                          ? gameEvent.eventTime
                          : gameEvent.eventDate
                      ).calendar(moment().startOf("day"))}
                    </p>
                    {/* <p class="mb-0 day text-left">
                      {moment(
                        gameEvent.eventTime
                          ? gameEvent.eventTime
                          : gameEvent.eventDate
                      ).format("  HH:mm")}
                    </p> */}
                  </div>
                  <div class="game-name d-inline-block">
                    <a onClick={() => navigateToGame(gameEvent)} class="">
                      <p class="day text-left">
                        {moment(
                          gameEvent.eventTime
                            ? gameEvent.eventTime
                            : gameEvent.eventDate
                        ).calendar(moment().startOf("day"))}
                      </p>
                      {/*  <p class="mb-0 day text-left">
                        {moment(
                          gameEvent.eventTime
                            ? gameEvent.eventTime
                            : gameEvent.eventDate
                        ).format("  HH:mm")}
                      </p> */}
                    </a>
                  </div>
                  {/* <div class="game-icons">
                  <div class="game-icon">
                    <span class="f-bm-icon">F1</span>
                  </div>
                  <div class="game-icon">
                    <span class="f-bm-icon">F</span>
                  </div>
                  <div class="game-icon">
                    <span class="f-bm-icon">BM</span>
                  </div>
                  <div class="game-icon"></div>
                </div> */}
                </div>

                <div class="point-title">
                  <div class="text-center d-none-desktop point-title-header">
                    {" "}
                    1{" "}
                  </div>
                  <div class="back bl-box">
                    <span class="d-block odds">{gameEvent.back1}</span>
                  </div>

                  <div class="lay bl-box">
                    <span class="d-block odds">{gameEvent.lay1}</span>
                  </div>
                </div>

                <div class="point-title">
                  <div class="text-center d-none-desktop point-title-header">
                    {" "}
                    X{" "}
                  </div>
                  <div class="no-val bl-box">
                    <span class="d-block odds">—</span>
                  </div>
                  <div class="no-val bl-box">
                    <span class="d-block odds">—</span>
                  </div>
                </div>

                <div class="point-title">
                  <div class="text-center d-none-desktop point-title-header">
                    {" "}
                    2{" "}
                  </div>
                  <div class="back bl-box">
                    <span class="d-block odds">{gameEvent.back11}</span>
                  </div>

                  <div class="lay bl-box">
                    <span class="d-block odds">{gameEvent.lay11}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MobileOddsTable;
