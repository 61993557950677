import React from "react";
import Iframe from "react-iframe";

const IframeComponent = ({ url, width, height, ...rest }) => {
  return (
    <>
      <Iframe
        url={url}
        width={width || "100%"}
        height={height || "94%"}
        {...rest}
      />
    </>
  );
};

export default IframeComponent;
