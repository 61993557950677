/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
} from "@chakra-ui/react";

import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "../includes/header";
import SidebarContent from "../includes/sidebar";
import MobileNav from "../includes/mobileNav";
import { useSelector } from "react-redux";

export default function PublicOutlet(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userData = useSelector((state) => state.account.userData);
  const location = useLocation();
  return (
    <>
      <Header />
      <Box minH="100vh">
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box
          ml={{
            base: 0,
            md: location.pathname.startsWith("/report") ? 0 : 60,
          }}
          p={location.pathname.startsWith("/report") ? 0 : 0}
          paddingTop={location.pathname.startsWith("/report") ? 100 : 98}
          className="content-box"
        >
          {props.children}
        </Box>
      </Box>
    </>
  );
}
