import React, { useEffect } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../components/Iframe";

export default function Video() {
  const videoRef = React.useRef(null);
  const [video, setVideo] = React.useState(null);
  const subscribeToSocket = () => {
    let socket = new WebSocket("wss://skt1.casinovid.in/3035");
    socket.onopen = () => {};
    socket.onmessage = (event) => {};
  };
  useEffect(() => {
    subscribeToSocket();
  }, []);
  return (
    <div>
      <IframeComponent
        url={`https://gbsroute.casinovid.in/supervid/?id=3035`}
      />
    </div>
  );
}
