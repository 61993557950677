import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Table,
  Td,
  Text,
  Thead,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import OddsBox from "./OddsBox";
import OddsRow from "./OddsRow";
import "./ui.css";
import { applyFancyFor } from "../../../utils/constants";
export default function MatchOdds_({
  currentKey,
  data,
  game,
  setSelectedEventForBet,
  eventId,
  bm,
  num,
}) {
  const ref = useRef();

  const convertToLacs = (num) => {
    if (num > 1000 && num <= 100000) {
      return parseInt(num / 1000) + "K";
    }
    if (num > 100000) {
      return parseInt(num / 100000) + "L";
    } else {
      return num;
    }
  };
  var gameSettings = useSelector((state) => state.gameData[game + "Settings"]);
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }
  const getExposure = (match) => {
    let exposure_;
    let isNotFancy =
      applyFancyFor.indexOf(data.gameType) == -1 && data.gameType != "oddeven";
    exposure_ = gameSettings?.plExposureResponseDTOs?.filter(
      (item) => item.marketIdExternal == match.marketId
    );

    if (isNotFancy && exposure_) {
      exposure_ = exposure_[0]?.runnerPlExposureDTOS?.find(
        (item) => item.selectionId == match.selectionId
      );
      return exposure_;
    }

    if (exposure_ && exposure_.length > 0) return exposure_[0];
    else return null;
  };
  const setData = (eventData) => {
    if (game == "cricket") {
      if (eventData.isFancy || eventData.isBookmaker) {
        setSelectedEventForBet({
          ...eventData,
          profit: 0,
          loss: 0,
          amount: 0,
        });
      } else {
        setSelectedEventForBet({
          ...eventData,
          marketId: data[0].marketId,
          marketName: data[0].marketName,
          profit: 0,
          loss: 0,
          amount: 0,
        });
      }
    } else {
      setSelectedEventForBet({
        ...eventData,
        marketId: data.marketId,
        marketName: data.marketName,
        eventId: data.eventId,
        profit: 0,
        loss: 0,
        amount: 0,
      });
    }
  };

  return (
    <div w="100%" gap={0}>
      <WrapItem
        w="100%"
        fontSize="xs"
        className={
          currentKey?.startsWith("bookMaker")
            ? "bookmaker-wrap-b-l"
            : "soccer-tennis-list-wrap"
        }
      >
        {(game == "soccer" || game == "tennis") &&
        window.screen.width < 786 ? null : (
          <span
            style={{
              marginLeft: currentKey?.startsWith("fancy") ? "-60px" : "1",
            }}
            className="row-title"
          ></span>
        )}
        {game == "soccer" || game == "tennis" ? (
          <Box
            display="flex"
            justifyContent="center"
            className={`back-lay-text-box soccer-tennis`}
          >
            <Box className="oddsBoxb title-back-lay  soccer-tennis-back-lay">
              <Text textColor="black" fontWeight="bold">
                {currentKey?.startsWith("fancy") ? "Yes" : "BACK"}
              </Text>
            </Box>
            <Box className="oddsBoxr title-back-lay soccer-tennis-back-lay">
              <Text textColor="black" fontWeight="bold">
                {currentKey?.startsWith("fancy") ? "No" : "LAY"}
              </Text>
            </Box>
          </Box>
        ) : currentKey?.startsWith("fancy") ? null : (
          <Box
            display="flex"
            justifyContent="center"
            className={
              currentKey.startsWith("bookMaker")
                ? `back-lay-text-box-bookmaker`
                : `back-lay-text-box`
            }
          >
            <Box className="oddsBoxb title-back-lay">
              <Text textColor="black" fontWeight="bold">
                {currentKey?.startsWith("fancy") ? "Yes" : "BACK"}
              </Text>
            </Box>
            <Box className="oddsBoxr title-back-lay">
              <Text textColor="black" fontWeight="bold">
                {currentKey?.startsWith("fancy") ? "No" : "LAY"}
              </Text>
            </Box>
          </Box>
        )}
      </WrapItem>

      {game == "soccer" || game == "tennis" ? (
        data?.oddDetailsDTOS &&
        data?.oddDetailsDTOS?.map((match, index) => (
          <Box
            className="match-list-items"
            margin={"0px !important"}
            w="100%"
            border="1px solid #9b9797"
          >
            <OddsRow
              setSelectedEventForBet={setData}
              data={data}
              match={match}
              index={index}
              game={game}
              eventId={eventId}
              isBookmaker={true}
            />
          </Box>
        ))
      ) : currentKey?.startsWith("match") && game == "cricket" ? (
        data?.map((odds) =>
          odds?.oddDetailsDTOS?.map((match, index) => (
            <Box
              w="100%"
              border="1px solid #9b9797"
              className="match-list-items"
            >
              <OddsRow
                setSelectedEventForBet={setData}
                data={data[0]}
                match={match}
                index={index}
                game={game}
                eventId={eventId}
                isBookmaker={false}
              />
            </Box>
          ))
        )
      ) : currentKey?.startsWith("bookMaker") && game == "cricket" ? (
        Object.keys(data)?.map((key) =>
          data[key][bm].oddDetailsDTOS.map((match, index) =>
            bm == "bm1" ? (
              <Box
                w="100%"
                border="1px solid #9b9797"
                className="match-list-items"
              >
                <OddsRow
                  ref={ref}
                  setSelectedEventForBet={(data_) => {
                    setData({
                      ...data_,
                      isBookmaker: true,
                      marketId: data[key][bm]?.marketId,
                      marketName: data[key][bm]?.marketName,
                    });
                  }}
                  num={bm == "bm2" ? 2 : 6}
                  data={data[key][bm]}
                  match={match}
                  index={index}
                  game={game}
                  eventId={eventId}
                  isBookmaker={true}
                />
              </Box>
            ) : bm == "bm2" ? (
              <OddsRow
                ref={ref}
                setSelectedEventForBet={(data_) => {
                  setData({
                    ...data_,
                    isBookmaker: true,
                    marketId: data[key][bm]?.marketId,
                    marketName: data[key][bm]?.marketName,
                  });
                }}
                num={bm == "bm2" ? 2 : 6}
                data={data[key][bm]}
                match={match}
                index={index}
                game={game}
                eventId={eventId}
                isBookmaker={true}
              />
            ) : null
          )
        )
      ) : currentKey?.startsWith("fancy") || currentKey?.startsWith("odd") ? (
        <Grid
          columnGap={2}
          rowGap={2}
          w="100%"
          display={{ base: "block", md: "grid" }}
          templateColumns="repeat(2,1fr)"
          className="grid-fancy-box"
        >
          <GridItem colSpan={2}>
            <Grid w="100%" templateColumns="repeat(2,1fr)">
              {applyFancyFor.find(
                (item) => item?.toLowerCase() == data?.gameType?.toLowerCase()
              ) && (
                <GridItem w="100%">
                  <Box
                    fontSize="xs"
                    display="flex"
                    justifyContent="flex-end"
                    pr="4px"
                  >
                    <Box
                      style={
                        data?.gameType == "fancy1"
                          ? {
                              backgroundColor: "#72bbef",
                            }
                          : {}
                      }
                      className="oddsBoxr resize"
                      display={{ base: "none", md: "block" }}
                    >
                      {data?.gameType == "fancy1" ? (
                        <Text textColor="black" fontWeight="bold">
                          BACK
                        </Text>
                      ) : (
                        <Text textColor="black" fontWeight="bold">
                          {currentKey?.startsWith("fancy") ? "No" : "LAY"}
                        </Text>
                      )}
                    </Box>
                    <Box
                      style={
                        data?.gameType == "fancy1"
                          ? {
                              backgroundColor: "#f994ba",
                            }
                          : {}
                      }
                      className="oddsBoxb resize"
                      display={{ base: "none", md: "block" }}
                    >
                      {data?.gameType == "fancy1" ? (
                        <Text textColor="black" fontWeight="bold">
                          LAY
                        </Text>
                      ) : (
                        <Text textColor="black" fontWeight="bold">
                          {currentKey?.startsWith("fancy") ? "Yes" : "BACK"}
                        </Text>
                      )}
                    </Box>
                  </Box>
                </GridItem>
              )}
              <GridItem className="fancy-grid-item">
                {applyFancyFor.find(
                  (item) => item?.toLowerCase() == data?.gameType?.toLowerCase()
                ) && (
                  <Box fontSize="xs" display="flex" justifyContent="flex-end">
                    <Box
                      style={
                        data?.gameType == "fancy1"
                          ? {
                              backgroundColor: "#72bbef",
                            }
                          : {}
                      }
                      className="oddsBoxr resize"
                    >
                      <Text textColor="black" fontWeight="bold">
                        {data?.gameType == "fancy1" ? (
                          <Text textColor="black" fontWeight="bold">
                            BACK
                          </Text>
                        ) : (
                          <Text textColor="black" fontWeight="bold">
                            {currentKey?.startsWith("fancy") ? "No" : "LAY"}
                          </Text>
                        )}
                      </Text>
                    </Box>
                    <Box
                      style={
                        data?.gameType == "fancy1"
                          ? {
                              backgroundColor: "#f994ba",
                            }
                          : {}
                      }
                      className="oddsBoxb resize"
                    >
                      <Text textColor="black" fontWeight="bold">
                        {data?.gameType == "fancy1" ? (
                          <Text textColor="black" fontWeight="bold">
                            LAY
                          </Text>
                        ) : (
                          <Text textColor="black" fontWeight="bold">
                            {currentKey?.startsWith("fancy") ? "Yes" : "BACK"}
                          </Text>
                        )}
                      </Text>
                    </Box>
                  </Box>
                )}
              </GridItem>
            </Grid>
          </GridItem>
          {data?.gameType?.toLowerCase() == "oddeven" && (
            <GridItem colSpan={2}>
              <Grid
                w="100%"
                templateColumns={{
                  base: "repeat(1,1fr)",
                  md: "repeat(2,1fr)",
                }}
              >
                <GridItem
                  display={{
                    base: "none",
                    md: "block",
                  }}
                  w="100%"
                >
                  <Box
                    fontSize="xs"
                    display="flex"
                    justifyContent="flex-end"
                    pr="4px"
                  >
                    <Box
                      style={{
                        backgroundColor: "#72bbef",
                      }}
                      className="oddsBoxr resize"
                    >
                      <Text textColor="black" fontWeight="bold">
                        ODD
                      </Text>
                    </Box>
                    <Box className="oddsBoxb resize">
                      <Text textColor="black" fontWeight="bold">
                        EVEN
                      </Text>
                    </Box>
                  </Box>
                </GridItem>

                <GridItem w="100%">
                  <Box
                    fontSize="xs"
                    display="flex"
                    justifyContent="flex-end"
                    pr={{
                      base: "0px",
                      md: "0px",
                    }}
                  >
                    <Box
                      style={{
                        backgroundColor: "#72bbef",
                      }}
                      className="oddsBoxr resize"
                    >
                      <Text textColor="black" fontWeight="bold">
                        ODD
                      </Text>
                    </Box>
                    <Box className="oddsBoxb resize">
                      <Text textColor="black" fontWeight="bold">
                        EVEN
                      </Text>
                    </Box>
                  </Box>
                </GridItem>
              </Grid>
            </GridItem>
          )}
          {data?.gameType?.toLowerCase() == "khado" && (
            <GridItem colSpan={2}>
              <Grid
                w="100%"
                templateColumns={{
                  base: "repeat(1,1fr)",
                  md: "repeat(2,1fr)",
                }}
              >
                <GridItem
                  display={{
                    base: "none",
                    md: "block",
                  }}
                  w="100%"
                >
                  <Box
                    fontSize="xs"
                    display="flex"
                    justifyContent="flex-end"
                    pr="4px"
                  >
                    <Box className="oddsBoxb resize">
                      <Text textColor="black" fontWeight="bold">
                        BACK
                      </Text>
                    </Box>
                  </Box>
                </GridItem>

                <GridItem w="100%">
                  <Box
                    fontSize="xs"
                    display="flex"
                    justifyContent="flex-end"
                    pr={{
                      base: "0px",
                      md: "0px",
                    }}
                  >
                    <Box className="oddsBoxb resize">
                      <Text textColor="black" fontWeight="bold">
                        BACK
                      </Text>
                    </Box>
                  </Box>
                </GridItem>
              </Grid>
            </GridItem>
          )}

          {data?.oddDetailsDTOS?.map((match, index) => (
            <GridItem
              w="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="0"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Flex alignItems="center">
                  <Image
                    mx="10px"
                    w="18px"
                    h="15px"
                    src={require("../../../assets/img/stream.png")}
                  />
                  <Text fontSize="xs" w="100% !important">
                    {match.runnerName ? match.runnerName : match.nat}
                  </Text>
                </Flex>{" "}
                <Text
                  className="fancy-exposure-text"
                  color={
                    getExposure(match)?.exposure < 0 ? "red.400" : "green.400"
                  }
                >
                  {getExposure(match)?.exposure}
                </Text>
              </Box>

              {data.gameType?.toLowerCase() == "oddeven" ||
              data?.gameType?.toLowerCase() == "fancy1" ? null : (
                <Flex
                  data-title={match.status}
                  className={
                    applyFancyFor.find(
                      (item) =>
                        item?.toLowerCase() == data?.gameType?.toLowerCase()
                    )
                      ? match.status != "ACTIVE" &&
                        match.status != "" &&
                        match.status != null
                        ? "suspendedtext"
                        : ""
                      : "suspended"
                  }
                  ref={ref}
                >
                  {applyFancyFor.find(
                    (item) =>
                      item?.toLowerCase() == data?.gameType?.toLowerCase()
                  ) && (
                    <OddsBox
                      data={data}
                      isFancy={true}
                      match={match}
                      oddsKey={data?.gameType == "fancy1" ? "back1" : "lay1"}
                      index={index}
                      back={data?.gameType == "fancy1" ? true : false}
                      onClick={(data_) =>
                        setData({
                          ...data_,
                          isBookmaker: false,
                          isFancy: true,
                          marketId: match.marketId,
                          marketName: data.marketName,
                          runnerName: match.nat ? match.nat : match.runnerName,
                          selectionId: match.selectionId,
                          gameType: data.gameType,
                        })
                      }
                    />
                  )}
                  <OddsBox
                    data={data}
                    match={match}
                    back={data?.gameType == "fancy1" ? false : true}
                    oddsKey={data?.gameType == "fancy1" ? "lay1" : "back1"}
                    index={index}
                    isFancy={true}
                    onClick={(data_) =>
                      setData({
                        ...data_,
                        isFancy: true,
                        selectionId: match.selectionId,
                        marketId: match.marketId,
                        marketName: match.marketName,
                        runnerName: match.nat ? match.nat : match.runnerName,
                        gameType: data.gameType,
                      })
                    }
                  />
                </Flex>
              )}

              {data.gameType == "oddeven" && (
                <Flex
                  data-title={match.status}
                  className={
                    match.status != "ACTIVE" &&
                    match.status != "" &&
                    match.status != null
                      ? "suspendedtext"
                      : ""
                  }
                  ref={ref}
                >
                  <OddsBox
                    data={data}
                    isFancy={true}
                    match={match}
                    oddsKey={"lay1"}
                    index={index}
                    back={false}
                    overRide={true}
                    onClick={(data_) =>
                      setData({
                        ...data_,
                        isBookmaker: false,
                        isFancy: true,
                        marketId: match.marketId,
                        marketName: data.marketName,
                        runnerName: match.nat ? match.nat : match.runnerName,
                        selectionId: match.selectionId,
                        gameType: data.gameType,
                      })
                    }
                  />

                  <OddsBox
                    data={data}
                    match={match}
                    oddsKey={"back1"}
                    index={index}
                    isFancy={true}
                    onClick={(data_) =>
                      setData({
                        ...data_,
                        isFancy: true,
                        selectionId: match.selectionId,
                        marketId: match.marketId,
                        marketName: match.marketName,
                        runnerName: match.nat ? match.nat : match.runnerName,
                        gameType: data.gameType,
                      })
                    }
                  />
                </Flex>
              )}
            </GridItem>
          ))}
        </Grid>
      ) : null}
    </div>
  );
}
