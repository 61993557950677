import React, { Suspense, useEffect, useRef, useState } from "react";

import ExportTabs from "./exportTabs";
import { Box, Flex, Heading, Spinner } from "@chakra-ui/react";

import RigthSidebar from "../../includes/rigthSidebar/main";
import GamesGallery from "../../includes/gamesGallery";
import Footer from "../../includes/footer";
import { getUserDataAPI } from "../../service/UserService";
import { useDispatch, useSelector } from "react-redux";
import MobileOddsTable from "./MobileOddsTable";
import Slider from "../../components/Slider";
import Image1 from "./../../assets/img/rSideBar/cmeter1.jpg";
import Image2 from "./../../assets/img/rSideBar/cricketv3.jpg";
import Image3 from "./../../assets/img/rSideBar/poker.jpg";
import Image4 from "./../../assets/img/rSideBar/superover.jpg";
import Image5 from "./../../assets/img/rSideBar/teen.jpg";
import Image6 from "./../../assets/img/rSideBar/teen20.jpg";
import { useCallback } from "react";
import { dashboardBannerAPI } from "../../service/AuthService";
const ImageSlider = React.lazy(() => import("../../components/imageSlider"));

const Home = () => {
  const mainWrap = useRef();
  const images = [Image1, Image2, Image3, Image4, Image5, Image6];
  const [homeImages, setHomeImages] = useState([
    require("../../assets/img/slides/slide1.jpg"),
    require("../../assets/img/slides/slide2.jpg"),
    require("../../assets/img/slides/slide3.jpg"),
    require("../../assets/img/slides/slide4.jpg"),
    require("../../assets/img/slides/slide5.jpg"),
    require("../../assets/img/slides/slide6.jpg"),
  ]);
  const [sliderWidth, setSliderWidth] = useState(null);
  const userData = useSelector((state) => state.account.userData);
  const dispatch = useDispatch();
  const checkIfMobileView = window.innerWidth < 768;
  const getUserData = useCallback(async () => {
    if (userData.token) {
      var { response, code } = await getUserDataAPI(userData.id);
      if (code === 200) {
        dispatch({
          type: "accountData/setUserProfile",
          payload: response,
        });
      }
    }
  }, [userData.id]);
  const getBannerImages = async () => {
    var { response, code } = await dashboardBannerAPI();
    if (code === 200) {
      setHomeImages(response);
    }
  };
  useEffect(() => {
    getBannerImages();
    setSliderWidth(mainWrap?.current?.clientWidth);
    if (userData) {
      getUserData();
    }
  }, [0]);

  return (
    <>
      <Flex>
        <Box width="80%" ref={mainWrap} className="middle-body">
          <Suspense fallback={<Spinner />}>
            <ImageSlider height={213} width={sliderWidth} />
          </Suspense>
          <Box display={{ base: "flex", md: "none" }}>
            <Slider
              images={homeImages}
              itemsToShow={1}
              imageHeight={100}
              showArrows={false}
              pagination={false}
            />
          </Box>
          <Box display={{ base: "block", md: "none" }}>
            <Box bg="rgb(60,68,75)" w="100%" p={1} m={0}>
              <Heading size={"sm"} color={"#fff"}>
                {" "}
                New Launch
              </Heading>
            </Box>
            <Slider
              images={images}
              itemsToShow={2}
              imageHeight={100}
              showArrows={false}
              pagination={false}
            />
          </Box>
          <Box display={{ base: "block", md: "none" }}>
            {checkIfMobileView && <MobileOddsTable />}
          </Box>
          <Box display={{ base: "none", md: "block" }}>
            <ExportTabs />
          </Box>

          <GamesGallery />
          <Footer />
        </Box>
        <RigthSidebar />
      </Flex>
    </>
  );
};

export default Home;
