import { Box } from "@chakra-ui/react";
import React from "react";
import SimpleImageSlider from "react-simple-image-slider";

const images = [
  { url: require("../../assets/img/slides/slide1.jpg") },
  { url: require("../../assets/img/slides/slide2.jpg") },
  { url: require("../../assets/img/slides/slide3.jpg") },
  { url: require("../../assets/img/slides/slide4.jpg") },
  { url: require("../../assets/img/slides/slide5.jpg") },
  { url: require("../../assets/img/slides/slide6.jpg") },
];
const ImageSlider = ({ width, height }) => {

  return (
    <>
      <Box zIndex={-1} pos="relative" w="100%" id="main_wrap">
        <SimpleImageSlider
          width={width}
          height={height || 213}
          images={images}
          showBullets={true}
          showNavs={true}
          autoPlay={true}
          style={{ zIndex: "-100" }}
        />
      </Box>
    </>
  );
};

export default ImageSlider;
