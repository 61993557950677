import React from "react";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
export default function Annoucement() {
  const [winners, setWinners] = React.useState([]);
  const userData = useSelector((state) => state.account.userData);
  const items = [
    { id: 1, title: "item #1" },
    { id: 2, title: "item #2" },
    { id: 3, title: "item #3" },
    { id: 4, title: "item #4" },
    { id: 5, title: "item #5" },
  ];

  const settings = {
    arrow: false,
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: false,
    beforeChange: function (currentSlide, nextSlide) {},
    afterChange: function (currentSlide) {},
  };
  const generateRandomWinner = () => {
    //format {name:"JK****",amount:"100,00,000",date:"10/03/2023"}  }

    let winners = [];
    for (let i = 0; i < 10; i++) {
      let randomWinner;
      let randomName =
        String.fromCharCode(Math.floor(Math.random() * 26) + 65) +
        String.fromCharCode(Math.floor(Math.random() * 26) + 65);
      let randomAmount = Math.floor(Math.random() * 1000000);

      let randomDate = new Date(
        new Date().getTime() -
          Math.floor(Math.random() * 10) * 24 * 60 * 60 * 1000
      );

      randomWinner = {
        name: randomName + "****",
        amount: randomAmount,
        date: randomDate,
      };
      winners.push(randomWinner);
    }
    setWinners(winners);
  };
  useEffect(() => {
    generateRandomWinner();
  }, []);
  const parseToINR = (amount) =>
    amount
      .toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      })
      .replace("₹", "P");

  return (
    <>
      {!userData?.token && (
        <Card className="card">
          <CardHeader>
            <h5 className="card_title">Winner Announcement</h5>
          </CardHeader>
          <CardBody>
            <div className="card_content">
              <Slider {...settings}>
                {winners.map((ele, index) => (
                  <>
                    <Flex
                      mt="10px"
                      color="#C3C3C3"
                      w="100%"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      flexDirection="row "
                    >
                      <Text w="min-content" fontSize="sm">
                        <h3>{ele.name}</h3>
                      </Text>
                      <Flex flexDirection="column" alignItems="flex-end">
                        <Text fontSize="xs">
                          <h3>{parseToINR(ele.amount)}</h3>
                        </Text>
                        <Text fontSize="xs">
                          <h3>{moment(ele.date).format("DD/MM/YYYY HH:MM")}</h3>
                        </Text>
                      </Flex>
                    </Flex>
                  </>
                ))}
              </Slider>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
}
