import React, { useEffect } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { BsPersonCircle } from "react-icons/bs";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  IconButton,
  Flex,
  HStack,
  useColorModeValue,
  Text,
  Button,
  Box,
  Image,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { bgColor } from "../../utils/constants";
import { getAnnouncementAPI } from "../../service/AuthService";

export default function DasNAv({ onOpen, ...rest }) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.account.userData);
  const [winners, setWinners] = React.useState([]);
  const [announcement, setAnnouncement] = React.useState({});
  const items = [];
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    beforeChange: function (currentSlide, nextSlide) {},
    afterChange: function (currentSlide) {},
  };
  const generateRandomWinner = () => {
    //format {name:"JK****",amount:"100,00,000",date:"10/03/2023"}  }

    let winners = [];
    for (let i = 0; i < 10; i++) {
      let randomWinner;
      let randomName = String.fromCharCode(Math.floor(Math.random() * 26) + 65);
      let randomAmount = Math.floor(Math.random() * 100000000);
      let randomDate = Math.floor(Math.random() * 100000000);
      randomWinner = {
        name: randomName,
        amount: randomAmount,
        date: randomDate,
      };
      winners.push(randomWinner);
    }
    setWinners(winners);
  };
  const getAnnouncement = async () => {
    const { response } = await getAnnouncementAPI();
    setAnnouncement(response);
  };
  useEffect(() => {
    generateRandomWinner();
    getAnnouncement();
  }, []);
  return (
    <>
      <Box className="mobile-menu-top" display={{ base: "flex", md: "none" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <IconButton
            display={{ base: "flex", md: "none" }}
            onClick={onOpen}
            variant="outline"
            aria-label="open menu"
            icon={<FiMenu color="white" />}
            className="menu-btn"
          />
          <Box className="logo_box">
            <a href="/">
              <img src={require("../../assets/img/logo.png")} alt="logo" />
            </a>
          </Box>
        </Box>

        <Flex alignItems={"center"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Menu>
              <MenuButton
                as={Button}
                leftIcon={<BsPersonCircle color="white" />}
                rightIcon={<ChevronDownIcon color="white" />}
                bg="transparent"
                color="white"
                fontSize="xs"
                textColor="white"
                _hover={{ bg: "rgb(35,41,46)" }}
              >
                {userData.username}
              </MenuButton>
              <MenuList bg={"#16191C"} borderColor="#16191C">
                <MenuItem
                  bg={"#16191C"}
                  onClick={() => {
                    window.location.href = "/report/account-statement";
                  }}
                >
                  Account Statement
                </MenuItem>
                <MenuItem
                  bg={"#16191C"}
                  onClick={() => {
                    window.location.href = "/report/bet-history";
                  }}
                >
                  Current Bets
                </MenuItem>
                <MenuItem
                  bg={"#16191C"}
                  onClick={() => {
                    window.location.href = "/report/activity-log";
                  }}
                >
                  Activity Log
                </MenuItem>
                <MenuItem
                  bg={"#16191C"}
                  onClick={() => {
                    window.location.href = "/report/casino-result";
                  }}
                >
                  Casino Results
                </MenuItem>
                <MenuItem
                  bg={"#16191C"}
                  /* onClick={() => {
                    window.location.href = "/report/activity-log";
                  }} */
                >
                  Live Casino Bets
                </MenuItem>
                <MenuItem
                  bg={"#16191C"}
                  onClick={() => {
                    dispatch({
                      type: "accountData/setChangeButtonState",
                      payload: true,
                    });
                  }}
                >
                  Set Button Value
                </MenuItem>

                <MenuItem
                  bg={"#16191C"}
                  onClick={() => {
                    dispatch({
                      type: "accountData/setChangePopSate",
                      payload: true,
                    });
                  }}
                >
                  Change Password
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    dispatch({
                      type: "accountData/logOut",
                      payload: {},
                    });
                    dispatch({
                      type: "gameData/logOut",
                      payload: {},
                    });
                    setTimeout(() => {
                      window.location.reload();
                      window.localStorage.clear();
                      window.location.href = "/";
                    }, 500);
                  }}
                  bg={"#16191C"}
                >
                  Sign Out
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      </Box>
      <Flex
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue("#262626", "#23292e")}
        pos="fixed"
        right={0}
        left={0}
        top={34}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        {...rest}
        zIndex={1000}
        className="announcement-flex"
      >
        {/* <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      /> */}
        <Flex
          h="50px"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          mx="2"
          className="b-flex-exp"
        >
          <Text
            display={{
              base: "block",
              md: "none",
            }}
            fontSize="xs"
          >
            <span>Balance:{userData?.balance?.toFixed(2)}</span>
            <span
              onClick={() => {
                dispatch({
                  type: "accountData/setMyMarketPopState",
                  payload: true,
                });
              }}
            >
              Exp:
              {userData?.liability?.toFixed(2)}
            </span>
          </Text>
          <a href="/">
            <Image
              display={{
                base: "none",
                md: "block",
              }}
              w="150px"
              src={require("../../assets/img/logo.png")}
              alt="logo"
            />
          </a>
          {/* <Image
            display={{
              base: "none",
              md: "block",
            }}
            w="50px"
            src={require("../../assets/img/side.gif")}
            alt="logo"
          /> */}
        </Flex>
        <HStack
          spacing={{ base: "0", md: "6" }}
          width="100%"
          display={{ base: "none", md: "flex" }}
        >
          <Box className="announcement_box" borderRadius={".5rem"} h="100%">
            <marquee>{announcement?.announcement}</marquee>
            <i className="icon speaker" />
          </Box>

          <Flex alignItems={"center"} display={{ base: "none", md: "flex" }}>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent="center"
              flexDirection={"row"}
            >
              {/* <nav>
          <Link to="/settings" >Settings</Link>
          <Link to="/prof
          ile">Profile</Link>
        </nav> */}
              <Text fontSize="sm" w="40%">
                <span>Balance:{userData?.balance?.toFixed(2)} </span>
                <br />
                <span
                  onClick={() => {
                    dispatch({
                      type: "accountData/setMyMarketPopState",
                      payload: true,
                    });
                  }}
                >
                  Exp:
                  {userData?.liability?.toFixed(2)}
                </span>
              </Text>
              <Menu>
                <MenuButton
                  as={Button}
                  leftIcon={<BsPersonCircle color="white" />}
                  rightIcon={<ChevronDownIcon color="white" />}
                  bg={bgColor}
                  _active={{ bg: bgColor }}
                  _hover={{ bg: bgColor }}
                  ml="2"
                >
                  <Text color="white" fontSize="sm">
                    {userData.username}
                  </Text>
                </MenuButton>
                <MenuList bg={"#16191C"} borderColor="#16191C">
                  <MenuItem
                    bg={"#16191C"}
                    onClick={() => {
                      window.location.href = "/report/account-statement";
                    }}
                  >
                    Account Statement
                  </MenuItem>
                  <MenuItem
                    bg={"#16191C"}
                    onClick={() => {
                      window.location.href = "/report/bet-history";
                    }}
                  >
                    Current Bets
                  </MenuItem>
                  <MenuItem
                    bg={"#16191C"}
                    onClick={() => {
                      window.location.href = "/report/activity-log";
                    }}
                  >
                    Activity Log
                  </MenuItem>
                  <MenuItem
                    bg={"#16191C"}
                    onClick={() => {
                      window.location.href = "/report/casino-result";
                    }}
                  >
                    Casino Results
                  </MenuItem>
                  <MenuItem
                    bg={"#16191C"}
                    /* onClick={() => {
                    window.location.href = "/report/activity-log";
                  }} */
                  >
                    Live Casino Bets
                  </MenuItem>
                  <MenuItem
                    bg={"#16191C"}
                    onClick={() => {
                      dispatch({
                        type: "accountData/setChangeButtonState",
                        payload: true,
                      });
                    }}
                  >
                    Set Button Value
                  </MenuItem>

                  <MenuItem
                    bg={"#16191C"}
                    onClick={() => {
                      dispatch({
                        type: "accountData/setChangePopSate",
                        payload: true,
                      });
                    }}
                  >
                    Change Password
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      dispatch({
                        type: "accountData/logOut",
                        payload: {},
                      });
                      dispatch({
                        type: "gameData/logOut",
                        payload: {},
                      });
                      setTimeout(() => {
                        window.location.reload();
                        window.localStorage.clear();
                        window.location.href = "/";
                      }, 500);
                    }}
                    bg={"#16191C"}
                  >
                    Sign Out
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Flex>
        </HStack>
      </Flex>
    </>
  );
}
