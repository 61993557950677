import React, { useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Text,
  Card,
  CardHeader,
  CardBody,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Button,
} from "@chakra-ui/react";
import { BiFootball } from "react-icons/bi";
import { MdSportsCricket, MdSportsTennis } from "react-icons/md";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { getAllCricketMatchListAPI } from "../../service/cricket";
import {
  getAllSoccerMatchListAPI,
  getAllTennisMatchListAPI,
} from "../../service/soccer&tennis";
import { FaHorse } from "react-icons/fa";
import { bgColor, buttonColors } from "../../utils/constants";
import { SearchIcon } from "@chakra-ui/icons";
import { searchAPI, upcomingFixtureAPI } from "../../service/AuthService";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
const AllSportsLinkItems = [
  {
    name: "cricket",
    icon: "cricket",
    isMap: false,
    iconIsPng: require("../../assets/img/icons/cricket.png"),
    nonStatic: true,
  },
  {
    name: "soccer",
    icon: "football",
    isMap: true,
    iconIsPng: require("../../assets/img/icons/soccer.png"),
    nonStatic: true,
  },
  {
    name: "tennis",
    icon: "tennis",
    isMap: true,
    iconIsPng: require("../../assets/img/icons/tennis.png"),
    nonStatic: true,
  },
  {
    name: "tableTennis",
    icon: "tableTennis",
    isMap: false,
    iconIsPng: require("../../assets/img/icons/table-tennis.png"),
    noOfGames: 2,
  },
  {
    name: "basketball",
    icon: "basketball",
    isMap: false,
    iconIsPng: require("../../assets/img/icons/basketball.png"),
    noOfGames: 6,
  },
  {
    name: "volleyball",
    icon: "volleyball",
    isMap: false,
    iconIsPng: require("../../assets/img/icons/volleyball.png"),
    noOfGames: 2,
  },
  {
    name: "snooker",
    icon: "snooker",
    isMap: false,
    iconIsPng: require("../../assets/img/icons/snooker.png"),
    noOfGames: 7,
  },
  {
    name: "handball",
    icon: "handball",
    isMap: false,
    iconIsPng: require("../../assets/img/icons/handball.png"),
    noOfGames: 8,
  },
  {
    name: "iceHockey",
    icon: "iceHockey",
    isMap: false,
    iconIsPng: require("../../assets/img/icons/ice-hockey.png"),
    noOfGames: 3,
  },
  {
    name: "eGames",
    icon: "eGames",
    isMap: false,
    iconIsPng: require("../../assets/img/icons/e-games.png"),
    noOfGames: 6,
  },
  {
    name: "futsal",
    icon: "futsal",
    isMap: false,
    iconIsPng: require("../../assets/img/icons/kabaddi.png"),
    noOfGames: 5,
  },
  {
    name: "kabaddi",
    icon: "kabaddi",
    isMap: false,
    iconIsPng: require("../../assets/img/icons/kabaddi.png"),
    noOfGames: 8,
  },
  {
    name: "golf",
    icon: "golf",
    isMap: false,
    iconIsPng: require("../../assets/img/icons/kabaddi.png"),
    noOfGames: 10,
  },
  {
    name: "rugbyLeague",
    icon: "rugbyLeague",
    isMap: false,
    iconIsPng: require("../../assets/img/icons/kabaddi.png"),
    noOfGames: 2,
  },
  {
    name: "boxing",
    icon: "boxing",
    isMap: false,
    iconIsPng: require("../../assets/img/icons/kabaddi.png"),
    noOfGames: 7,
  },
];
const RacingLinkItems = [
  {
    name: "Horse racing",
    icon: FaHorse,
    isMap: require("../../assets/img/icons/kabaddi.png"),
  },
];

const SidebarContent = ({ onClose, ...rest }) => {
  const naviagte = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchResultRef = useRef(null);
  const [upcomingFixture, setUpcomingFixture] = useState([]);
  const userData = useSelector((state) => state.account.userData);
  const [currentFixture, setCurrentFixture] = useState({});
  const [gameData, setGameData] = useState({
    cricket: [],
    soccer: [],
    tennis: [],
    tableTennis: [],
    basketball: [],
    volleyball: [],
    snooker: [],
    handball: [],
    iceHockey: [],
    eGames: [],
    futsal: [],
    kabaddi: [],
    golf: [],
    rugbyLeague: [],
    boxing: [],
  });

  const [searchResults, setSearchResults] = useState({
    cricket: [],
    soccer: [],
    tennis: [],
  });
  const [isSearchPopOverOpen, setIsSearchPopOverOpen] = useState(false);
  const getCricketData = async () => {
    const { response } = await getAllCricketMatchListAPI();
    setGameData((prev) => ({ ...prev, cricket: response }));
  };
  const getSoccerData = async () => {
    const { response } = await getAllSoccerMatchListAPI();
    setGameData((prev) => ({ ...prev, soccer: response }));
  };
  const getTennisData = async () => {
    const { response } = await getAllTennisMatchListAPI();
    setGameData((prev) => ({ ...prev, tennis: response }));
  };
  const getUpcomingFixture = async () => {
    const { response } = await upcomingFixtureAPI();
    setUpcomingFixture(response);
  };
  useEffect(() => {
    getCricketData();
    getTennisData();
    getSoccerData();
    getUpcomingFixture();
    document.addEventListener("click", (e) => {
      if (
        searchResultRef.current &&
        !searchResultRef.current.contains(e.target)
      ) {
        setIsSearchPopOverOpen(false);
      }
    });
  }, []);
  const setRandomCurrentFixture = () => {
    const randomIndex = Math.floor(Math.random() * upcomingFixture.length);
    setCurrentFixture(upcomingFixture[randomIndex]);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setRandomCurrentFixture();
    }, 10000);
    return () => clearInterval(interval);
  }, [upcomingFixture]);

  const search = async (e) => {
    const { value } = e.target;
    if (value.length >= 3) {
      const { response, code } = await searchAPI(value);
      if (code === 200) {
        setSearchResults(response);
        setIsSearchPopOverOpen(true);
      }
    }
  };
  const navigateToGame = (id, gameEvent) => {
    if (!userData?.token) {
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: true,
      });
      return;
    }

    naviagte(`/game/${id}/${gameEvent[id + "Id"]}`, {
      state: {
        gameEvent: {
          ...gameEvent,
          eventTime: gameEvent.eventDate
            ? gameEvent.eventDate
            : gameEvent.eventTime
            ? gameEvent.eventTime
            : gameEvent.startDate,
        },
      },
    });
  };
  return (
    <Box
      transition="3s ease"
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      className="sidebar"
      {...rest}
      overflowY="auto"
      zIndex={1000}
      display={location?.pathname?.startsWith("/report") ? "none" : "block"}
    >
      <Flex
        display={{ base: "none", md: "flex" }}
        h="20"
        alignItems="center"
        mx="8"
        justifyContent="space-between"
      >
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Flex
        borderRadius="25px"
        alignItems="center"
        justifyContent="center"
        bgColor={bgColor}
        className="side-input-flex"
        p="2"
      >
        <Input
          outline="none !important"
          placeholder="Search"
          w="80%"
          bg="transparent"
          border="none"
          size="sm"
          onChange={search}
        />
        <SearchIcon color="gray" />
      </Flex>
      {(searchResults?.cricket?.length > 0 ||
        searchResults?.soccer?.length > 0 ||
        searchResults?.tennis?.length > 0) &&
        isSearchPopOverOpen && (
          <div
            ref={searchResultRef}
            style={{
              zIndex: "auto",
            }}
            className="search-list d-none-mobile"
          >
            <div className="search-title">Search Result</div>{" "}
            <div className="search-content">
              <div className="search-event-title">Cricket</div>{" "}
              {searchResults?.cricket?.map((item) => (
                <a href={`/game/cricket/${item.id}`} className>
                  <div className="search-game">
                    <div className="search-game-name">{item.eventName}</div>{" "}
                    <div className="search-game-time">
                      {moment(item.eventDate).format("DD/MM/YYYY hh:mm:ss")}
                    </div>
                  </div>
                </a>
              ))}
              <div className="search-event-title">Soccer</div>{" "}
              {searchResults?.soccer?.map((item) => (
                <a href={`/game/soccer/${item.id}`} className>
                  <div className="search-game">
                    <div className="search-game-name">{item.eventName}</div>{" "}
                    <div className="search-game-time">
                      {moment(item.eventDate).format("DD/MM/YYYY hh:mm:ss")}
                    </div>
                  </div>
                </a>
              ))}
              <div className="search-event-title">Tennis</div>{" "}
              {searchResults?.tennis?.map((item) => (
                <a href={`/game/tennis/${item.id}`} className>
                  <div className="search-game">
                    <div className="search-game-name">{item.eventName}</div>{" "}
                    <div className="search-game-time">
                      {moment(item.eventDate).format("DD/MM/YYYY hh:mm:ss")}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        )}
      <Card mt="2" className="card">
        <CardHeader>
          <h5 className="card_title">Upcoming Fixtures</h5>
        </CardHeader>
        <CardBody
          style={{
            background: "linear-gradient(#2c8268, var(--bg-sidebar))",
          }}
        >
          <div className="card_content">
            <div className="sport_title_box">
              <i className="icon football"></i>{" "}
              <p>{currentFixture?.eventName}</p>
            </div>

            <p>
              <small>
                {moment(currentFixture?.eventDate).format(
                  "DD/MM/YYYY hh:mm:ss"
                )}
              </small>
            </p>
          </div>
        </CardBody>
      </Card>
      <Card className="card">
        <CardHeader>
          <h5 className="card_title">Racing Sport</h5>
        </CardHeader>
        <CardBody>
          <div className="card_content">
            {/*  <h5
              style={{
                color: buttonColors + " !important",
              }}
              className="sports_title"
            >
              Racing Sport
            </h5> */}

            <Accordion p="0" allowToggle>
              {RacingLinkItems.map((link) => (
                <AccordionItem p="0" mt="1" border="none">
                  <AccordionButton p="0">
                    <div
                      onClick={() => naviagte("/")}
                      className="sport_title_box"
                    >
                      <i className="icon horse_racing"></i>{" "}
                      <p>
                        {link?.name?.charAt(0)?.toUpperCase() +
                          link?.name?.slice(1)}
                      </p>
                    </div>
                  </AccordionButton>

                  {/*  <AccordionPanel p="0" border="none">
                <Accordion p="0" bg="#3C444B" allowToggle border="none">
                  {link.isMap
                    ? Object.keys(gameData[link.name]).map((gameName) => (
                        <AccordionItem mt="1" border="none">
                          <AccordionButton p="0.5">
                            <Text
                              flex="1"
                              textColor="#757C82"
                              fontSize="sm"
                              textAlign="left"
                              fontWeight="bold"
                            >
                              {gameName}
                            </Text>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel
                            bg="#2E3439"
                            p="1"
                            mt="1"
                            border="none"
                          >
                            {link.isMap &&
                              gameData[link.name][gameName].map((match) => (
                                <Link to={`/match/${match.id}`}>
                                  <Text
                                    flex="1"
                                    textColor="#757C82"
                                    textAlign="left"
                                    fontWeight="bold"
                                    mt="1"
                                    fontSize="xs"
                                  >
                                    {match.eventName}
                                  </Text>
                                </Link>
                              ))}
                          </AccordionPanel>
                        </AccordionItem>
                      ))
                    : gameData[link.name].map((event) => (
                        <AccordionItem p="1" border="none">
                          <Text
                            flex="1"
                            textColor="#757C82"
                            textAlign="left"
                            fontWeight="bold"
                            mt="1"
                            fontSize="xs"
                          >
                            {event.eventName}
                          </Text>
                        </AccordionItem>
                      ))}
                </Accordion>
              </AccordionPanel> */}
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </CardBody>
      </Card>

      <Card overflowY="auto" className="card">
        <CardHeader>
          <h5 className="card_title">All Sports</h5>
        </CardHeader>
        <CardBody>
          <div className="card_content">
            <Accordion p="0" allowToggle>
              {AllSportsLinkItems?.map((link) => (
                <AccordionItem p="0" mt="1" border="none">
                  <AccordionButton cursor="pointer" p="0">
                    <div className="sport_title_box">
                      {link?.iconIsPng ? (
                        <img
                          src={link?.iconIsPng}
                          alt="icon"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "5px",
                          }}
                        />
                      ) : (
                        <i className={`icon ${link?.icon}`}></i>
                      )}
                      {gameData[link.name] && (
                        <p>
                          {link?.name?.charAt(0)?.toUpperCase() +
                            link?.name?.slice(1)}{" "}
                          {link.nonStatic &&
                            (gameData[link.name]?.length > 0 ||
                              Object.keys(gameData[link.name])?.length > 0) &&
                            `(${
                              !link.isMap
                                ? gameData[link.name]?.length
                                : Object.keys(gameData[link.name])?.length
                            })`}
                          {!link.nonStatic &&
                            `(${link.noOfGames ? link.noOfGames : 0})`}
                        </p>
                      )}
                    </div>
                    <link.icon />
                  </AccordionButton>

                  <AccordionPanel p="0" border="none">
                    <Accordion p="0" bg="#3C444B" allowToggle border="none">
                      {gameData[link.name] && link?.isMap
                        ? Object.keys(gameData[link.name])?.map((gameName) => (
                            <AccordionItem mt="1" border="none">
                              <AccordionButton cursor="pointer" p="0.5">
                                <Text
                                  flex="1"
                                  textColor="#757C82"
                                  fontSize="sm"
                                  textAlign="left"
                                  fontWeight="bold"
                                >
                                  {gameName}
                                </Text>
                                <AccordionIcon />
                              </AccordionButton>
                              <AccordionPanel
                                bg="#2E3439"
                                p="1"
                                mt="1"
                                border="none"
                              >
                                {link?.isMap &&
                                  gameData[link?.name][gameName]?.map(
                                    (match) => (
                                      <Box>
                                        <Text
                                          cursor="pointer"
                                          flex="1"
                                          textColor="#757C82"
                                          textAlign="left"
                                          fontWeight="bold"
                                          mt="1"
                                          fontSize="xs"
                                          onClick={() =>
                                            navigateToGame(link?.name, match)
                                          }
                                        >
                                          {match?.eventName}
                                        </Text>
                                      </Box>
                                    )
                                  )}
                              </AccordionPanel>
                            </AccordionItem>
                          ))
                        : gameData[link?.name]?.map((event) => (
                            <AccordionItem p="1" border="none">
                              <Text
                                flex="1"
                                cursor="pointer"
                                textColor="#757C82"
                                textAlign="left"
                                fontWeight="bold"
                                mt="1"
                                fontSize="xs"
                                onClick={() =>
                                  navigateToGame(link?.name, event)
                                }
                              >
                                {event?.eventName}
                              </Text>
                            </AccordionItem>
                          ))}
                    </Accordion>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </CardBody>
      </Card>
    </Box>
  );
};

export default SidebarContent;

const NavItem = ({ icon, children, ...rest }) => {
  return (
    <Link to="#">
      <Flex
        align="center"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};
