/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@chakra-ui/react";
import React, { useRef } from "react";
import Carousel from "react-elastic-carousel";

const Slider = ({
  images,
  itemsToShow,
  imageHeight,
  showArrows,
  pagination,
}) => {
  const itemsPerPage = itemsToShow;
  const carouselRef = useRef(null);
  const totalPages = Math.ceil(images.length / itemsPerPage);
  let resetTimeout;
  return (
    <>
      <Carousel
        ref={carouselRef}
        enableAutoPlay
        showArrows={showArrows}
        itemsToShow={itemsToShow}
        pagination={pagination}
        onNextEnd={({ index }) => {
          clearTimeout(resetTimeout);
          if (index + 1 === totalPages) {
            resetTimeout = setTimeout(() => {
              carouselRef?.current?.goTo(0);
            }, 1500); // same time
          }
        }}
      >
        {images.map((item) => {
          return (
            <>
              <Box className="slide-item">
                <img
                  src={item}
                  style={{
                    height: imageHeight,
                    width: "100%",
                    borderRadius: "5px",
                  }}
                />
              </Box>
            </>
          );
        })}
      </Carousel>
    </>
  );
};

export default Slider;
