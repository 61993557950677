import { Box, Flex, Image, Text, WrapItem } from "@chakra-ui/react";
import React, { useRef } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import OddsBox from "./OddsBox";

export default function OddsRow({
  data,
  match,
  index,
  num,
  setSelectedEventForBet,
  children,
  game,
  eventId,
  isBookmaker,
}) {
  const [exposure, setExposure] = React.useState({});
  const selectedEventForBet = useSelector(
    (state) => state.gameData.selectedEventForBet
  );

  const onClick = (item) => {
    setSelectedEventForBet(item);
  };
  var gameSettings = useSelector((state) => state.gameData[game + "Settings"]);
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }
  const ref2 = useRef();
 useEffect(() => {
   let exposure_;
   gameSettings?.plExposureResponseDTOs?.map((item) =>
     item?.runnerPlExposureDTOS?.map((event) => {
       if (isBookmaker) {
         if (
           event?.selectionId == match?.selectionId &&
           item?.marketIdExternal == data?.marketId
         ) {
           exposure_ = event;
         }
       } else {
         if (
           event?.selectionId == match?.selectionId &&
           item?.marketIdExternal == data?.marketId
         ) {
           exposure_ = event;
         }
       }
     })
   );
   setExposure(exposure_);
 }, [gameSettings?.plExposureResponseDTOs]);
 useEffect(() => {}, [selectedEventForBet]);
 const getPandL = (data, match) => {
   let pandl = null;
   if (
     match.selectionId == selectedEventForBet?.selectedId &&
     data?.marketName == selectedEventForBet?.marketName
   ) {
     if (selectedEventForBet?.type == "back") {
       pandl =
         parseInt(selectedEventForBet?.profit) +
         (parseInt(exposure?.exposure) ? exposure.exposure : 0);
     } else {
       pandl =
         (parseInt(exposure?.exposure) ? exposure.exposure : 0) -
         parseInt(selectedEventForBet?.loss);
     }
   }
   if (
     match.selectionId != selectedEventForBet?.selectedId &&
     data?.marketName == selectedEventForBet?.marketName
   ) {
     if (selectedEventForBet?.type == "lay") {
       pandl =
         parseInt(selectedEventForBet?.profit) +
         (parseInt(exposure?.exposure) ? exposure.exposure : 0);
     } else {
       pandl =
         (parseInt(exposure?.exposure) ? exposure.exposure : 0) -
         parseInt(selectedEventForBet?.loss);
     }
   }
   if (pandl == null || pandl == undefined) {
     return "";
   } else if (isNaN(pandl)) {
     return "";
   } else {
     return pandl?.toFixed(2);
   }
 };
  return (
    <WrapItem
      h="100%"
      alignItems="center"
      w="100%"
      className="match-odds-item-wrap"
    >
      <Box
        className={`match-odds-box ${num == 2 ? "" : "nation-name"}`}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        w={num == 2 ? "50%" : `calc(100% - ${num == 6 ? "354px" : "305px"})`}
        justifyContent="space-between"
        style={{ width: num == 2 ? "50% !important" : "" }}
      >
        <Flex
          ml="2"
          my="5px"
          alignItems="center"
          className="match-odds-flex-bb"
        >
          <Image
            mx="10px"
            w="18px"
            h="15px"
            src={require("../../../assets/img/stream.png")}
          />
          <Text
            className="team-name"
            fontSize={isBookmaker ? "xs" : "sm"}
            w="100% !important"
          >
            {match.runnerName ? match.runnerName : match.nat}
          </Text>
          <Text
            display={{ base: "block", md: "none" }}
            style={{
              fontSize: "x-small",
            }}
            color={exposure?.exposure >= 0 ? "green.400" : "red.400"}
          >
            {exposure?.exposure?.toFixed(2)}
          </Text>
        </Flex>

        <Text
          display={{ base: "none", md: "block" }}
          fontSize="sm"
          ml="10"
          color={exposure?.exposure >= 0 ? "green.400" : "red.400"}
        >
          {exposure?.exposure?.toFixed(2)}
        </Text>
      </Box>
      <Text
        display={{ base: "none", md: "block" }}
        fontSize="sm"
        color={getPandL(data, match) >= 0 ? "green.400" : "red.400"}
        alignSelf="center"
        mr="2"
        w="70px"
        textAlign="right"
      >
        {getPandL(data, match)}
      </Text>

      {num == 2 && (
        <Flex
          data-title={match.status}
          className={`flex-match-odds-ff ${
            match.status != "ACTIVE" &&
            match.status != "" &&
            match.status != null
              ? "suspendedtext"
              : ""
          }`}
          ref={ref2}
        >
          <OddsBox
            data={data}
            match={match}
            oddsKey={"back1"}
            index={index}
            onClick={(data) =>
              onClick({
                ...data,
                isBookmaker,
              })
            }
            back={true}
            isBookmaker={isBookmaker}
          />
          <OddsBox
            data={data}
            match={match}
            oddsKey={"lay1"}
            index={index}
            isBookmaker={isBookmaker}
            back={false}
            onClick={(data) =>
              onClick({
                ...data,
                isBookmaker,
              })
            }
          />
        </Flex>
      )}

      {num !== 2 && (
        <Flex
          data-title={match?.status}
          className={`flex-match-odds-ff ${
            match.status != "ACTIVE" &&
            match.status != "" &&
            match.status != null
              ? "suspendedtext"
              : ""
          }`}
        >
          {Object.keys(match)
            .sort((a, b) => -1)
            .map((oddsKey) =>
              oddsKey.startsWith("back") && !oddsKey.startsWith("backSize") ? (
                <OddsBox
                  isBookmaker={isBookmaker}
                  data={data}
                  match={match}
                  oddsKey={oddsKey}
                  index={index}
                  onClick={onClick}
                />
              ) : null
            )}

          {Object.keys(match).map((oddsKey) =>
            oddsKey.startsWith("lay") && !oddsKey.startsWith("laySize") ? (
              <OddsBox
                isBookmaker={isBookmaker}
                data={data}
                match={match}
                oddsKey={oddsKey}
                back={false}
                index={index}
                onClick={onClick}
              />
            ) : null
          )}
        </Flex>
      )}
    </WrapItem>
  );
}
