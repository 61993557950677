/* eslint-disable jsx-a11y/iframe-has-title */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Footer from "../../includes/footer";
import {
  getCricketEventDetailsAPI,
  getCricketOddsByMatchIdAPI,
  getMatchDetailsCricketAPI,
} from "../../service/cricket";
import {
  getMatchDetailsSoccerAPI,
  getMatchDetailsTennisAPI,
  getSoccerEventDetailsAPI,
  getSoccerOddsByMatchIdAPI,
  getTennisEventDetailsAPI,
  getTennisOddsByMatchIdAPI,
} from "../../service/soccer&tennis";
import {
  bgColor,
  buttonColors,
  createSocket,
  getProfit,
} from "../../utils/constants";
import PlaceBet from "./PlaceBet";
import MatchOdds_ from "./Ui/MatchOdds_";
import "./css/scorecard.css";
import moment from "moment";

import { useMemo } from "react";
export default function GamePage() {
  var { id, game } = useParams();
  const [sortedOdds, setSortedOdds] = useState([]);
  const location = useLocation();
  if (!id || !game) {
    var path = location.pathname.split("/");
    id = path[path.length - 1];
    game = path[path.length - 2];
  }
  const [gameEvent, setGameEvent] = useState({});
  const dispatch = useDispatch();
  const gameOdds_ = useSelector((state) => state.gameData[game + "Odds"][id]);
  const gameOdds = useMemo(() => gameOdds_, [gameOdds_]);
  var gameSettings_ = useSelector((state) => state.gameData[game + "Settings"]);
  var gameSettings = useMemo(() => gameSettings_, [gameSettings_]);
  if (gameSettings && gameSettings[id]) {
    gameSettings = gameSettings[id];
  }
  const userData_ = useSelector((state) => state.account.userData);
  const userData = useMemo(() => userData_, [userData_]);
  const getSoccerOddsByEventId = useCallback(async () => {
    const { response } = await getSoccerOddsByMatchIdAPI(id);
    let response_ = {};
    response_[id] = response;
    dispatch({
      type: "gameData/setSoccerOdds",
      payload: response_,
    });
  }, [id]);
  const getCricketOddsByMatchId = useCallback(async () => {
    const { response } = await getCricketOddsByMatchIdAPI(id);
    let response_ = {};
    response_[id] = response;
    let sessionMarkets = response_[id].fancyOdds?.find(
      (item) => item?.gameType?.toLowerCase() == "normal"
    );
    let fancyMarkets = response_[id].fancyOdds?.find(
      (item) => item?.gameType?.toLowerCase() == "fancy1"
    );
    let oddEvenMarkets = response_[id].fancyOdds?.find(
      (item) => item?.gameType?.toLowerCase() == "oddeven"
    );
    let meterMarkets = response_[id].fancyOdds?.find(
      (item) => item?.gameType?.toLowerCase() == "meter"
    );
    let khadoMarkets = response_[id].fancyOdds?.find(
      (item) => item?.gameType?.toLowerCase() == "khado"
    );

    let finalOrder = [];
    if (sessionMarkets) {
      finalOrder.push(sessionMarkets);
    }
    if (fancyMarkets) {
      finalOrder.push(fancyMarkets);
    }
    if (oddEvenMarkets) {
      finalOrder.push(oddEvenMarkets);
    }
    if (meterMarkets) {
      finalOrder.push(meterMarkets);
    }
    if (khadoMarkets) {
      finalOrder.push(khadoMarkets);
    }

    response_[id].fancyOdds = finalOrder;
    console.log("finalOrder", response_);
    dispatch({
      type: "gameData/setCricketOdds",
      payload: response_,
    });
  }, [id]);
  const getGameEventDetails = useCallback(async () => {
    if (game == "soccer") {
      const { response } = await getSoccerEventDetailsAPI(id);
      setGameEvent(response);
    } else if (game == "tennis") {
      const { response } = await getTennisEventDetailsAPI(id);
      setGameEvent(response);
    } else if (game == "cricket") {
      const { response } = await getCricketEventDetailsAPI(id);
      setGameEvent(response);
    }
  }, [id, game]);
  const getTennisOddsByMatchId = useCallback(async () => {
    const { response } = await getTennisOddsByMatchIdAPI(id);
    let response_ = {};
    response_[id] = response;
    dispatch({
      type: "gameData/setTennisOdds",
      payload: response_,
    });
  }, [id]);
  useEffect(() => {
    dispatch({
      type: "accountData/setLoading",
      payload: true,
    });
    if (game === "soccer") {
      getSoccerOddsByEventId();
    } else if (game === "tennis") {
      getTennisOddsByMatchId();
    } else {
      getCricketOddsByMatchId();
    }
    getGameEventDetails();
    let pollingInterval = null;
    pollingInterval = setInterval(() => {
      if (game === "soccer") {
        getSoccerOddsByEventId();
      } else if (game === "tennis") {
        getTennisOddsByMatchId();
      } else {
        getCricketOddsByMatchId();
      }
    }, 1000);
    const interval = setInterval(() => {
      dispatch({
        type: "accountData/setLoading",
        payload: false,
      });
    }, 3000);
    return () => {
      clearInterval(interval);
      clearInterval(pollingInterval);
    };
  }, [game, id]);
  useEffect(() => {
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: null,
    });
  }, [game, id, dispatch]);

  const setSelectedEventForBet = (data) => {
    if (data.isBookmaker) {
      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: {
          ...data,
          eventId: id,
          amount: 0,
          profit: getProfit(0, game, data).profit,
          loss: getProfit(0, game, data).loss,
        },
      });
    } else {
      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: {
          ...data,
          eventId: id,
          amount: 0,
          profit: getProfit(0, game, data).profit,
          loss: getProfit(0, game, data).loss,
        },
      });
    }
  };

  const getMatchDetailsSoccer = useCallback(async () => {
    const { response } = await getMatchDetailsSoccerAPI(id);
    dispatch({
      type: "gameData/setMatchSettings",
      payload: {
        game,
        settings: response,
        eventId: id,
      },
    });
  }, [dispatch, game, id]);

  const getMatchDetailsTennis = useCallback(async () => {
    const { response } = await getMatchDetailsTennisAPI(id);
    dispatch({
      type: "gameData/setMatchSettings",
      payload: {
        game,
        settings: response,
        eventId: id,
      },
    });
  }, [dispatch, game, id]);

  const getCricketMatchDetails = useCallback(async () => {
    const { response } = await getMatchDetailsCricketAPI(id);

    dispatch({
      type: "gameData/setMatchSettings",
      payload: {
        game,
        settings: response,
        eventId: id,
      },
    });
  }, [dispatch, game, id]);

  useEffect(() => {
    if (userData.token) {
      if (game == "soccer") {
        getMatchDetailsSoccer();
      } else if (game == "tennis") {
        getMatchDetailsTennis();
      } else if (game == "cricket") {
        getCricketMatchDetails();
      }
      let interval = setInterval(() => {
        if (game == "soccer") {
          getMatchDetailsSoccer();
        } else if (game == "tennis") {
          getMatchDetailsTennis();
        } else if (game == "cricket") {
          getCricketMatchDetails();
        }
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [
    game,
    getCricketMatchDetails,
    getMatchDetailsSoccer,
    getMatchDetailsTennis,
    userData.token,
  ]);

  return (
    <>
      <Box
        display="flex"
        flexDirection={{
          base: "column",
          sm: "column",
          lg: "row",
        }}
        minH="84.5vh"
        zIndex={100}
        className="game-page-box"
        mt="1"
      >
        <Box className="bet-for-mobile" display={{ base: "block", md: "none" }}>
          <Box
            bg={buttonColors}
            p="2"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            color="black"
            fontWeight="bold"
            pr="5"
            mt="2"
            className="game-top-main"
          >
            <Box display="flex" textColor="black" className="game-top-head-box">
              <Text>
                {game == "cricket"
                  ? gameEvent?.eventName
                  : gameEvent?.seriesName + " > " + gameEvent?.eventName}
              </Text>
              {gameEvent?.inPlay && (
                <Flex
                  ml="5px"
                  alignItems="center"
                  justifyContent="center"
                  id="gameInfoDate"
                  class="green"
                >
                  <img
                    class="icon-irun"
                    src={require("../../assets/img/icon-irun.png")}
                  />
                  <Text fontSize="sm" color="green.700">
                    In-Play
                  </Text>
                </Flex>
              )}
            </Box>
            <span>
              {moment(
                gameEvent?.eventDate
                  ? gameEvent?.eventDate
                  : gameEvent?.eventTime
                  ? gameEvent?.eventTime
                  : new Date()
              ).format("DD/MMM/YYYY hh:mm A")}
            </span>
          </Box>
          <PlaceBet
            w="100%"
            eventId={id}
            game={game}
            gameId={gameEvent?.eventId}
            teamName={gameEvent?.eventName}
          />
        </Box>
        <Box
          w={{
            base: "100%",
            sm: "100%",
            lg: "70%",
          }}
          mr="0.5"
          p="0"
        >
          {(gameEvent?.gameId || gameEvent?.eventId) && (
            <Box>
              {game == "cricket" ? (
                <iframe
                  style={{
                    width: "100%",
                  }}
                  src={`https://newscore.365cric.com/#/score3/${gameEvent?.gameId}`}
                />
              ) : game == "soccer" || game == "tennis" ? (
                <iframe
                  style={{
                    height: "30vh",
                    width: "100%",
                  }}
                  src={`https://anim.365cric.com/#/score1/${gameEvent?.eventId}`}
                />
              ) : null}
            </Box>
          )}

          <Box
            bg={buttonColors}
            p="2"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            color="black"
            fontWeight="bold"
            pr="5"
            /*  mt="5" */
            className="game-top-main"
            display={{ base: "none", md: "flex" }}
          >
            <Box display="flex" textColor="black" className="game-top-head-box">
              <Text>
                {game == "cricket"
                  ? gameEvent?.eventName
                  : gameEvent?.seriesName + " > " + gameEvent?.eventName}
              </Text>
              {gameEvent?.inPlay && (
                <Flex
                  ml="5px"
                  alignItems="center"
                  justifyContent="center"
                  id="gameInfoDate"
                  class="green"
                >
                  <img
                    class="icon-irun"
                    src={require("../../assets/img/icon-irun.png")}
                  />
                  <Text fontSize="sm" color="green.700">
                    In-Play
                  </Text>
                </Flex>
              )}
            </Box>
            {new Date(
              gameEvent?.eventDate ? gameEvent?.eventDate : gameEvent?.eventTime
            ).toLocaleString("en-US", {
              timeZone: "Asia/Kolkata",
              hour12: false,
            })}
          </Box>
          {game == "cricket" && (
            <Accordion p="0" defaultIndex={[0]} allowMultiple>
              <AccordionItem border="none" mt="1">
                <AccordionButton bg="#262626">
                  <AccordionIcon />
                  <Box
                    as="span"
                    fontSize="xs"
                    textColor="white"
                    flex="1"
                    textAlign="center"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Text>MATCH ODDS</Text>
                    <Text
                      fontWeight="bold"
                      color="yellow.300"
                      justifySelf="center"
                    >
                      Max:
                      {gameSettings?.maxStack && gameSettings?.maxStack}
                    </Text>
                  </Box>
                </AccordionButton>
                <AccordionPanel
                  display="flex"
                  flexDirection="column"
                  p="0"
                  bg="#383838"
                  className="match-odds-accordion-panel"
                >
                  <MatchOdds_
                    setSelectedEventForBet={setSelectedEventForBet}
                    currentKey={"matchOdds"}
                    data={gameOdds?.matchOdds}
                    game={game}
                    eventId={id}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
          {game == "cricket" && (
            <Flex w="100%">
              {gameOdds?.bookMakerOdds[0]?.bm1?.marketId && (
                <Accordion p="0" w={"100%"} defaultIndex={[0]} allowMultiple>
                  <AccordionItem border="none" mt="1">
                    <AccordionButton bg="#262626">
                      <AccordionIcon />
                      <Box
                        as="span"
                        fontSize="xs"
                        textColor="white"
                        flex="1"
                        textAlign="center"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Box>BOOKMAKER </Box>
                        <Text
                          fontWeight="bold"
                          color="yellow.300"
                          justifySelf="center"
                        >
                          Max:
                          {gameSettings?.bookmakerMaxStack &&
                            gameSettings?.bookmakerMaxStack}
                        </Text>
                      </Box>
                    </AccordionButton>
                    <AccordionPanel
                      display="flex"
                      flexDirection="column"
                      px="0"
                      py="2"
                      bg="#383838"
                      className="bookmaker-accordion-panel"
                    >
                      <MatchOdds_
                        setSelectedEventForBet={setSelectedEventForBet}
                        currentKey={"bookMakerOdds"}
                        data={gameOdds?.bookMakerOdds}
                        game={game}
                        eventId={id}
                        bm="bm1"
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              )}
              {/* {gameOdds?.bookMakerOdds[0]?.bm2?.marketId && (
                <Accordion p="0" w={"100%"} defaultIndex={[0]} allowMultiple>
                  <AccordionItem border="none" mt="1">
                    <AccordionButton bg="#262626">
                      <AccordionIcon />
                      <Box
                        as="span"
                        fontSize="xs"
                        textColor="white"
                        flex="1"
                        textAlign="center"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Box>BOOKMAKER 2</Box>
                        <Text
                          fontWeight="bold"
                          color="yellow.300"
                          justifySelf="center"
                        >
                          Max:
                          {gameSettings?.bookmakerMaxStack &&
                            convertToLacs(gameSettings?.bookmakerMaxStack)}
                        </Text>
                      </Box>
                    </AccordionButton>
                    <AccordionPanel
                      display="flex"
                      flexDirection="column"
                      px="0"
                      py="2"
                      bg="#383838"
                    >
                      <MatchOdds_
                        setSelectedEventForBet={setSelectedEventForBet}
                        currentKey={"bookMakerOdds"}
                        data={gameOdds?.bookMakerOdds}
                        game={game}
                        eventId={id}
                        bm="bm2"
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              )} */}
            </Flex>
          )}
          {game == "cricket" && (
            <Grid templateColumns="repeat(2,1fr)">
              {gameOdds?.fancyOdds.map((fancy) => (
                <GridItem colSpan={2}>
                  <Accordion p="0" defaultIndex={[0]} allowMultiple>
                    <AccordionItem border="none" mt="1">
                      <AccordionButton bg={"#262626"}>
                        <AccordionIcon />
                        <Box
                          as="span"
                          fontSize="xs"
                          textColor="white"
                          flex="1"
                          textAlign="left"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text textTransform="uppercase">
                            {" "}
                            {fancy.gameType === "Normal"
                              ? "Session Market"
                              : fancy.gameType}
                          </Text>
                          <Text
                            fontWeight="bold"
                            color="yellow.300"
                            justifySelf="center"
                          >
                            Max:
                            {gameSettings?.sessionMaxStack &&
                              gameSettings?.sessionMaxStack}
                          </Text>
                        </Box>
                      </AccordionButton>
                      <AccordionPanel
                        display="flex"
                        flexDirection="column"
                        pb={4}
                        bg={bgColor}
                        className="fancy-accordion-panel"
                      >
                        <MatchOdds_
                          setSelectedEventForBet={setSelectedEventForBet}
                          currentKey={"fancyOdds"}
                          data={fancy}
                          game={game}
                          eventId={id}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </GridItem>
              ))}
            </Grid>
          )}

          <Accordion p="0" defaultIndex={[0]} allowMultiple>
            <Flex w="100%">
              {(game == "tennis" || game == "soccer") && gameOdds && (
                <Accordion p="0" w={"100%"} defaultIndex={[0]} allowMultiple>
                  <AccordionItem border="none" mt="1">
                    <AccordionButton bg="#262626">
                      <AccordionIcon />
                      <Box
                        as="span"
                        fontSize="xs"
                        textColor="white"
                        flex="1"
                        textAlign="center"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Box>MATCH ODDS </Box>
                      </Box>
                    </AccordionButton>
                    <AccordionPanel
                      display="flex"
                      flexDirection="column"
                      p="0"
                      bg="#383838"
                      className="bookmaker-accordion-panel"
                    >
                      <MatchOdds_
                        setSelectedEventForBet={setSelectedEventForBet}
                        game={game}
                        currentKey={"matchOdds"}
                        data={gameOdds?.matchOddsResponseDTO[0]}
                        eventId={id}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              )}
            </Flex>
            {/*  {game == "tennis" || game == "soccer"
              ? gameOdds && (
                  <AccordionItem border="none" mt="1">
                    <AccordionButton bg="#262626">
                      <AccordionIcon />
                      <Box
                        as="span"
                        fontSize="xs"
                        textColor="white"
                        flex="1"
                        textAlign="left"
                      >
                        MATCH ODDS
                      </Box>
                    </AccordionButton>
                    <AccordionPanel
                      bg="#383838"
                      display="flex"
                      flexDirection="column"
                      p="0"
                    >
                      <MatchOdds_
                        setSelectedEventForBet={setSelectedEventForBet}
                        game={game}
                        data={gameOdds?.matchOddsResponseDTO[0]}
                        eventId={id}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                )
              : null} */}
          </Accordion>
        </Box>
        <Box
          overflowY="auto"
          right={0}
          w="26%"
          pos="fixed"
          className="bet-box-right"
          /* mt="-10px" */
        >
          <PlaceBet
            w="100%"
            eventId={id}
            game={game}
            teamName={gameEvent?.eventName}
            gameId={gameEvent?.eventId}
          />
        </Box>
      </Box>
      <Box w="70%" className="game-footer-page-box">
        <Footer />
      </Box>
    </>
  );
}
