import React from "react";
import { Box } from "@chakra-ui/react";
import Annoucement from "./annoucement";
import Lounch from "./launch";
import OurCosino from "./ourCosino";
import VirtualCosino from "./virtualCosino";
// const Astyle = {
//     backgroundColor: "black",
//     height: "15px",
//     width: "50px"
//  }
export default function RigthSidebar() {
  return (
    <>
      <Box
        w="20%"
        color="white"
        paddingLeft={2}
        paddingRight="10px"
        className="right-sidebar"
      >
        <Annoucement />
        <Lounch />
        <OurCosino />
        <VirtualCosino />
      </Box>
    </>
  );
}
