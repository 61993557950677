import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Radio,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Td,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import {
  BsFileEarmarkExcel,
  BsFileEarmarkPdf,
  BsFileExcelFill,
  BsFilePdfFill,
} from "react-icons/bs";
import CustomTable from "../../components/Table";
import { currentBetsAPI } from "../../service/AuthService";

export default function BetHistory() {
  const [sport, setSport] = React.useState("DRAGON_TIGER_20");
  const [isCasino, setIsCasino] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [data, setData] = React.useState([]);
  const [betType, setBetType] = React.useState("ALL");
  const [search, setSearch] = React.useState("");
  const [betStatus, setBetStatus] = React.useState("MATCHED");
  const [totalElements, setTotalElements] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [size, setSize] = React.useState(25);
  const [totalBets_, setTotalBets] = React.useState(0);
  const [totalAmount_, setTotalAmount] = React.useState(0);
  const toast = useToast();
  const getCurrentBets = async () => {
    const {
      response: {
        totalAmount,
        totalBets,
        unsettledBets: { content, totalElements, totalPages, size },
      },
    } = await currentBetsAPI({
      betStatus,
      betType,
      pageSize,
      pageNumber,
      search,
      sports: !isCasino,
    });
    let data_ = content.map((item) => ({
      ...item,
      rate: item.back_run ? item.back_run : item.lay_run,
      type: item.back_run ? "BACK" : "LAY",
    }));
    setData(data_);
    setTotalElements(totalElements);
    setTotalPages(totalPages);
    setSize(size);
    setTotalBets(totalBets);
    setTotalAmount(totalAmount);
  };
  useEffect(() => {
    getCurrentBets();
  }, [pageNumber, pageSize, betType, search, betStatus, isCasino]);
  return (
    <Box fontSize="sm" mt="5" textColor="lightgray">
      <Box
        p="2"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        fontWeight="bold"
        color="white"
      >
        <Text textColor="#AAA391" fontSize="2xl">
          Current Bets
        </Text>
      </Box>
      <Tabs defaultChecked={1} align="center" variant="unstyled">
        <TabList bg="#16191C" pb="2">
          <Tab
            _selected={{
              borderBottom: "2px solid white",
            }}
            onClick={() => setIsCasino(false)}
            isSelected={!isCasino}
          >
            Sports
          </Tab>
          <Tab
            onClick={() => setIsCasino(true)}
            _selected={{
              borderBottom: "2px solid white",
            }}
            isSelected={isCasino}
          >
            Casino
          </Tab>
        </TabList>
      </Tabs>
      <Flex p="3">
        <Flex mx="1">
          <Radio
            onClick={() => setBetStatus("MATCHED")}
            mx="1"
            isChecked={betStatus === "MATCHED"}
          />
          <Text>Matched</Text>
        </Flex>
        <Flex mx="1">
          <Radio
            onClick={() => setBetStatus("UNMATCHED")}
            mx="1"
            isChecked={betStatus === "UNMATCHED"}
          />
          <Text>Un-Matched</Text>
        </Flex>
        <Flex mx="1">
          <Radio
            onClick={() => setBetStatus("DELETED")}
            mx="1"
            isChecked={betStatus === "DELETED"}
          />
          <Text>Deleted</Text>
        </Flex>
      </Flex>
      <Grid placeItems="center" my="2" templateColumns={"repeat(4, 1fr)"}>
        <GridItem placeSelf="start">
          <Flex alignItems="center">
            <Text>Show</Text>
            <Select
              onChange={(e) => setPageSize(e.target.value)}
              mx="2"
              w="100px"
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Select>
            <Text>Entries</Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex alignItems="center">
            <Flex mx="1">
              <Radio
                isChecked={betType === "ALL"}
                onClick={() => setBetType("ALL")}
                mx="1"
                value="ALL"
              />
              <Text>All</Text>
            </Flex>
            <Flex mx="1">
              <Radio
                isChecked={betType === "BACK"}
                onClick={() => setBetType("BACK")}
                mx="1"
                value="BACK"
              />
              <Text>Back</Text>
            </Flex>
            <Flex mx="1">
              <Radio
                isChecked={betType === "LAY"}
                onClick={() => setBetType("LAY")}
                mx="1"
                value="LAY"
              />
              <Text>Lay</Text>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem>
          Total Bets : {totalBets_} Total Amount : {totalAmount_}
        </GridItem>
        <GridItem fontSize="3xl" placeSelf="end">
          <Flex>
            <Image
              h="8"
              w="8"
              src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
            />

            <Image
              h="8"
              w="8"
              src="https://img.icons8.com/color/512/microsoft-excel-2019--v1.png"
            />
          </Flex>
        </GridItem>
      </Grid>
      <CustomTable
        headerBg="#3C444B"
        columns={[
          {
            name: "Sports",
            id: "event_type",
            element: (item) => (
              <Td
                borderLeft={`5px solid ${
                  item?.type == "BACK"
                    ? "rgb(114, 187, 239)"
                    : "rgb(249, 148, 186)"
                }`}
              >
                <Text>{item.event_type}</Text>
              </Td>
            ),
          },
          {
            name: "Event Name",
            id: "event_name",
          },
          {
            name: "Market Name",
            id: "marketName",
          },
          {
            name: "Nation",
            id: "runner_name",
          },
          {
            name: "User Rate",
            id: "rate",
          },
          {
            name: "Amount",
            id: "amount",
          },
          {
            name: "Place Date",
            id: "created_date",
            element: (item) => (
              <Td>
                <Text>
                  {moment(item.created_date + "Z").format(
                    "DD-MM-YYYY hh:mm:ss A"
                  )}
                </Text>
              </Td>
            ),
          },
        ]}
        rows={data}
        rowsPerPage_={pageSize}
        totalPages_={totalElements}
        currentPage_={pageNumber}
      />
    </Box>
  );
}
