import React from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { BiFootball, BiGlobeAlt, BiHome } from "react-icons/bi";
import { MdSportsCricket, MdSportsTennis } from "react-icons/md";
import "./exportTab.css";
import { Stomp } from "@stomp/stompjs";
import TabsContent from "./tabsContent";
import {
  buttonColors,
  CASINO_SERVICE_URL,
  createSocket,
  CRICKET_SERVICE_URL,
  SOCCER_AND_TENNIS_SERVICE_URL,
} from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMatchesOddsSoccerAPI,
  getAllMatchesOddsTennisAPI,
} from "../../service/soccer&tennis";
import { useEffect } from "react";
import {
  getAllCricketMatchListAPI,
  getAllMatchesOddsCricketAPI,
} from "../../service/cricket";
export default function ExportTabs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedGame, setSelectedGame] = React.useState("cricket");
  const location = useLocation();

  const getSoccerMatches = async () => {
    const { response, code } = await getAllMatchesOddsSoccerAPI();
    if (code == 200) {
      dispatch({
        type: "gameData/setSoccerGames",
        payload: response,
      });
    }
  };
  const getTennisMatches = async () => {
    const { response, code } = await getAllMatchesOddsTennisAPI();
    if (code == 200) {
      dispatch({
        type: "gameData/setTennisGames",
        payload: response,
      });
    }
  };
  const getCricketMatches = async () => {
    const { response, code } = await getAllMatchesOddsCricketAPI();
    if (code == 200) {
      dispatch({
        type: "gameData/setCricketGames",
        payload: response,
      });
    }
  };
  useEffect(() => {
    let interval;
    if (selectedGame == "cricket") {
      getCricketMatches();
      interval = setInterval(() => {
        getCricketMatches();
      }, 5000);
    } else if (selectedGame == "soccer") {
      getSoccerMatches();
      interval = setInterval(() => {
        getSoccerMatches();
      }, 5000);
    } else if (selectedGame == "tennis") {
      getTennisMatches();
      interval = setInterval(() => {
        getTennisMatches();
      }, 5000);
    } else {
      getSoccerMatches();
      getCricketMatches();
      getTennisMatches();
      interval = setInterval(() => {
        getSoccerMatches();
        getCricketMatches();
        getTennisMatches();
      }, 5000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [selectedGame]);
  return (
    <>
      <Tabs
        defaultIndex={1}
        bg="#2e3439"
        size="sm"
        width={"100%"}
        marginTop={1.5}
      >
        <TabList borderBottom={0}>
          <Tab
            onClick={() => setSelectedGame("all")}
            className="tab"
            _selected={{ color: "#262626", bg: buttonColors }}
            color="#aaafb5"
          >
            <BiGlobeAlt /> &nbsp; All
          </Tab>
          <Tab
            onClick={() => {
              setSelectedGame("cricket");
              getCricketMatches();
            }}
            className="tab"
            _selected={{ color: "#262626", bg: buttonColors }}
            color="#aaafb5"
          >
            <MdSportsCricket /> &nbsp; Cricket
          </Tab>
          <Tab
            onClick={() => {
              setSelectedGame("tennis");
              getTennisMatches();
            }}
            className="tab"
            _selected={{ color: "#262626", bg: buttonColors }}
            color="#aaafb5"
          >
            <MdSportsTennis /> &nbsp; Tennis
          </Tab>
          <Tab
            onClick={() => {
              setSelectedGame("soccer");
              getSoccerMatches();
            }}
            className="tab"
            _selected={{ color: "#262626", bg: buttonColors }}
            color="#aaafb5"
          >
            <BiFootball /> &nbsp; Soccer
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel padding={0}>
            <TabsContent id="all" icon="all" bg={buttonColors} />
          </TabPanel>

          <TabPanel padding={0}>
            <TabsContent id="cricket" icon="cricket_dark" bg={buttonColors} />
          </TabPanel>
          <TabPanel padding={0}>
            <TabsContent id="tennis" icon="tennis" bg={buttonColors} />
          </TabPanel>
          <TabPanel padding={0}>
            <TabsContent id="soccer" icon="football" bg={buttonColors} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}
