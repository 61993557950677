import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Route,
  Router,
  Routes,
  useLocation,
} from "react-router-dom";
import PrivateOutlet from "./layout/PrivateOutlet";
import PublicOutlet from "./layout/PublicOutlet";
import AccountStatement from "./pages/accountStatement/AccountStatement";
import ActivityLog from "./pages/activityLog/ActivityLog";
import BetHistory from "./pages/betHistory/BetHistory";
import CasinoList from "./pages/casino/CasinoList";
import Cards32A from "./pages/casino/games/32Cards-A";
import Cards32B from "./pages/casino/games/32Cards-B";
import JudgementCards3 from "./pages/casino/games/3CardsJudgement";
import AmarAkbarAnthony from "./pages/casino/games/AmabAkbarAnthony";
import AndarBahar from "./pages/casino/games/AndarBahar";
import AndarBahar2 from "./pages/casino/games/AndarBahar-2";
import BollywoodCasino from "./pages/casino/games/BollywoodCasino";
import CasinoMeter from "./pages/casino/games/CasinoMeter";
import CasinoQueen from "./pages/casino/games/CasinoQueen";
import DragonTiger1Day from "./pages/casino/games/DragonTiger1Day";
import DragonTiger202 from "./pages/casino/games/DragonTiger202";
import DragonTiger201 from "./pages/casino/games/DragonTiger201";
import InstantWorli from "./pages/casino/games/InstantWorli";
import Lucky7A from "./pages/casino/games/Lucky7A";
import Lucky7B from "./pages/casino/games/Lucky7B";
import Poker20 from "./pages/casino/games/Poker20-20";
import Race2020 from "./pages/casino/games/Race2020";
import TeenPatti from "./pages/casino/games/TeenPatti";
import TeenPatti1Day from "./pages/casino/games/Teenpatti1Day";
import CasinoResult from "./pages/casinoResults/CasinoResults";
import Dashboard from "./pages/dashboard";
import GamePage from "./pages/game/GamePage";
import Home from "./pages/home";
import ProfitLossReport from "./pages/profitLossReport/ProfitLossReport";
import UnSetteledBet from "./pages/unSetteledBet/UnSetteledBet";
import Video from "./pages/Video";
import store, { saveState } from "./redux/store";
import DragonTigerLion from "./pages/casino/games/DragonTigerLion";
import TeenPattiTest from "./pages/casino/games/TeenPattiTest";
import TeenPattiOpen from "./pages/casino/games/TeenPattiOpen";
import CasinoWar from "./pages/casino/games/CasinoWar";
import Five5Cricket from "./pages/casino/games/Five-5Cricket";
import Cricket2020 from "./pages/casino/games/Cricket20-20";
import Poker1Day from "./pages/casino/games/Poker1Day";
import Pokerr6Players from "./pages/casino/games/Poker6Players";
import Baccart from "./pages/casino/games/Baccart";
import Baccart2 from "./pages/casino/games/Baccarat2";
import Worli from "./pages/casino/games/WorliMatka";
import FiveCricket from "./pages/casino/games/Five-5Cricket";
import SuperOver from "./pages/casino/games/SuperOver";
import Loading from "./components/Loading";
import {
  getAllMatchesOddsSoccerAPI,
  getAllMatchesOddsTennisAPI,
  getAllSoccerMatchListAPI,
} from "./service/soccer&tennis";
import { createSocket } from "./utils/constants";

export default function App() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.account.userData);
  const isLoading = useSelector((state) => state.account.loading);
  const location = useLocation();

  useEffect(() => {
    store.subscribe(() => {
      saveState(store.getState());
    });
  }, [0]);
  useEffect(() => {
    /* let socket; */

    if (location.pathname.includes("live-casino")) {
      /*   const gameId_ = location.pathname.split("/")[2];
      if (gameId_) {
        socket = createSocket("casino", dispatch, gameId_);
      } */
    } else if (userData?.token && userData?.resetRequired) {
      dispatch({
        type: "accountData/setChangePopSate",
        payload: true,
      });
    }
    return () => {
      /*  if (location.pathname.includes("live-casino")) {
        const gameId_ = location.pathname.split("/")[2];
        if (gameId_) {
          socket.disconnect();
        }
      } */
    };
  }, [location.pathname, userData?.token, userData?.resetRequired]);
  return userData.token ? (
    <PrivateOutlet>
      {/*  {isLoading && <Loading />}  */}

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/profile" element={<Dashboard />} />
        <Route exact path="/game/:game/:id" element={<GamePage />} />
        {/*  <Route path="/live-casino" element={<CasinoList />} />
        <Route
          path="/live-casino/dragonTiger202"
          element={<DragonTiger202 />}
        />
        <Route path="/live-casino/teenPatti20" element={<TeenPatti />} />
        <Route path="/live-casino/card32" element={<Cards32A />} />
        <Route path="/live-casino/card32eu" element={<Cards32B />} />
        <Route path="/live-casino/race20" element={<Race2020 />} />
        <Route path="/live-casino/casinoQueen" element={<CasinoQueen />} />
        <Route path="/live-casino/dragonTiger20" element={<DragonTiger201 />} />
        <Route
          path="/live-casino/dragonTigerOneDay"
          element={<DragonTiger1Day />}
        />
        <Route path="/live-casino/lucky7" element={<Lucky7A />} />
        <Route path="/live-casino/lucky7eu" element={<Lucky7B />} />
        <Route path="/live-casino/andarBahar20" element={<AndarBahar />} />
        <Route path="/live-casino/abj" element={<AndarBahar2 />} />
        <Route
          path="/live-casino/3cardsjudgement"
          element={<JudgementCards3 />}
        />
        <Route
          path="/live-casino/bollywoodTable"
          element={<BollywoodCasino />}
        />
        <Route path="/live-casino/aaa" element={<AmarAkbarAnthony />} />
        <Route path="/live-casino/teen" element={<TeenPatti1Day />} />
        <Route path="/live-casino/poker20" element={<Poker20 />} />
        <Route path="/live-casino/worli2" element={<InstantWorli />} />
        <Route path="/live-casino/casinoMeter" element={<CasinoMeter />} />
        <Route
          path="/live-casino/dragonTigerLion"
          element={<DragonTigerLion />}
        />
        <Route path="/live-casino/teen9" element={<TeenPattiTest />} />
        <Route path="/live-casino/teen8" element={<TeenPattiOpen />} />
        <Route path="/live-casino/casinoWar" element={<CasinoWar />} />
        <Route path="/live-casino/cricketV3" element={<FiveCricket />} />
        <Route path="/live-casino/cmatch20" element={<Cricket2020 />} />
        <Route path="/live-casino/poker" element={<Poker1Day />} />
        <Route path="/live-casino/poker9" element={<Pokerr6Players />} />
        <Route path="/live-casino/baccarat" element={<Baccart />} />
        <Route path="/live-casino/baccarat2" element={<Baccart2 />} />
        <Route path="/live-casino/worli" element={<Worli />} />
        <Route path="/live-casino/superOver" element={<SuperOver />} /> */}
        <Route
          path="/report/account-statement"
          element={<AccountStatement />}
        />
        <Route path="/report/bet-history" element={<BetHistory />} />
        <Route
          path="/report/profit-loss-report"
          element={<ProfitLossReport />}
        />
        <Route path="/report/unsetteled-bet" element={<UnSetteledBet />} />
        <Route path="/report/activity-log" element={<ActivityLog />} />
        <Route path="/report/casino-result" element={<CasinoResult />} />
      </Routes>
    </PrivateOutlet>
  ) : (
    <PublicOutlet>
      {/*  {isLoading && <Loading />} */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="video" element={<Video />} />
        <Route path="/game/:game/:id" element={<GamePage />} />
        {/* <Route path="/live-casino" element={<CasinoList />} />
        <Route path="/live-casino/dt2" element={<DragonTiger202 />} />
        <Route path="/live-casino/teenPatti20" element={<TeenPatti />} />
        <Route path="/live-casino/32cards-A" element={<Cards32A />} />
        <Route path="/live-casino/32cards-B" element={<Cards32B />} />
        <Route path="/live-casino/race20" element={<Race2020 />} />
        <Route path="/live-casino/casinoqueen" element={<CasinoQueen />} />
        <Route path="/live-casino/dt1" element={<DragonTiger201 />} />
        <Route
          path="/live-casino/dragonTigerOneDay"
          element={<DragonTiger1Day />}
        />
        <Route path="/live-casino/lucky7a" element={<Lucky7A />} />
        <Route path="/live-casino/lucky7b" element={<Lucky7B />} />
        <Route path="/live-casino/andarbahar" element={<AndarBahar />} />
        <Route path="/live-casino/abj" element={<AndarBahar2 />} />
        <Route
          path="/live-casino/3cardsjudgement"
          element={<JudgementCards3 />}
        />
        <Route path="/live-casino/btable" element={<BollywoodCasino />} />
        <Route path="/live-casino/aaa" element={<AmarAkbarAnthony />} />
        <Route path="/live-casino/teen" element={<TeenPatti1Day />} />
        <Route path="/live-casino/poker20" element={<Poker20 />} />
        <Route path="/live-casino/worli2" element={<InstantWorli />} />
        <Route path="/live-casino/cmeter" element={<CasinoMeter />} />
        <Route path="/live-casino/dtl" element={<DragonTigerLion />} />
        <Route path="/live-casino/teentest" element={<TeenPattiTest />} />
        <Route path="/live-casino/teenopen" element={<TeenPattiOpen />} />
        <Route path="/live-casino/war" element={<CasinoWar />} />
        <Route path="/live-casino/cricketv3" element={<Five5Cricket />} />
        <Route path="/live-casino/cmatch20" element={<Cricket2020 />} />
        <Route path="/live-casino/poker" element={<Poker1Day />} />
        <Route path="/live-casion/poker6" element={<Pokerr6Players />} />
        <Route path="/live-casion/baccarat" element={<Baccart />} />
        <Route path="/live-casion/baccarat2" element={<Baccart2 />} /> */}
      </Routes>
    </PublicOutlet>
  );
}
