import React, { useEffect } from "react";
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  GridItem,
  Grid,
  TabPanel,
  TabPanels,
  Tabs,
  TabList,
  Tab,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Modal,
  Text,
  useToast,
  ModalCloseButton,
  ModalBody,
  Button,
} from "@chakra-ui/react";
import Header from "../includes/header";
import SidebarContent from "../includes/sidebar";
import DasNAv from "../includes/dasNav";
import { getUserDataAPI } from "../service/UserService";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../components/CustomInput";
import {
  changeButtonValuesAPI,
  changePasswordAPI,
  getButtonValuesAPI,
  getExposureTableAPI,
} from "../service/AuthService";
import { buttonColors, Encrypt } from "../utils/constants";
import CustomTable from "../components/Table";
const PrivateOutlet = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userData = useSelector((state) => state.account.userData);
  const selectedEventForBet = useSelector(
    (state) => state.gameData.selectedEventForBet
  );
  const [buttons, setButtons] = React.useState({});
  const handleUpdate = (key, value) => {
    setButtons((prev) => ({ ...prev, [key]: value }));
  };
  const getButtonValues = async () => {
    const { response } = await getButtonValuesAPI();
    setButtons(response);
  };
  const handleSave = async () => {
    const { response, code } = await changeButtonValuesAPI(buttons);
    if (code === 200) {
      toast({
        title: "Success",
        description: "Button values updated successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    getButtonValues();
  };

  useEffect(() => {
    getButtonValues();
  }, []);
  const dispatch = useDispatch();
  const toast = useToast();
  const location = useLocation();
  const isChangePasswordPopOpen = useSelector(
    (state) => state.account.ui.changePasswordPopOpen
  );
  const isChangeButtonPopOpen = useSelector(
    (state) => state.account.ui.changeButtonPopOpen
  );
  const isMyMarketPopOpen = useSelector(
    (state) => state.account.ui.myMarketPopOpen
  );
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [exposureTable, setExposureTable] = React.useState([]);
  const changePassword = async () => {
    if (newPassword === "" || confirmPassword === "" || oldPassword === "") {
      toast({
        title: "Error",
        description: "Please fill all the fields",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      toast({
        title: "Error",
        description: "newPassword and confirm Password does not match",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (newPassword === oldPassword) {
      toast({
        title: "Error",
        description: "New Password and old Password cannot be same",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        newPassword
      )
    ) {
      toast({
        title: "Error",

        description:
          "newPassword must contain at least 8 characters, one uppercase, one lowercase, one number and one special character",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    var { data, code, message, response, error } = await changePasswordAPI(
      {
        newPassword: Encrypt(newPassword),
        password: Encrypt(oldPassword),
        resetRequired: false,
      },
      userData.token
    );
    if (code === 200) {
      toast({
        title: "Success",
        description: "New Password changed successfully",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      dispatch({
        type: "gameData/logOut",
        payload: {},
      });
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "/";
      }, 500);
    } else {
      toast({
        title: "Error",
        description: "Wrong Password",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };
  const getExposureTable = async () => {
    var { response, code } = await getExposureTableAPI();
    if (code === 200) {
      setExposureTable(response);
    }
  };
  useEffect(() => {
    if (userData.token && userData.token !== "") {
      getExposureTable();
      let interval = setInterval(() => {
        getUserData();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [selectedEventForBet]);
  const getUserData = async () => {
    var { response, code } = await getUserDataAPI(userData.id);
    if (code === 200) {
      dispatch({
        type: "accountData/setUserProfile",
        payload: response,
      });
    } else {
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      dispatch({
        type: "gameData/logOut",
        payload: {},
      });
      // setTimeout(() => {
      //   localStorage.clear();
      //   window.location.href = "/";
      // }, 500);
    }
  };
  if (!userData.token) {
    return (window.location.href = "/");
  }
  return (
    <>
      <Header />
      <Box minH="100vh">
        <Modal
          size={"lg"}
          onClose={() => {
            if (userData?.token && userData?.resetRequired) {
              dispatch({
                type: "accountData/logOut",
                payload: {},
              });
              dispatch({
                type: "gameData/logOut",
                payload: {},
              });
              setTimeout(() => {
                window.location.reload();
                window.localStorage.clear();
                window.location.href = "/";
              }, 500);
            }
            dispatch({
              type: "accountData/setChangePopSate",
              payload: false,
            });
          }}
          isOpen={isChangePasswordPopOpen}
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent zIndex={10000} marginTop={10} bg={"rgb(51,51,51)"}>
            <ModalHeader bg={"#3C444B"} p="1" borderRadius={"5px"}>
              <Text fontSize="sm" color={"#FDCF13"}>
                Change Password
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody p="2" bg={"#2E3439"} borderRadius={"5px"}>
              <CustomInput
                label="Old Password"
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                bg={"#00000078"}
              />
              <CustomInput
                label="New Password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                bg={"#00000078"}
              />
              <CustomInput
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                bg={"#00000078"}
              />
              <Button
                onClick={changePassword}
                mt="10px"
                w={"100%"}
                bg={buttonColors}
              >
                Change Password
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal
          size={"lg"}
          onClose={() =>
            dispatch({
              type: "accountData/setChangeButtonState",
              payload: false,
            })
          }
          isOpen={isChangeButtonPopOpen}
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent marginTop={10} bg={"#494949"}>
            <ModalHeader bg={"#383838"} p="1" borderRadius={"5px"}>
              <Text fontSize="sm" color={"#FDCF13"}>
                Set Button Value
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody p="2" bg={"#494949"}>
              <Tabs p="0" variant="enclosed">
                <TabList mb="2" borderBottom="none">
                  <Tab
                    _selected={{
                      bg: "#262626",
                    }}
                    fontSize="xs"
                    borderRadius={5}
                  >
                    Game Buttons
                  </Tab>
                  <Tab
                    borderRadius={5}
                    fontSize="xs"
                    _selected={{
                      bg: "#262626",
                    }}
                  >
                    Casino Button
                  </Tab>
                </TabList>
                <TabPanels p="0">
                  <TabPanel p="0">
                    <Box
                      p="1"
                      display="flex"
                      justifyContent="space-between"
                      bg="#383838"
                      fontSize="sm"
                    >
                      <Box w="100%">
                        <Text>Price Label</Text>
                      </Box>
                      <Box w="100%">
                        <Text>Price Value</Text>
                      </Box>
                    </Box>
                    <Grid
                      fontWeight="bold"
                      gap={2}
                      templateColumns="repeat(2,1fr)"
                    >
                      <GridItem>
                        <Grid templateColumns="repeat(1,1fr)">
                          {Object.keys(buttons).map(
                            (key, index) =>
                              !key.includes("Casino") &&
                              !key.includes("Value") && (
                                <GridItem>
                                  <CustomInput
                                    bg={"#00000078"}
                                    onChange={(e) =>
                                      handleUpdate(key, e.target.value)
                                    }
                                    value={buttons[key]}
                                  />
                                </GridItem>
                              )
                          )}
                        </Grid>
                      </GridItem>
                      <GridItem>
                        <Grid templateColumns="repeat(1,1fr)">
                          {Object.keys(buttons).map(
                            (key, index) =>
                              !key.includes("Casino") &&
                              !key.includes("Name") && (
                                <GridItem>
                                  <CustomInput
                                    bg={"#00000078"}
                                    onChange={(e) =>
                                      handleUpdate(key, e.target.value)
                                    }
                                    value={buttons[key]}
                                  />
                                </GridItem>
                              )
                          )}
                        </Grid>
                      </GridItem>
                    </Grid>
                  </TabPanel>
                  <TabPanel p="0">
                    <Box
                      p="1"
                      display="flex"
                      justifyContent="space-between"
                      bg="#494949"
                      fontSize="sm"
                    >
                      <Box w="100%">
                        <Text>Price Label</Text>
                      </Box>
                      <Box w="100%">
                        <Text>Price Value</Text>
                      </Box>
                    </Box>
                    <Grid
                      fontWeight="bold"
                      gap={2}
                      templateColumns="repeat(2,1fr)"
                    >
                      <GridItem>
                        <Grid templateColumns="repeat(1,1fr)">
                          {Object.keys(buttons).map(
                            (key, index) =>
                              key.startsWith("buttonNameCasino") && (
                                <GridItem>
                                  <CustomInput
                                    bg={"#00000078"}
                                    onChange={(e) =>
                                      handleUpdate(key, e.target.value)
                                    }
                                    value={buttons[key]}
                                  />
                                </GridItem>
                              )
                          )}
                        </Grid>
                      </GridItem>
                      <GridItem>
                        <Grid templateColumns="repeat(1,1fr)">
                          {Object.keys(buttons).map(
                            (key, index) =>
                              key.startsWith("buttonValueCasino") && (
                                <GridItem>
                                  <CustomInput
                                    bg={"#00000078"}
                                    onChange={(e) =>
                                      handleUpdate(key, e.target.value)
                                    }
                                    value={buttons[key]}
                                  />
                                </GridItem>
                              )
                          )}
                        </Grid>
                      </GridItem>
                    </Grid>
                  </TabPanel>
                </TabPanels>
              </Tabs>

              <Button
                onClick={handleSave}
                mt="10px"
                w={"100%"}
                bg={buttonColors}
              >
                Submit
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal
          size={"4xl"}
          onClose={() =>
            dispatch({
              type: "accountData/setMyMarketPopState",
              payload: false,
            })
          }
          isOpen={isMyMarketPopOpen}
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent marginTop={10} bg={"rgb(51,51,51)"}>
            <ModalHeader bg={"#3C444B"} p="1" borderRadius={"5px"}>
              <Text fontSize="sm" color={"#FDCF13"}>
                My Market
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody p="2" bg={"#2E3439"} borderRadius={"5px"}>
              <CustomTable
                rows={exposureTable}
                rowsPerPage_={20}
                showPagination={false}
                totalPages_={1}
                currentPage_={0}
                columns={[
                  {
                    name: "Event Type",
                    id: "eventType",
                  },
                  {
                    name: "Event Name",
                    id: "eventName",
                  },
                  {
                    name: "Match Name",
                    id: "marketName",
                  },
                  {
                    name: "Trade",
                    id: "trade",
                  },
                ]}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <DasNAv onOpen={onOpen} />
        <Box
          ml={{
            base: 0,
            md: location.pathname.startsWith("/report") ? 0 : 60,
          }}
          p={location.pathname.startsWith("/report") ? 0 : 0}
          paddingTop={location.pathname.startsWith("/report") ? 100 : 114}
          className="content-box"
        >
          {props.children}
        </Box>
      </Box>
    </>
  );
};

export default PrivateOutlet;
