import React, { useEffect, useMemo } from "react";
import { animate, motion } from "framer-motion";
import { Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
export default function OddsBox({
  match,
  onClick,
  data,
  oddsKey,
  index,
  back = true,
  isFancy,
  isBookmaker,
  ...rest
}) {
  const ref = React.useRef({
    element: {
      current: { style: { backgroundColor: "rgba(114, 187, 239, 1)" } },
    },
  });
  const dispatch = useDispatch();
  const [currentValue, setCurrentValue] = React.useState(0);
  const userData = useSelector((state) => state.account.userData);
  const convertToLacs = (num) => {
    if (num) {
      return parseFloat(num).toLocaleString("en-IN", {
        maximumFractionDigits: 2,
      });
    } else {
      if (num > 1000 && num < 99999) {
        return parseInt(num / 1000) + "K";
      }
      if (num > 100000) {
        return parseInt(num / 100000) + "L";
      } else {
        return num;
      }
    }
  };

  useEffect(() => {
    const colorMapH = {
      back12: "rgba(114, 187, 239, 1)",
      back11: "rgba(114, 187, 239, 1)",
      back1: "rgba(114, 187, 239, 1)",
      lay12: "rgba(249, 148, 186, 1)",
      lay11: "rgba(249, 148, 186, 1)",
      lay1: "rgba(249, 148, 186, 1)",
    };
    const colorMapI = {
      back3: "rgba(114, 187, 239, 0.5)",
      back2: "rgba(114, 187, 239, 0.75)",
      back1: "rgba(114, 187, 239, 1)",
      lay3: "rgba(249, 148, 186, 0.5)",
      lay2: "rgba(249, 148, 186, 0.75)",
      lay1: "rgba(249, 148, 186, 1)",
    };
    var colorMap;
    if (rest.home) {
      colorMap = colorMapH;
    } else {
      colorMap = colorMapI;
    }
    const element = ref?.current;
    if (element && element.style) {
      if (currentValue > rest.overRide ? match["back1"] : match[oddsKey]) {
        element.style.backgroundColor = "#32bd26";
      } else if (
        currentValue == rest.overRide ? match["back1"] : match[oddsKey]
      ) {
        element.style.backgroundColor = rest.overRide
          ? colorMap["back1"]
          : colorMap[oddsKey];
      } else {
        element.style.backgroundColor = "#c22121";
        setTimeout(() => {
          element.style.backgroundColor = rest.overRide
            ? colorMap["back1"]
            : colorMap[oddsKey];
        }, 200);
      }
      setTimeout(() => {
        element.style.backgroundColor = rest.overRide
          ? colorMap["back1"]
          : colorMap[oddsKey];
      }, 500);
    }
    setCurrentValue(match[oddsKey]);
  }, [match[oddsKey], ref]);

  return (match[oddsKey] == 0 || match == 0) && rest.home ? (
    <div ref={ref} class="no-val bl-box">
      <span class="d-block odds">—</span>
    </div>
  ) : (
    <motion.div
      onClick={() => {
        if (!userData?.token) {
          dispatch({
            type: "accountData/setLoginPopSate",
            payload: true,
          });
          return;
        }
        if (match[oddsKey] == 0 || match == 0) {
          return;
        }
        onClick({
          runnerName: match.runnerName,
          isBookmaker: isBookmaker,
          selectedOdd: rest.overRide
            ? match["lay1"]
            : oddsKey
            ? match[oddsKey]
            : match,
          selectedId: match.selectionId,
          type: rest.overRide ? "back" : back ? "back" : "lay",
          selectedRate:
            match[
              rest.overRide
                ? "lay1".slice(0, 3) + "Size"
                : oddsKey.slice(0, back ? 4 : 3) +
                  "Size" +
                  oddsKey.slice(back ? 4 : 3)
            ],
        });
      }}
      ref={ref}
      {...rest}
      className={`${back ? "oddsBoxb" : "oddsBoxr"} oddsBoxH`}
    >
      <Text fontSize="md" fontWeight="bold">
        {isFancy || isBookmaker
          ? data?.gameType == "fancy1"
            ? match[oddsKey] == 0
              ? "—"
              : parseFloat(
                  oddsKey ? (match[oddsKey] ? match[oddsKey] : 0) : match,
                  2
                )
            : parseFloat(
                oddsKey ? (match[oddsKey] ? match[oddsKey] : 0) : match
              )
          : oddsKey
          ? match[oddsKey]
          : match}
      </Text>
      <Text mt="-1" fontSize="sm">
        {data?.gameType == "fancy1" && match[oddsKey] == 0
          ? ""
          : convertToLacs(
              isFancy || isBookmaker
                ? parseFloat(
                    match[
                      oddsKey.slice(0, back ? 4 : 3) +
                        "Size" +
                        oddsKey.slice(back ? 4 : 3)
                    ]
                      ? match[
                          oddsKey.slice(0, back ? 4 : 3) +
                            "Size" +
                            oddsKey.slice(back ? 4 : 3)
                        ]
                      : 0
                  )
                : oddsKey
                ? match[oddsKey.slice(0, back ? 4 : 3) + "Size" + (index + 1)]
                : match
            )}
      </Text>
    </motion.div>
  );
}
