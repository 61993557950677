import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  Td,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import CustomTable from "../../components/Table";
import { getAccountStatementAPI } from "../../service/UserService";
import {
  bgColor,
  buttonColors,
  convertEpochToDate,
  getCurrentDateTimeLocal,
  searchSubstringInArrayObjects,
} from "../../utils/constants";
import { SearchIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { getBetsFromAccountLogs } from "../../service/AuthService";
import moment from "moment";
import "./pagination.css";
import "./statement.css";
import "./modal.css";
export default function AccountStatement() {
  const [data, setData] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [currentPageData, setCurrentPageData] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [type, setType] = React.useState("ALL");
  const dispatch = useDispatch();
  const [startDate, setStartDate] = React.useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [noOfPages, setNoOfPages] = React.useState(0);
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [selectedType, setSelectedType] = React.useState("ALL");
  const [logData, setLogData] = React.useState([]);
  const [selectedLogId, setSelectedLogId] = React.useState(null);
  const isMobileView = window.innerWidth < 768;
  const getData = async (id, TYPE = "ALL") => {
    setSelectedLogId(id);
    const { response } = await getBetsFromAccountLogs(
      selectedLogId ? selectedLogId : id
    );
    let response_ = response;
    let betResponseDTOList = response_.betResponseDTOList;
    let betResponseDTOList_ = [];
    betResponseDTOList.forEach((item) => {
      if (item.back_rate && item.event_type == "Casino") {
        item.type = "back";
        item.rate = item.back_rate;
      } else if (item.lay_rate && item.event_type == "Casino") {
        item.type = "lay";
        item.rate = item.lay_rate;
      }
      if (
        item.back_run &&
        (item.event_type == "Cricket" ||
          item.event_type == "Soccer" ||
          item.event_type == "Tennis")
      ) {
        item.type = "back";
        item.rate = item.back_run;
      } else if (
        item.lay_run &&
        (item.event_type == "Cricket" ||
          item.event_type == "Soccer" ||
          item.event_type == "Tennis")
      ) {
        item.type = "lay";
        item.rate = item.lay_run;
      }
      betResponseDTOList_.push(item);
    });
    response_.betResponseDTOList = betResponseDTOList_;

    if (TYPE == "ALL") {
      response_ = response;
    }
    if (TYPE == "BACK") {
      response_.betResponseDTOList = response_.betResponseDTOList.filter(
        (item) => item.type == "back"
      );
    }
    if (TYPE == "LAY") {
      response_.betResponseDTOList = response_.betResponseDTOList.filter(
        (item) => item.type == "lay"
      );
    }
    setLogData(response_);
  };

  const getAccountStatement = async () => {
    let payload = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      type: type,
    };
    const { response } = await getAccountStatementAPI(payload);
    setNoOfPages(Math.ceil(response.length / rowsPerPage));
    setCurrentPageData(response.slice(0, rowsPerPage));
    setData(response);
  };
  const search = async (e) => {
    if (e.target.value == "") return getAccountStatement();
    setData(
      searchSubstringInArrayObjects(
        data,
        ["comment", "debitInfo", "creditInfo", "updatedBalance"],
        e.target.value
      )
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    getAccountStatement();
  };
  useEffect(() => {
    getAccountStatement();
  }, [type, startDate, endDate]);
  useEffect(() => {
    setCurrentPageData(
      data.slice(
        pageNumber * rowsPerPage,
        pageNumber * rowsPerPage + rowsPerPage
      )
    );
  }, [pageNumber, selectedType]);
  return (
    <div className="report-container snipcss-fBYv4">
      <Modal
        size={"6xl"}
        onClose={() => {
          setIsModalOpen(false);
        }}
        isOpen={isModalOpen}
        motionPreset="slideInBottom"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          style={{
            backgroundColor: "transparent !important",
          }}
          marginTop={10}
          bg={"white"}
        >
          <ModalCloseButton />
          <ModalBody
            p="2"
            style={{
              backgroundColor: "transparent",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                color: "black",
              }}
              className="modal-dialog modal-md snipcss-IJ1UB"
            >
              <span tabIndex={0} />

              <div
                id="__BVID__50___BV_modal_content_"
                tabIndex={-1}
                className="modal-content"
              >
                <div id="__BVID__50___BV_modal_body_" className="modal-body">
                  <div className="container-fluid container-fluid-5">
                    <div className="row row5">
                      <div className="col-12 text-center">
                        <div
                          id="match_delete"
                          role="radiogroup"
                          tabIndex={-1}
                          className=""
                        >
                          <div className="custom-control custom-control-inline custom-radio">
                            <input
                              id="matched"
                              type="radio"
                              name="match_delete"
                              autoComplete="off"
                              defaultValue={1}
                              className="custom-control-input"
                            />{" "}
                            <label
                              htmlFor="matched"
                              className="custom-control-label"
                            >
                              <span>Matched</span>
                            </label>
                          </div>
                          <div className="custom-control custom-control-inline custom-radio">
                            <input
                              id="deleteed"
                              type="radio"
                              name="match_delete"
                              autoComplete="off"
                              defaultValue={2}
                              className="custom-control-input"
                            />{" "}
                            <label
                              htmlFor="deleteed"
                              className="custom-control-label"
                            >
                              <span>Deleted</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row row5">
                      <div className="col-12">
                        {logData?.betResponseDTOList?.map((item) => (
                          <div
                            style={{ fontSize: "x-small", color: "black" }}
                            className={`account-statement
                              ${
                                item?.lay_rate || item?.lay_run ? "lay" : "back"
                              }`}
                          >
                            <div className="row row5">
                              <div className="col-6">
                                <div style={{ color: "black" }}>
                                  <b>Nation:</b>{" "}
                                  {item?.runner_name
                                    ? item?.runner_name
                                    : item?.event_name}{" "}
                                </div>
                                <div style={{ color: "black" }} className="">
                                  <b style={{}}>Placed Date:</b>{" "}
                                  {moment(item?.created_date + "Z").format(
                                    "DD MM YYYY hh:mm A"
                                  )}
                                </div>
                                <div style={{ color: "black" }}>
                                  <b>Matched Date:</b>{" "}
                                  {moment(item?.created_date + "Z").format(
                                    "DD MM YYYY hh:mm A"
                                  )}{" "}
                                </div>
                              </div>
                              <div
                                style={{ color: "black" }}
                                className="col-2 text-right"
                              >
                                <div style={{ color: "black" }}>
                                  <b>Rate</b>
                                </div>
                                <div style={{ color: "black" }}>
                                  {item?.lay_rate || item?.back_rate
                                    ? item?.lay_rate
                                      ? item?.lay_rate
                                      : item?.back_rate
                                    : item?.lay_run
                                    ? item?.lay_run
                                    : item?.back_run}
                                </div>
                              </div>
                              <div className="col-2 text-right">
                                <div style={{ color: "black", width: "43px" }}>
                                  <b>Amount</b>
                                </div>
                                <div style={{ color: "black" }}>
                                  {item?.amount}
                                </div>
                              </div>
                              <div className="col-2 text-right">
                                <div style={{ color: "black" }}>
                                  <b>W&amp;L</b>
                                </div>
                                <div
                                  style={{
                                    color: item?.profit ? "green" : "red",
                                    marginRight: "5px",
                                  }}
                                >
                                  {item?.profit ? item?.profit : item?.loss}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <footer
                  id="__BVID__50___BV_modal_footer_"
                  className="modal-footer"
                >
                  <button
                    onClick={() => setIsModalOpen(null)}
                    type="button"
                    className="btn btn-danger btn-sm"
                  >
                    Cancel
                  </button>
                </footer>
              </div>
              <span tabIndex={0} />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="card snipcss0-0-0-1">
        <div className="card-header">
          <h4>Account Statement</h4>
        </div>
        <div className="card-body container-fluid container-fluid-5 snipcss0-1-1-4">
          <div className="row row5 snipcss0-2-4-5">
            <div className="col-6 snipcss0-3-5-6">
              <div className="form-group mb-0 snipcss0-4-6-7">
                <input
                  type="date"
                  className="form-control acc-date"
                  value={startDate}
                  style={{
                    fontWeight: "bold",
                  }}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-6 snipcss0-3-5-259">
              <div className="form-group mb-0 snipcss0-4-259-260">
                <input
                  type="date"
                  className="form-control"
                  value={endDate}
                  style={{
                    fontWeight: "bold",
                  }}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row row5 mt-2 snipcss0-2-4-512">
            <div className="col-12 snipcss0-3-512-513">
              <div className="form-group mb-0 snipcss0-4-513-514">
                <select
                  name="reportType"
                  style={{
                    fontWeight: "bold",
                  }}
                  className="custom-select snipcss0-5-514-515"
                >
                  <option value={"ALL"} className="snipcss0-6-515-516">
                    All
                  </option>
                  <option
                    value={"BALANCE_REPORT"}
                    className="snipcss0-6-515-517"
                  >
                    Deposit/Withdraw Report
                  </option>
                  <option value={"GAME_REPORT"} className="snipcss0-6-515-518">
                    Game Report
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="row row5 mt-2 snipcss0-2-4-519">
            <div className="col-12 snipcss0-3-519-520">
              <button
                style={{
                  backgroundColor: "#2c8268",
                  fontWeight: "bold",
                  color: "white",
                }}
                onClick={getAccountStatement}
                className="btn btn-primary btn-block btn-sm snipcss0-4-520-521"
              >
                Submit
              </button>
            </div>
          </div>
          <div className="row row5 mt-2">
            <div className="col-6">
              <div id="account-statement_length" className="dataTables_length">
                <label
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  Show{" "}
                  <select
                    value={rowsPerPage}
                    onChange={(e) => {
                      setRowsPerPage(e.target.value);
                    }}
                    name="account-statement_length"
                    aria-controls="account-statement"
                    className="form-control form-control-sm theme1font acc-search"
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                    <option value={25}>25</option>
                    <option value={30}>30</option>
                    <option value={35}>35</option>
                    <option value={40}>40</option>
                    <option value={45}>45</option>
                    <option value={50}>50</option>
                  </select>{" "}
                  entries{" "}
                </label>
              </div>
            </div>
            <div className="col-6">
              <div id="account-statement_filter" className="dataTables_filter">
                <label>
                  {" "}
                  Search:{" "}
                  <input
                    type="search"
                    placeholder="Type to Search"
                    aria-controls="account-statement"
                    className="form-control form-control-sm acc-search"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="row row5">
            <div className="col-12 snipcss0-3-522-523">
              <div className="table-responsive snipcss0-4-523-524">
                <table
                  style={{
                    width: "auto",
                    color: "white",
                  }}
                  role="table"
                  aria-busy="false"
                  aria-colcount={6}
                  className="table b-table table-bordered snipcss0-5-524-525"
                  id="__BVID__38"
                >
                  <thead role="rowgroup" className="snipcss0-6-525-526">
                    <tr role="row" className="snipcss0-7-526-527">
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={1}
                        className="text-center snipcss0-8-527-528"
                        style={{
                          borderRight: "1px solid gray",
                        }}
                      >
                        <div
                          style={{ color: "black" }}
                          className="snipcss0-9-528-529"
                        >
                          Date
                        </div>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid gray",
                        }}
                        role="columnheader"
                        scope="col"
                        aria-colindex={2}
                        className="text-right snipcss0-8-527-530"
                      >
                        <div
                          style={{ color: "black" }}
                          className="snipcss0-9-530-531"
                        >
                          Sr no
                        </div>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid gray",
                        }}
                        role="columnheader"
                        scope="col"
                        aria-colindex={3}
                        className="text-right snipcss0-8-527-532"
                      >
                        <div
                          style={{ color: "black" }}
                          className="snipcss0-9-532-533"
                        >
                          Credit
                        </div>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid gray",
                        }}
                        role="columnheader"
                        scope="col"
                        aria-colindex={4}
                        className="text-right snipcss0-8-527-534"
                      >
                        <div
                          style={{ color: "black" }}
                          className="snipcss0-9-534-535"
                        >
                          Debit
                        </div>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid gray",
                        }}
                        role="columnheader"
                        scope="col"
                        aria-colindex={5}
                        className="text-right snipcss0-8-527-536"
                      >
                        <div
                          style={{ color: "black" }}
                          className="snipcss0-9-536-537"
                        >
                          Balance
                        </div>
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={6}
                        className="text-center snipcss0-8-527-538"
                      >
                        <div
                          style={{ color: "black" }}
                          className="snipcss0-9-538-539"
                        >
                          Remark
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup" className="snipcss0-6-525-540">
                    <tr
                      style={{
                        height: "2px",
                      }}
                    />
                    {currentPageData?.map((item, index) => (
                      <tr
                        role="row"
                        tabIndex={0}
                        aria-rowindex={1}
                        className=""
                      >
                        <td
                          aria-colindex={1}
                          role="cell"
                          className="text-center"
                        >
                          <span>
                            {moment(item?.createdAt + "Z").format(
                              "DD MM YYYY hh:mm A"
                            )}
                          </span>
                        </td>
                        <td
                          aria-colindex={2}
                          role="cell"
                          className="text-right"
                        >
                          <span>{index + 1}</span>
                        </td>
                        <td
                          aria-colindex={3}
                          role="cell"
                          className="text-right"
                        >
                          <span className={item?.creditInfo && "text-success"}>
                            {item?.creditInfo ? item?.creditInfo : "-"}
                          </span>
                        </td>
                        <td
                          aria-colindex={4}
                          role="cell"
                          className={
                            item?.debitInfo
                              ? "text-right text-danger "
                              : "text-right"
                          }
                        >
                          <span>
                            {" "}
                            {item?.debitInfo ? item?.debitInfo : "-"}
                          </span>
                        </td>
                        <td
                          aria-colindex={5}
                          role="cell"
                          className="text-right"
                        >
                          <span className="text-success">
                            {parseFloat(item?.updatedBalance).toFixed(2)}
                          </span>
                        </td>
                        <td
                          aria-colindex={6}
                          role="cell"
                          onClick={() => {
                            if (item.type == "GAME_REPORT") {
                              setSelectedLogId(item.accountLogId);
                              getData(item.accountLogId, "ALL");

                              setIsModalOpen(true);
                            }
                          }}
                          className="text-center"
                        >
                          {item?.comment}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot
                    style={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "min-content",
                      flexDirection: "row",
                    }}
                  >
                    <button
                      style={{ color: "white" }}
                      className="btn btn-primary btn-sm"
                    >
                      <i className="fa fa-angle-left" />
                    </button>
                    {Array.from({ length: noOfPages }, (_, i) => (
                      <button
                        style={{
                          color: "white",
                          backgroundColor:
                            pageNumber == i + 1 ? buttonColors : "",
                        }}
                        className="btn btn-primary btn-sm"
                        onClick={() => setPageNumber(i)}
                      >
                        {i + 1}
                      </button>
                    ))}
                    <button
                      style={{ color: "white" }}
                      className="btn btn-primary btn-sm"
                    >
                      <i className="fa fa-angle-right" />
                    </button>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}
